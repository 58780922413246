import { setCookie } from "../../addons/CookieUtils";
import { callLocalFunc, isLocalEnv } from "../../addons/LambdaUtils";
// import { rpcCall } from "../../addons/RPC";
import { updateLoginStatus } from "../ui/actions/login";
import { loadTheme } from "../ui/actions/ThemeAction";
import { updateUser } from "../ui/actions/user";
import { callAPI } from "../../addons/APIUtils2";

async function _loadAccountTheme(AccountName, dispatch) {
  if (AccountName === "Capital One") {
    const lenatiTheme = {
      logo: "http://www.lenati.com/wp-content/themes/lenati/images/logo.svg",
      mask: "rgba(45, 37, 35, 0.7)",
      active: "rgba(45, 37, 35, 1.0)",
      backgroundColor: "rgba(0, 0, 0, 0)",
      content: {
        backgroundColor: "rgba(45, 37, 35, 0.7)",
        color: "#fff",
      },
      background: {
        backgroundImage:
          "url(http://www.lenati.com/wp-content/uploads/2014/08/Sales1.jpg)",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
      },
      sider: {
        backgroundColor: "rgba(0, 0, 0, 0)",
      },
      trigger: {
        borderColor: "rgba(0, 0, 0, 0)",
        backgroundColor: "rgba(45, 37, 35, 0.7)",
        active: "rgba(45, 37, 35, 1.0)",
      },
      footer: {
        backgroundColor: "rgba(45, 37, 35, 0.7)",
        padding: 0,
      },
    };

    await dispatch(loadTheme(lenatiTheme));
  }
}

export async function handleSignInPayload(authData, dispatch) {
  const accessToken = authData.signInUserSession.getAccessToken().getJwtToken();
  const { FullName, IsSystemAdmin, Email } = await callAPI(
    "sr_cognito_callback",
    {
      jwt_token: accessToken,
    }
  );

  await dispatch(updateLoginStatus({ ok: true }));
  const user = {
    fullname: FullName,
    isSystemAdmin: IsSystemAdmin,
    email: Email,
  };
  await dispatch(updateUser(user));

  // await _loadAccountTheme(AccountName, dispatch);
}

import { SRIA_ADMIN_STORE_SUPPLIER_PROFILE } from "../actions/SRIAAdminSupplierAction";
const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {},
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_ADMIN_STORE_SUPPLIER_PROFILE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

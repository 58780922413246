// @flow
import { Col, Pagination, Row, Spin, Table } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { sriaAdminSupplierCrawledDataAction } from "../../actions/SRIAAdminSupplierAction";
import { isNumber } from "lodash";

type TProps = {
  dataSource: string,
  loadData: Function,
  supplierId: number,
  page: number,
  data: Object,
  loading: boolean,
  [dataSource: string]: Object,
};
type TState = {};

class SRIAAdminSupplierCrawledData extends Component<TProps, TState> {
  componentDidMount() {
    const { supplierId, dataSource, page } = this.props;
    this.props.loadData(supplierId, dataSource, page);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.supplierId !== this.props.supplierId ||
      nextProps.page !== this.props.page
    ) {
      nextProps.loadData(
        nextProps.supplierId,
        nextProps.dataSource,
        nextProps.page
      );
    }
  }

  buildDataSource = items => {
    let dataSource = [];

    items.forEach((item, key) => {
      let data = {
        key: key,
      };
      Object.entries(item).forEach(([key, value]) => {
        if (isNumber(value)) {
          data[key] = Math.round(Number(value) * 100);
        } else {
          data[key] = value;
        }
      });
      dataSource.push(data);
    });
    return dataSource;
  };

  buildColumns = items => {
    let columns = [];
    if (items) {
      Object.entries(items[0]).forEach(([key, value]) => {
        columns.push({
          title: key,
          dataIndex: key,
          key: key,
        });
      });
    }
    return columns;
  };

  buildTable = (items, pagination) => {
    if (items.length > 0) {
      return (
        <React.Fragment>
          <Table
            dataSource={this.buildDataSource(items)}
            columns={this.buildColumns(items)}
            pagination={false}
            size="small"
            scroll={{ x: true }}
          />

          <PaginationWrapper
            {...pagination}
            onChange={page => changeUrl(this.props, { page })}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </React.Fragment>
      );
    }
  };

  render() {
    const { loading, dataSource } = this.props;
    const { items, pagination } = this.props[dataSource];

    let content = <NoDataWrapper>No available data!</NoDataWrapper>;
    if (items.length > 0) {
      content = this.buildTable(items, pagination);
    }
    return (
      <Spin spinning={loading}>
        <Row>
          <Col span={24}>{content}</Col>
        </Row>
      </Spin>
    );
  }
}

const PaginationWrapper = styled(Pagination)`
  margin-top: 10px !important;
  text-align: center;
`;

const NoDataWrapper = styled.div`
  margin-top: 10px !important;
  font-size: 18px;
  text-align: center;
`;

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const { page = 1 } = queryString.parse(ownedProps.location.search);
  return {
    supplierId: Number(ownedProps.match.params.supplierId),
    page: Number(page),
    ...state.SupplierCrawledData,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (supplierId, dataSource, page) =>
      dispatch(
        sriaAdminSupplierCrawledDataAction({ supplierId, dataSource, page })
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplierCrawledData)
);

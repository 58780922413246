import {
  HIDE_EVENT_REPORT_FORM,
  SHOW_EVENT_REPORT_FORM,
} from "../actions/SRIAEventReportAction";

const initState = {
  start: 0,
  end: 0,
  snippet: "",
  showForm: false,
  commentText: "",
};

export default (state = initState, action) => {
  switch (action.type) {
    case SHOW_EVENT_REPORT_FORM:
      return {
        ...state,
        ...action.payload,
        showForm: true,
      };
    case HIDE_EVENT_REPORT_FORM:
      return {
        ...state,
        snippet: "",
        showForm: false,
      };
    default:
      return state;
  }
};

// @flow
import { Col, Icon, Input, Pagination, Row, Spin, Typography } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { changeUrl } from "../../../../co/modules/layout/SwitchRouteParams";
import { sriaAdminSupplierNewsAction } from "../../../actions/SRIAAdminSupplierAction";

import DocDelete from "./DocDelete";

type TProps = {
  loadData: Function,
  supplierId: number,
  page: number,
  query?: string,
  data: Object,
  loading: boolean,
};

type TState = {};

function getFieldValue({ field, highlight, defaultValue }) {
  if (highlight[field]) {
    return (
      <span
        dangerouslySetInnerHTML={{ __html: highlight[field].join(" ... ") }}
      />
    );
  }

  return defaultValue;
}

class SRIAAdminSupplierNews extends Component<TProps, TState> {
  componentDidMount() {
    this.loadData(this.props);
  }

  loadData = props => {
    const { loadData, supplierId, page, query } = props;
    loadData({ supplierId, page, query });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.supplierId !== this.props.supplierId ||
      nextProps.query !== this.props.query ||
      nextProps.page !== this.props.page
    ) {
      this.loadData(nextProps);
    }
  }

  render() {
    const { data: { items, pagination }, loading, query } = this.props;

    return (
      <Spin spinning={loading}>
        <Row>
          <Col span={6} />
          <Col span={12}>
            <Input.Search
              placeholder="Input search text (i.e: ceo / url:*.workday.com / title:ceo)"
              enterButton="Search"
              defaultValue={query}
              size="large"
              onSearch={value =>
                changeUrl(this.props, { query: value }, ["page"])}
              style={{ marginBottom: 16 }}
            />
            {items.map(
              ({ url, id, title, content, published_date, highlight }) =>
                <Container key={id}>
                  <div>
                    {title
                      ? <b style={{ color: "green" }}>
                          {getFieldValue({
                            field: "title",
                            highlight,
                            defaultValue: title,
                          })}
                        </b>
                      : <i>---</i>}{" "}
                    - {moment(published_date).format("ll")}{" "}
                    <DocDelete
                      doc_id={id}
                      onDelete={() => this.loadData(this.props)}
                    />
                  </div>
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    {getFieldValue({
                      field: "url",
                      highlight,
                      defaultValue: url,
                    })}{" "}
                    <Icon type="link" />
                  </a>
                  {highlight.content
                    ? <div
                        dangerouslySetInnerHTML={{
                          __html: highlight.content.join(" ... "),
                        }}
                      />
                    : <Typography.Paragraph
                        ellipsis={{ rows: 1, expandable: true }}
                        style={{ whiteSpace: "pre-wrap" }}
                      >
                        {content}
                      </Typography.Paragraph>}
                </Container>
            )}
            <Pagination
              {...pagination}
              onChange={page => changeUrl(this.props, { page })}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`}
            />
          </Col>
        </Row>
      </Spin>
    );
  }
}

const Container = styled.div`
  padding: 8px;
  &:hover {
    background-color: #eee;
  }
`;

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const { page = 1, query } = queryString.parse(ownedProps.location.search);
  return {
    supplierId: Number(ownedProps.match.params.supplierId),
    page: Number(page),
    query,
    ...state.SupplierNews,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: ({ supplierId, page, query }) =>
      dispatch(sriaAdminSupplierNewsAction({ supplierId, page, query })),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplierNews)
);

import { Auth, I18n } from "aws-amplify";
import { RequireNewPassword } from "aws-amplify-react";
import React from "react";

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Input,
  Link,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React";

import { COAuthButton } from "./COAuthButton";

export default class CORequireNewPassword extends RequireNewPassword {
  state = {
    loading: false,
  };

  handleKeyPress = (e: Event) => {
    if (e.key === "Enter") {
      this.change();
    }
  };

  change = async () => {
    this.setState({ loading: true });
    const user = this.props.authData;
    const { password } = this.inputs;
    const { requiredAttributes } = user.challengeParam;
    const attrs = objectWithProperties(this.inputs, requiredAttributes);

    if (!Auth || typeof Auth.completeNewPassword !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    await Auth.completeNewPassword(user, password, attrs)
      .then(user => {
        // logger.debug("complete new password", user);
        if (user.challengeName === "SMS_MFA") {
          this.changeState("confirmSignIn", user);
        } else if (user.challengeName === "MFA_SETUP") {
          // logger.debug("TOTP setup", user.challengeParam);
          this.changeState("TOTPSetup", user);
        } else {
          this.checkContact(user);
        }
      })
      .catch(err => this.error(err));
    this.setState({ loading: false });
  };

  showComponent = theme => {
    const { hide } = this.props;
    if (hide && hide.includes(CORequireNewPassword)) {
      return null;
    }

    const user = this.props.authData;
    const { requiredAttributes } = user.challengeParam;

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Change Password")}
        </SectionHeader>
        <SectionBody theme={theme}>
          <Input
            autoFocus
            placeholder={I18n.get("New Password")}
            theme={theme}
            key="password"
            name="password"
            type="password"
            onChange={this.handleInputChange}
            onKeyPress={this.handleKeyPress}
          />

          {requiredAttributes.map(attribute =>
            <Input
              placeholder={I18n.get(convertToPlaceholder(attribute))}
              theme={theme}
              key={attribute}
              name={attribute}
              type="text"
              onChange={this.handleInputChange}
              onKeyPress={this.handleKeyPress}
            />
          )}
        </SectionBody>
        <SectionFooter theme={theme}>
          <SectionFooterPrimaryContent theme={theme}>
            <COAuthButton onClick={this.change} loading={this.state.loading}>
              {I18n.get("Change")}
            </COAuthButton>
          </SectionFooterPrimaryContent>
          <SectionFooterSecondaryContent theme={theme}>
            <Link theme={theme} onClick={() => this.changeState("signIn")}>
              {I18n.get("Back to Sign In")}
            </Link>
          </SectionFooterSecondaryContent>
        </SectionFooter>
      </FormSection>
    );
  };
}

function convertToPlaceholder(str) {
  return str
    .split("_")
    .map(part => part.charAt(0).toUpperCase() + part.substr(1).toLowerCase())
    .join(" ");
}

function objectWithProperties(obj, keys) {
  const target = {};
  for (const key in obj) {
    if (keys.indexOf(key) === -1) {
      continue;
    }
    if (!Object.prototype.hasOwnProperty.call(obj, key)) {
      continue;
    }
    target[key] = obj[key];
  }
  return target;
}

import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sriaEventDashboardLoad } from "../../actions/SRIAEventDashboardAction";
import EventListView from "./EventListView";
import { normalizeItems } from "./EventUtils";

class EventList extends Component {
  componentWillMount() {
    this.props.loadData(
      this.props.page,
      this.props.supplierIds,
      this.props.eventCodes,
      this.props.timePeriod,
      this.props.eventImportances,
      this.props.sortField,
      this.props.sortOrder,
      this.props.mySuppliers
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      !isEqual(nextProps.supplierIds, this.props.supplierIds) ||
      !isEqual(nextProps.eventCodes, this.props.eventCodes) ||
      !isEqual(nextProps.timePeriod, this.props.timePeriod) ||
      !isEqual(nextProps.eventImportances, this.props.eventImportances) ||
      !isEqual(nextProps.sortField, this.props.sortField) ||
      !isEqual(nextProps.sortOrder, this.props.sortOrder) ||
      !isEqual(nextProps.mySuppliers, this.props.mySuppliers)
    ) {
      nextProps.loadData(
        nextProps.page,
        nextProps.supplierIds,
        nextProps.eventCodes,
        nextProps.timePeriod,
        nextProps.eventImportances,
        nextProps.sortField,
        nextProps.sortOrder,
        nextProps.mySuppliers
      );
    }
  }

  render() {
    return <EventListView {...this.props} />;
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    page = 1,
    supplier_ids,
    event_codes,
    time_period,
    importances,
    sortField,
    sortOrder,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);
  return {
    ...state.EventDashboard,
    page,
    supplierIds: supplier_ids,
    eventCodes: normalizeItems(event_codes),
    timePeriod: time_period,
    eventImportances: normalizeItems(importances),
    sortField,
    sortOrder,
    mySuppliers: mySuppliers === "1",
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (
      page,
      supplierIds,
      eventCodes,
      timePeriod,
      eventImportances,
      sortField,
      sortOrder,
      mySuppliers
    ) => {
      return dispatch(
        sriaEventDashboardLoad(
          page,
          supplierIds,
          eventCodes,
          timePeriod,
          eventImportances,
          sortField,
          sortOrder,
          mySuppliers
        )
      );
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventList)
);

// @flow
import { Auth } from "aws-amplify";
import { rpcCall } from "../../../addons/RPC";

import { clearTheme } from "./ThemeAction";
import { clearUser } from "./user";

export const UPDATE_LOGIN_STATUS = "UPDATE_LOGIN_STATUS";
export const CLEAR_LOGIN = "CLEAR_LOGIN";
export const UPDATE_FORGET_PASSWORD_EMAIL = "UPDATE_FORGET_PASSWORD_EMAIL";
type TAuthResponse = {
  ok: boolean,
  message?: string,
};

export function updateLoginStatus(payload: TAuthResponse) {
  return { type: UPDATE_LOGIN_STATUS, payload };
}

export function clearLogin() {
  return { type: CLEAR_LOGIN };
}

export function updateForgetPasswordEmail(forgetPasswordEmail: string) {
  return {
    type: UPDATE_FORGET_PASSWORD_EMAIL,
    payload: { forgetPasswordEmail },
  };
}

export function logoutAction() {
  return dispatch => {
    console.log("call logout action");
    return Promise.all([
      dispatch(clearLogin()),
      dispatch(clearUser()),
      dispatch(clearTheme()),
      Auth.signOut(),
      // Call legacy API to clear cookie
      // rpcCall("AuthService/remote_clear_session"),
    ]);
  };
}

export async function callAPI(funcName, params = {}) {
  let fetchOptions: Object = {
    // credentials: "include",
    method: "POST",
  };
  if (params) {
    // fetchOptions.headers = {};
    const formData = new FormData();
    formData.append("__formData", JSON.stringify(params));
    fetchOptions.body = formData;
  }

  const res = await fetch(`/api/${funcName}`, fetchOptions);
  if (!res.ok) {
    if (res.headers.get("Content-Type") === "application/json") {
      const data = await res.json();

      const message = data.message || (data.response && data.response.message);

      const e = new Error(message);

      if (data.status === 401) {
        // Not authorized
        e.errorType = "NOT_AUTHORIZED";
        /* eslint-disable no-console */
        console.log("Not authorized error => logout ABC");
        /* eslint-enable no-console */
        window.logout();
      }

      // For debug purpose
      /* eslint-disable no-console */
      console.error(message);
      /* eslint-enable no-console */

      throw e;
    }

    const text = await res.text();

    throw new Error(
      `Fail to load data from API: ${res.statusText}.\n\n${text}`
    );
  }

  const ret = await res.json();
  return ret;
}

import { callAPI } from "./APIUtils2";

type TAction = {
  type: string,
};

type TRPCSuccess = {
  url: string,
  params: Object,
  response: Object,
};

type TRPCAction = {
  url: string,
  params: Object,
  stateKey: (params: TRPCSuccess) => string,
};

function _rpcSuccessAction({ url, params, response }: TRPCSuccess) {
  return {
    type: "RPC_SUCCESS",
    payload: {
      url,
      params,
      response,
    },
  };
}

export const rpcBuilder = url => ({
  getValue: (state, params) => {
    if (!state.rpc) {
      return {};
    }

    const stateKey = _buildRPCStateKey(url, params);

    return state.rpc[stateKey];
  },
  action: params => {
    return _rpcAction({ url, params });
  },
});

function _rpcAction({ url, params, stateKey }: TRPCAction) {
  return async (dispatch, getState) => {
    const response = await rpcCall(url, params);
    return dispatch(_rpcSuccessAction({ url, params, response }));
  };
}

const _buildRPCStateKey = (url: string, params: Object): string => {
  return (
    url +
    ":" +
    Object.keys(params)
      .sort()
      .map(k => `${k}=${params[k]}`)
      .join(",")
  );
};

export const RPCReducer = (state = {}, action: TAction) => {
  switch (action.type) {
    case "RPC_SUCCESS":
      const { url, params, response } = action.payload;
      const stateKey = _buildRPCStateKey(url, params);
      // console.log("rpc success", action.payload, stateKey);
      return {
        ...state,
        [stateKey]: response,
      };

    default:
      return state;
  }
};

export async function rpcCall(url: string, params: Object = {}) {
  const func = url.replace("EventService/", "");
  return callAPI(func, params);
}

export function buildAPIUrl(url: string) {
  let apiHost = window.apiHost;
  if (!apiHost) {
    throw new Error("API Host is not provide.");
  }

  if (apiHost.endsWith("/")) {
    apiHost = apiHost.substring(0, apiHost.length - 1);
  }

  let urlTmp = url;
  if (urlTmp.startsWith("/")) {
    urlTmp = urlTmp.substring(1);
  }

  return `${apiHost}/${urlTmp}`;
}

// @flow
import { I18n } from "aws-amplify";
import { Authenticator, ForgotPassword } from "aws-amplify-react";
import React, { Component } from "react";
import { UnAuthenticateContentAddy } from "../../modules/Authentication/UnAuthenticateContentAddy";
import UnAuthenticatedLayout from "../../modules/Authentication/UnAuthenticatedLayout";

import { handleSignInPayload } from "../co/COSignInUtils";

import COConfirmSignIn from "../co/modules/authentication/COConfirmSignIn";
import CORequireNewPassword from "../co/modules/authentication/CORequireNewPassword";
import COSignIn, {
  errorMessageMap,
} from "../co/modules/authentication/COSignIn";

import COTOTPSetup from "../co/modules/authentication/COTOTPSetup";

type TProps = any;
type TState = {
  authState: any,
  authData: any,
  manualSignIn: boolean,
};

const authScreenLabels = {
  en: {
    // Hide create account
    "No account? ": "\n",
    "Create account": "\n",
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(authScreenLabels);

export default function withAmplifyAuthenticator(
  Comp: any,
  includeGreetings: boolean = false,
  authenticatorComponents: any[] = [],
  federated: any = null,
  theme: ?Object = null
) {
  return class extends Component<TProps, TState> {
    state = {
      authState: this.props.authState || null,
      authData: this.props.authData || null,
      manualSignIn: false,
    };

    handleAuthStateChange = (authState: string, authData: any) => {
      const signedIn = authState === "signedIn";

      if (signedIn && this.props.signedIn && !this.props.signInFinished) {
        // signedIn but this issue the first time user login and setup MFA, so we have to manually trigger the signIn handler
        // to update legacy session
        this.manualSignIn(authData);
      } else {
        this.setState({ authState: authState, authData: authData });
      }
    };

    manualSignIn = async (authData: any) => {
      console.log("trigger login after setup MFA");
      await handleSignInPayload(authData, this.props.store.dispatch);

      this.setState({
        manualSignIn: true,
      });
    };

    render() {
      const { authState, authData } = this.state;
      const signedIn = authState === "signedIn";
      if (signedIn || this.state.manualSignIn) {
        // const signInFinished =
        //   this.props.signInFinished || this.state.manualSignIn;
        // if (!signInFinished) {
        //   return "aaaa";
        // }

        return (
          <div>
            {/* {includeGreetings
              ? <Greetings
                  authState={authState}
                  authData={authData}
                  onStateChange={this.handleAuthStateChange}
                />
              : null} */}
            <Comp
              {...this.props}
              authState={authState}
              authData={authData}
              onStateChange={this.handleAuthStateChange}
            />
          </div>
        );
      }

      const content = (
        <Authenticator
          {...this.props}
          theme={theme}
          federated={federated || this.props.federated}
          onStateChange={this.handleAuthStateChange}
          children={authenticatorComponents}
          hideDefault={true}
          errorMessage={errorMessageMap}
        >
          <COSignIn />
          <ForgotPassword />
          <COConfirmSignIn />
          <CORequireNewPassword />
          <COTOTPSetup />
        </Authenticator>
      );

      if (authState == null) {
        // When authState is null, we need to render content for Amplify to continue the process.
        // Otherwise, it will stop and a blank page will be showed
        return content;
      }

      return (
        <UnAuthenticatedLayout>
          <UnAuthenticateContentAddy>
            {content}
          </UnAuthenticateContentAddy>
        </UnAuthenticatedLayout>
      );
    }
  };
}

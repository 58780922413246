import { Alert, Table } from "antd";
import React, { Component } from "react";
import { buildUrl } from "../../../co/modules/layout/SwitchRouteParams";

import ColumnConfigButton, {
  getColumnsInLocalStorage,
} from "./ColumnConfigButton";

export const handlePaginationChange = props => pagination => {
  const { current } = pagination;

  props.history.push(buildUrl(props, { page: current }));
};

export default class DataTable extends Component {
  render() {
    const {
      data: { items, pagination },
      error,
      message,
      ...props
    } = this.props;
    const errorMessage = error || message;
    if (errorMessage) {
      return <Alert type="error" description={errorMessage} />;
    }

    return (
      <Table
        size="small"
        onChange={handlePaginationChange(this.props)}
        dataSource={items}
        pagination={{
          ...pagination,
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${new Intl.NumberFormat("en").format(
              total
            )} items`,
        }}
        {...props}
      />
    );
  }
}

export class DataTableConfigable extends Component<{ name: string }> {
  constructor(props) {
    super(props);

    this.state = this.buildState(props);
  }

  buildState = props => {
    return {
      selectedColumnNames: getColumnsInLocalStorage(
        props.name,
        props.defaultColumns
      ),
    };
  };

  render() {
    const { selectedColumnNames } = this.state;
    const { render, name, defaultColumns, columns, ...props } = this.props;
    const selectedColumns = columns.filter(
      item => selectedColumnNames.indexOf(item.title) >= 0
    );
    return render({
      configButton: this.renderConfigButton(),
      table: <DataTable {...props} columns={selectedColumns} />,
    });
  }

  renderConfigButton = () => {
    const { name, defaultColumns, columns } = this.props;
    return (
      <ColumnConfigButton
        name={name}
        columns={columns.map(item => item.title)}
        defaultColumns={defaultColumns}
        onChange={columns => this.setState({ selectedColumnNames: columns })}
      />
    );
  };
}

// @flow
import { UPDATE_USER, CLEAR_USER } from "../actions/user";

const initState: TypeStateUser = {
  fullname: null,
  email: null,
  avatar: null,
  userRole: null,
  privileges: null,
  isSystemAdmin: false,
};

export default (state: TypeStateUser = initState, action: TypeAction) => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, ...action.payload };
    case CLEAR_USER:
      return initState;
    default:
      return state;
  }
};

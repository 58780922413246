// @flow
import React, { Component } from "react";

import ActionFilter from "./ActionFilter";
import ActionList from "./ActionList";

type TProps = {};

type TState = {};

export default class ActionDashboard extends Component<TProps, TState> {
  render() {
    return (
      <div>
        <ActionFilter showSupplier={true} showEvent={true} />
        <ActionList />
      </div>
    );
  }
}

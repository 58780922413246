import { Select, Form } from "antd";
import React, { Component } from "react";
import { connect, FormikProps } from "formik";
import { rpcCall } from "../../../../../addons/RPC";
import { get } from "lodash";

type TProps = FormikProps & {};
type TState = {};

class SRIAPrimaryTPM extends Component<TProps, TState> {
  state = { value: this.props.defaultValue, managers: [] };

  async componentWillMount() {
    const managers = await rpcCall(
      "EventService/sria_supplier_primary_tpm_get"
    );
    this.setState({ managers: managers });
  }

  handleChange = value => {
    this.setValue(value);
  };

  onSelect = value => {
    this.setValue(value);
  };

  getValue = () => {
    const { name, formik: { values } } = this.props;
    return values[name] || {};
  };

  setValue = value => {
    const { name, formik: { setFieldValue } } = this.props;
    setFieldValue(name, value);
  };

  render() {
    const { managers } = this.state;
    const {
      formik: { values, errors, touched },
      name,
      labelCol,
      wrapperCol,
    } = this.props;

    const value = values[name];

    return (
      <Form.Item
        htmlFor={name}
        required={true}
        label="Primary TPM"
        colon={true}
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        hasFeedback={
          !!(get(touched, name, undefined) && !get(errors, name, undefined))
        }
        validateStatus={(() => {
          if (get(errors, name, undefined)) {
            return "error";
          } else {
            return "success";
          }
        })()}
        help={(() => {
          return get(errors, name, undefined);
        })()}
      >
        <Select
          showSearch={true}
          style={{ width: "100%", marginBottom: 4 }}
          placeholder="Select Primary TPM..."
          value={value}
          onChange={this.handleChange}
          onSelect={this.onSelect}
          optionLabelProp="value"
          optionFilterProp="value"
          allowClear={true}
          mode="tags"
          maxTagCount={1}
        >
          {managers.map(item =>
            <Select.Option key={item.value} value={item.value}>
              {item.label}
            </Select.Option>
          )}
        </Select>
      </Form.Item>
    );
  }
}

export default connect(SRIAPrimaryTPM);

// @flow
import { Icon, Menu, Layout, Dropdown, Row, Col } from "antd";
import { sortBy } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../../../img/logo-main.png";
import { rowStyle } from "../../../co/modules/layout/COCommon";

type TProps = {};
const { Header } = Layout;
const items = [
  { path: "/events", name: "Events", icon: "database" },
  { path: "/suppliers", name: "Suppliers", icon: "bank" },
  {
    path: "/event-rule",
    name: "Event Rules",
    icon: "filter",
    hasAdminRole: true,
  },
  { path: "/actions", name: "Actions", icon: "alert" },
  { path: "/metrics", name: "Metrics", icon: "line-chart", hasAdminRole: true },
  { path: "/competitors", name: "Competitors", icon: "cluster" },
  { path: "/feedback", name: "Feedback", icon: "interaction" },
];

const paths = sortBy(
  items.map(item => item.path),
  path => -path.length
);
class SRIAMenu extends Component<TProps> {
  render() {
    const {
      user: { isSystemAdmin },
      location: { pathname },
    } = this.props;
    let matchKey;
    if (paths.indexOf(pathname) >= 0) {
      matchKey = pathname;
    } else {
      matchKey = paths.filter(item => pathname.includes(item))[0];
    }

    return (
      <Row type="flex" style={{ backgroundColor: "white", height: 48 }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: 48,
              borderBottom: "1px solid #eee",
              paddingTop: 10,
              paddingLeft: 16,
            }}
          >
            <Link to="/">
              <img
                src={logo}
                alt="Home"
                style={{
                  width: 120,
                  paddingRight: 8,
                  display: "block",
                }}
              />
            </Link>
          </div>
          <MainMenu
            selectedKeys={[matchKey]}
            mode="horizontal"
            style={rowStyle}
          >
            {items.map(item => {
              const menuItem = (
                <Menu.Item key={item.path}>
                  <Link to={item.path}>
                    <Icon type={item.icon} />
                    {item.name}
                  </Link>
                </Menu.Item>
              );

              if (item.hasAdminRole) {
                if (isSystemAdmin === true || isSystemAdmin === "true") {
                  return menuItem;
                }

                return null;
              }
              return menuItem;
            })}
          </MainMenu>
        </div>
        <div
          style={{ flex: 1, height: 48, borderBottom: "1px solid #eee" }}
        ></div>
        <UserWrapper>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link to="/profile">
                    <Icon type="user" /> Profile
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link to="/logout">
                    <Icon type="logout" /> Logout
                  </Link>
                </Menu.Item>
              </Menu>
            }
          >
            <UserName className="ant-dropdown-link" href="#">
              <Icon type="user" /> {this.props.user.fullname}{" "}
              <Icon type="down" />
            </UserName>
          </Dropdown>
        </UserWrapper>
      </Row>
    );
  }
}

const UserName = styled.span`
  color: #108ee9;
  &:hover {
    cursor: pointer;
  }
`;

const UserWrapper = styled.div`
  height: 48px;
  border-bottom: 1px solid #eee;
  padding-top: 12px;
  padding-right: 16px;
}
`;

const MainMenu = styled(Menu)`
  .ant-menu-item:hover,
  .ant-menu-item-active {
    border-bottom: 2px solid #d2eafb !important;
    background-color: #d2eafb !important;
  }
`;

const mapStateToProps = state => {
  return {
    user: state.user,
  };
};

// $FlowFixMe
export default connect(mapStateToProps)(SRIAMenu);

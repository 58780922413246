import { actionCodeMap } from "../action/ActionListView";
import React from "react";
import { Row } from "antd";
import NonNewsEventThumbUpDown from "./NonNewsEventThumbUpDown";

export const NonNewsEventInfo = ({ record }) => {
  const { actions: actionCodes, title } =
    record.actions && record.actions.length > 0
      ? { actions: record.actions, title: "Actions" }
      : {
          actions: record.recommend_actions,
          title: "Recommend Actions",
        };

  let action = null;
  if (actionCodes !== null && actionCodes.length > 0) {
    action = (
      <div>
        <b>
          {title}:
        </b>
        <ul style={{ marginLeft: 40, color: "tomato" }}>
          {actionCodes.map(code =>
            <li key={code}>
              {actionCodeMap[code] || code}
            </li>
          )}
        </ul>
      </div>
    );
  }

  let vote = null;
  if (record.report != null && record.report.vote != null) {
    vote = record.report.vote;
  }

  let comment = null;
  if (record.report != null && record.report.comment != null) {
    comment = record.report.comment;
  }

  return (
    <div>
      <Row>
        <div style={{ display: "inline" }}>
          <div style={{ float: "left" }}>
            <b>Event Type:</b> {record.event_name}
          </div>
          <NonNewsEventThumbUpDown
            eventProps={{
              event_id: record.id,
              event_code: record.event_code,
              doc_url_hash: "",
              actions: record.actions,
              recommend_actions: record.recommend_actions,
              vote: vote,
              index: record.index,
              commentText: comment,
            }}
          />
        </div>
      </Row>

      {comment &&
        <Row>
          <div style={{ float: "left" }}>
            <b>Comments:</b>{" "}
            <pre
              style={{
                color: "#1890ff",
                marginLeft: 82,
                marginTop: -20,
                marginBottom: 4,
              }}
            >
              <i>
                {comment}
              </i>
            </pre>
          </div>
        </Row>}

      <Row>
        {action}
      </Row>
    </div>
  );
};

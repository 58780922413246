import { SRIA_ADMIN_STORE_SUPPLIERS } from "../actions/SRIAAdminSupplierAction";
import { SRIA_UPDATE_SUPPLIER_INGESTION_STATUS } from "../actions/SRIAAdminSupplierAction";
import { SRIA_DELETE_SUPPLIER } from "../actions/SRIAAdminSupplierAction";

const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {
    items: [],
    pagination: {
      total: null,
      pageSize: 20,
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_ADMIN_STORE_SUPPLIERS:
      return {
        ...state,
        ...action.payload,
      };

    case SRIA_UPDATE_SUPPLIER_INGESTION_STATUS: {
      const { supplierId } = action.payload;

      const nextItems = state.data.items.map(item => {
        if (item.SRIASupplierID !== supplierId) {
          return item;
        }

        item.ImportStatus = "Importing";

        return item;
      });

      return {
        ...state,
        data: {
          ...state.data,
          items: nextItems,
        },
      };
    }

    case SRIA_DELETE_SUPPLIER: {
      const { supplierId } = action.payload;

      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.filter(
            item => item.SRIASupplierID !== supplierId
          ),
        },
      };
    }

    default:
      return state;
  }
};

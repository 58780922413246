import {
  UPDATE_LOGIN_STATUS,
  CLEAR_LOGIN,
  UPDATE_FORGET_PASSWORD_EMAIL,
} from "../actions/login";

const initState = {
  ok: false,
};

export const loginReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_STATUS:
      return { ...state, ...action.payload };
    case CLEAR_LOGIN:
      return initState;
    case UPDATE_FORGET_PASSWORD_EMAIL:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default loginReducer;

import { Row, Col } from "antd";
import React from "react";
import moment from "moment";
import { NonNewsEventInfo } from "./NonNewsEventInfo";

const Event8kView = ({ record }) => {
  return (
    <div>
      <div style={{ marginLeft: 56, marginBottom: 10 }}>
        <NonNewsEventInfo record={record} />
      </div>

      <ul style={{ marginLeft: 16, listStyleType: "circle" }}>
        {record.docs.map((doc, j) => {
          return (
            <li key={j} style={{ paddingBottom: 16 }}>
              <div>
                <span>
                  <b>{doc.title}</b> - {moment(doc.published_date).format("ll")}
                </span>
              </div>
              <div>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">
                  {doc.url}
                </a>
              </div>
              <div style={{ marginTop: 20 }}>
                <Row>
                  <Col span={6}>
                    <b>SEC Accession No: </b>
                    {doc.summary.accession_no}
                  </Col>

                  <Col span={6}>
                    <b>Filing Date: </b>
                    {doc.summary.filed}
                  </Col>

                  <Col span={6}>
                    <b>File Size: </b>
                    {doc.summary.file_size}
                  </Col>
                  <Col span={6}>
                    <div>
                      <b>Items: </b>
                    </div>
                    <div style={{ paddingLeft: 25 }}>
                      {doc.summary.items.map((item, k) => {
                        return (
                          <span>
                            {item}
                            <br />
                          </span>
                        );
                      })}
                    </div>
                  </Col>
                </Row>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Event8kView;

// @flow
import { Layout } from "antd";

import React, { Component } from "react";
import {
  HashRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import Logout from "../ui/components/Logout";
import ActionDashboard from "./modules/action/ActionDashboard";
import CompetitorDashboard from "./modules/competitor/CompetitorDashboard";
import EventDashboard from "./modules/event/EventDashboard";

import EventRule from "./modules/EventRule/EventRule";

import SRIAFeedback from "./modules/feedback/SRIAFeedback";
import SRIAMenu from "./modules/layout/SRIAMenu";
import MetricsDashboard from "./modules/metrics/MetricsDashboard";
import SRIAUserProfile from "./modules/profile/SRIAUserProfile";
import SRIAAdminSupplier from "./modules/SRIAAdminSupplier/SRIAAdminSupplier";
import SRIAAdminSupplierView from "./modules/SRIAAdminSupplier/SRIAAdminSupplierView";
import SRIAAdminSupplierCreate from "./modules/SRIAAdminSupplier/update/SRIAAdminSupplierCreate";
import SRIAAdminTool from "./modules/SRIAAdminTool/SRIAAdminTool";
import "./sria.css";

type TProps = {
  match: {
    url: string,
  },
};

export default class SRIARoute extends Component<TProps> {
  render() {
    return (
      <Layout>
        <Router>
          <Switch>
            <Route path={`/`} component={SRIAMenu} />
          </Switch>
        </Router>

        <Layout.Content
          style={{
            padding: 16,
            backgroundColor: "white",
            // borderTop: "1px solid #eee",
          }}
        >
          <Router>
            <Switch>
              <Route exact path={`/logout`} component={Logout} />
              <Route exact path={`/events`} component={EventDashboard} />
              <Route exact path={`/actions`} component={ActionDashboard} />
              <Route exact path={`/metrics`} component={MetricsDashboard} />
              <Route
                exact
                path={`/competitors`}
                component={CompetitorDashboard}
              />
              <Route exact path={`/profile`} component={SRIAUserProfile} />
              <Route exact path={`/suppliers`} component={SRIAAdminSupplier} />
              <Route
                exact
                path={`/suppliers/new`}
                component={SRIAAdminSupplierCreate}
              />
              <Route
                exact
                path={`/suppliers/edit/:supplierId`}
                component={SRIAAdminSupplierCreate}
              />
              <Route
                exact
                path={`/suppliers/view/:supplierId`}
                component={SRIAAdminSupplierView}
              />
              <Route exact path={`/event-rule`} component={EventRule} />
              <Route exact path={`/admin`} component={SRIAAdminTool} />
              <Route exact path={`/feedback`} component={SRIAFeedback} />
              <Redirect from="/" to="/events" />
            </Switch>
          </Router>
        </Layout.Content>
      </Layout>
    );
  }
}

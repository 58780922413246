// @flow
import { Alert, Col, Row, Select } from "antd";
import React, { Component } from "react";
import styled from "styled-components";

export const COBox = ({ children }) => {
  return (
    <div
      style={{
        border: "1px solid #ddd",
        padding: 8,
        margin: 8,
        backgroundColor: "#eee",
      }}
    >
      {children}
    </div>
  );
};

export class FormSelect extends Component<{}, {}> {
  render() {
    const { items = [], defaultValue, onChange, style, ...rest } = this.props;
    const firstItemId = items && items.length > 0 ? items[0].id : undefined;
    const finalDefaultValue =
      defaultValue !== undefined ? defaultValue : firstItemId;
    const selectProps = { ...rest };
    if (
      (rest.value == null || rest.value === "") &&
      finalDefaultValue != null &&
      items &&
      items.filter(item => item.id + "" === finalDefaultValue + "").length > 0
    ) {
      selectProps.value = finalDefaultValue + "";
    } else if (typeof selectProps.value === "number") {
      selectProps.value += "";
    } else if (
      items &&
      finalDefaultValue != null &&
      Array.isArray(finalDefaultValue)
    ) {
      selectProps.value = finalDefaultValue;
    }

    return (
      <Select
        {...selectProps}
        onChange={onChange}
        style={{ width: 250, ...style }}
      >
        {items.map((item, k) =>
          <Select.Option
            key={item.id}
            value={item.id + ""}
            placeholder="Select..."
          >
            {item.name}
          </Select.Option>
        )}
      </Select>
    );
  }
}

export const rowStyle = { marginBottom: 4 };
export const centerRowStyle = { marginBottom: "auto", marginTop: "auto" };

export const FormItem = ({ label, required, children }) => {
  let labelClass = "";
  let itemLabelClass = "";
  if (required) {
    labelClass = "ant-form-item-required";
  }
  if (label) {
    itemLabelClass = "ant-form-item-label";
  }
  return (
    <Row style={rowStyle}>
      <Col span={4} className={itemLabelClass}>
        <label className={labelClass} title={label}>
          {label}
        </label>
      </Col>
      <Col span={20}>
        {children}
      </Col>
    </Row>
  );
};

export function ErrorMessage({ message = "Error", description }) {
  return (
    <Alert
      type="error"
      showIcon
      message={message}
      description={
        <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {description}
        </pre>
      }
    />
  );
}

export const MandatoryText = () => {
  return <MandatoryLabel>(Mandatory)</MandatoryLabel>;
};

const MandatoryLabel = styled.label`
  &::before {
    display: inline-block;
    margin-right: 4px;
    margin-top: 6px;
    content: "*";
    font-family: SimSun;
    font-size: 11px;
    color: #f04134;
  }
  color: rgba(0, 0, 0, 1);
  font-size: 11px;
`;

import { Auth, I18n } from "aws-amplify";
import { SignIn, ForgotPassword, FederatedButtons } from "aws-amplify-react";
import React from "react";
import {
  FormSection,
  FormField,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Link,
  Hint,
  Input,
  InputLabel,
  SectionFooterPrimaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React";

import { COAuthButton } from "./COAuthButton";

export default class COSignIn extends SignIn {
  constructor(props) {
    super(props);

    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
    this.state = {};
    this.signInNumber = 0;
  }

  handleKeyPress = (e: Event) => {
    if (e.key === "Enter") {
      this.signIn(e);
    }
  };

  async signIn(event) {
    this.signInNumber++;

    // avoid submitting the form
    if (event) {
      event.preventDefault();
    }

    const { username, password } = this.inputs;

    if (!Auth || typeof Auth.signIn !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    this.setState({ loading: true });
    let error = {};
    try {
      const user = await Auth.signIn(username, password);
      if (
        user.challengeName === "SMS_MFA" ||
        user.challengeName === "SOFTWARE_TOKEN_MFA"
      ) {
        this.changeState("confirmSignIn", user);
      } else if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.changeState("requireNewPassword", user);
      } else if (user.challengeName === "MFA_SETUP") {
        this.changeState("TOTPSetup", user);
      } else {
        this.checkContact(user);
      }
    } catch (err) {
      if (err.code === "UserNotConfirmedException") {
        this.changeState("confirmSignUp", { username });
      } else if (err.code === "PasswordResetRequiredException") {
        this.changeState("forgotPassword", { username });
      } else {
        if (this.signInNumber >= 5 || err.code !== "NetworkError") {
          this.error(err);
        }
        error = err;
      }
    } finally {
      if (error.code && error.code === "NetworkError") {
        const _this = this;
        const timer = setTimeout(() => {
          _this.signIn(event);
        }, 3000);

        if (this.signInNumber === 5) {
          clearTimeout(timer);
          this.signInNumber = 0;
          this.setState({ loading: false });
        }
      } else {
        this.setState({ loading: false });
      }
    }
  }

  showComponent(theme) {
    const { loading } = this.state;
    const {
      authState,
      hide = [],
      federated,
      onStateChange,
      onAuthEvent,
      override = [],
    } = this.props;

    if (hide && hide.includes(COSignIn)) {
      return null;
    }

    const hideForgotPassword =
      !override.includes("ForgotPassword") &&
      hide.some(component => component === ForgotPassword);

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Sign in to your account")}
        </SectionHeader>
        <FederatedButtons
          federated={federated}
          theme={theme}
          authState={authState}
          onStateChange={onStateChange}
          onAuthEvent={onAuthEvent}
        />
        <form onSubmit={this.signIn}>
          <SectionBody theme={theme}>
            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get("Username")} *
              </InputLabel>
              <Input
                autoFocus
                placeholder={I18n.get("Enter your username")}
                theme={theme}
                key="username"
                name="username"
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
              />
            </FormField>

            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get("Password")} *
              </InputLabel>
              <Input
                placeholder={I18n.get("Enter your password")}
                theme={theme}
                key="password"
                type="password"
                name="password"
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
              />
              {!hideForgotPassword &&
                <Hint theme={theme}>
                  {I18n.get("Forget your password? ")}
                  <Link
                    theme={theme}
                    onClick={() => this.changeState("forgotPassword")}
                  >
                    {I18n.get("Reset password")}
                  </Link>
                </Hint>}
            </FormField>
          </SectionBody>

          <SectionFooter theme={theme} />
          <SectionFooterPrimaryContent theme={theme}>
            <COAuthButton
              onClick={this.signIn}
              disabled={loading}
              loading={loading}
            >
              {I18n.get("Sign In")}
            </COAuthButton>
          </SectionFooterPrimaryContent>
        </form>
      </FormSection>
    );
  }
}

export const errorMessageMap = message => {
  if (/.*lambda|network.*/i.test(message)) {
    return I18n.get("There is error due to networking. Please sign-in again!");
  }

  return message;
};

import { Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { isEqual } from "lodash";
import { rpcCall } from "../../../../addons/RPC";
import { showOutputMessage } from "../../../co/modules/request/view/RequestViewUtils";

type TProps = {};
type TState = {};

class _Select extends Component<TProps, TState> {
  state = { value: this.props.defaultValue, managers: [] };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.defaultValue, nextProps.defaultValue)) {
      this.setState({ value: nextProps.defaultValue });
    }
  }

  async componentWillMount() {
    const managers = await rpcCall(
      "EventService/sria_supplier_primary_tpm_get"
    );
    this.setState({ managers: [{ label: "_blank", value: "" }, ...managers] });
  }

  handleChange = value => {
    this.setState({ value });
  };

  onSelect = value => {
    this.updateValue(value);
  };

  updateValue = async value => {
    if (value !== this.props.defaultValue) {
      const { field } = this.props;

      const output = await this.props.update(field, value);

      showOutputMessage(output);

      this.setState({ value });
    }
  };

  render() {
    const { value, managers } = this.state;

    return (
      <Select
        showSearch={true}
        style={{ width: "100%", marginBottom: 4 }}
        placeholder="Select Primary TPM..."
        value={value}
        onChange={this.handleChange}
        onSelect={this.onSelect}
        optionLabelProp="value"
        optionFilterProp="value"
        allowClear
      >
        {managers.map(item =>
          <Select.Option key={item.value} value={item.value}>
            {item.label}
          </Select.Option>
        )}
      </Select>
    );
  }
}

const mapDispatchToProps = (dispatch: Function, ownedProps) => {
  return {
    update: (field, value) => {
      return rpcCall("EventService/sria_user_profile_update", {
        field,
        value,
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(_Select);

// @flow
import { Spin, Table, Tag, Tabs, Alert } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { rpcCall } from "../../../../addons/RPC";

const { TabPane } = Tabs;

type TProps = {};

type TState = {
  loading: boolean,
  data: {
    news_event: {
      events: Object[],
      event_rule: Object,
      event_group: Object,
      word_group: Object[],
    },
    natural_event: Object[],
  },
  error: ?string,
};

export default class EventRule extends Component<TProps, TState> {
  state = {
    data: {
      news_event: {
        events: [],
        event_rule: {},
        event_group: {},
        word_group: [],
      },
      natural_event: [],
    },
    loading: false,
    error: null,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    this.setState({ loading: true });
    try {
      const data = await rpcCall("EventService/sria_admin_event_rule_list");
      this.setState({ data, loading: false });
    } catch (error) {
      this.setState({ error: error.toString(), loading: false });
    }
  };
  render() {
    const {
      loading,
      data: {
        news_event: {
          events,
          event_rule: eventRule,
          event_group: eventGroup,
          word_group: wordGroup,
        },
        natural_event,
      },
      error,
    } = this.state;

    if (!loading && error !== null) {
      return <Alert type="error" description={error} />;
    }
    return (
      <Spin spinning={loading}>
        <h3>
          Supported events:{" "}
          {!loading &&
            <span>
              {events.length + 6}
            </span>}
        </h3>
        <Tabs defaultActiveKey="1">
          <TabPane tab="News Event" key="1">
            <Table
              dataSource={events}
              columns={[
                {
                  title: "ID",
                  dataIndex: "EventID",
                },
                {
                  title: "Event Group",
                  render: record =>
                    <span>
                      {eventGroup[record.EventGroupID].Name}
                    </span>,
                },
                {
                  title: "Name",
                  render: record =>
                    <Link to={`/events?event_codes=${record.Code}`}>
                      {record.Name}
                    </Link>,
                },
                {
                  title: "Risk Level",
                  dataIndex: "RiskLevel",
                },
                {
                  title: "Code",
                  dataIndex: "Code",
                },
              ]}
              rowKey="Code"
              rowClassName={record => `EventRiskLevel_${record.RiskLevel}`}
              pagination={false}
              expandRowByClick={true}
              expandedRowRender={record => {
                return (
                  <Table
                    dataSource={eventRule[record.Code]}
                    columns={[
                      { title: "Syntax", dataIndex: "syntax" },
                      { title: "Example", dataIndex: "example" },
                    ]}
                    pagination={false}
                  />
                );
              }}
            />

            {this.renderWordGroup(wordGroup)}
          </TabPane>
          <TabPane tab="Crunchbase Event" key="2">
            {renderRuleTable(crunchbaseEvent)}
          </TabPane>
          <TabPane tab="Finance Event" key="3">
            {renderRuleTable(financeEvent)}
          </TabPane>
          <TabPane tab="Weather Event" key="4">
            {renderRuleTable(natural_event)}
          </TabPane>
        </Tabs>
      </Spin>
    );
  }

  renderWordGroup = (wordGroup: { name: string, words: string[] }[]) => {
    return (
      <div style={{ marginTop: 10 }}>
        <h3>Word Group</h3>
        <Table
          dataSource={wordGroup}
          columns={[
            { title: "Group", dataIndex: "name", keyIndex: "name" },
            {
              title: "Words",
              render: record =>
                record.words.map(word =>
                  <Tag key={word}>
                    {word}
                  </Tag>
                ),
            },
          ]}
        />
      </div>
    );
  };
}

const crunchbaseEvent = [
  {
    EventGroup: "Company News",
    Name: "Supplier Being Acquired",
    RiskLevel: "Medium",
    DataSource: "Crunchbase API",
  },
  {
    EventGroup: "Company News",
    Name: "Supplier Acquiring Other Company",
    RiskLevel: "Low",
    DataSource: "Crunchbase API",
  },
  {
    EventGroup: "Company News",
    Name: "New Funding / Investment",
    RiskLevel: "Low",
    DataSource: "Crunchbase API",
  },
];

const financeEvent = [
  {
    EventGroup: "Company Financial",
    Name: "Stock Price Drop",
    RiskLevel: "Medium",
    DataSource: "Yahoo Finance",
  },
  {
    EventGroup: "Government Regulator",
    Name: "New 8-K Filing",
    RiskLevel: "Medium",
    DataSource: "SEC Gov",
  },
  {
    EventGroup: "Company Financial",
    Name: "Bearish Financial Sentiment",
    RiskLevel: "Medium",
    DataSource: "Last10k",
  },
  {
    EventGroup: "Customer Review",
    Name: "Consumer Finance Protection Bureau Complaints",
    RiskLevel: "Medium",
    DataSource: "Consumer Finance Gov",
  },
];

const renderRuleTable = dataSource => {
  return (
    <Table
      dataSource={dataSource}
      pagination={false}
      rowClassName={record => `EventRiskLevel_${record.RiskLevel}`}
      size="small"
      columns={[
        {
          title: "Event Group",
          dataIndex: "EventGroup",
          keyIndex: "EventGroup",
        },
        { title: "Name", dataIndex: "Name", keyIndex: "Name" },
        {
          title: "Data Source",
          dataIndex: "DataSource",
          keyIndex: "DataSource",
        },
        { title: "Risk Level", dataIndex: "RiskLevel", keyIndex: "RiskLevel" },
      ]}
    />
  );
};

// @flow
import React, { Component } from "react";
import styled from "styled-components";
type TRuleItem =
  | string
  | {
      entity_type: "PERSON" | "ORG",
      supplier?: boolean,
      key: string,
      word_group?: string,
    };

type TPart = {
  key: string,
  entity_type: "PERSON" | "ORG",
  supplier?: boolean,
  text?: string,
  start: number,
  end: number,
};

type TProps = {
  data: {
    rule_item: TRuleItem,
    parts: TPart[],
  }[],
};

type TState = {
  selectedIndex: ?number,
  hoverIndex: ?number,
};

const entityTypeColorMap = {
  ORG: "blue",
  PERSON: "orange",
  MONEY: "purple",
};

export default class EventExplaination extends Component<TProps, TState> {
  state = {
    selectedIndex: null,
    hoverIndex: null,
  };
  render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    return (
      <div>
        <div>
          <b>Rule</b>: {data.map(this.renderRuleItem)}
        </div>
        <div>
          <b>Match sentence</b>: {data.map(this.renderPartItem)}
        </div>
      </div>
    );
  }

  renderRuleItem = (
    { rule_item: rule }: { rule_item: TRuleItem },
    i: number
  ) => {
    let content;
    if (rule == null) {
      // last part
      return null;
    } else if (typeof rule === "string") {
      content = rule;
    } else if (rule.supplier === true) {
      content = "[supplier]";
    } else if (rule.entity_type != null) {
      content = `[${rule.entity_type}]`;
    } else if (rule.word_group != null) {
      content = `[wg:${rule.word_group}]`;
    } else if (rule.text != null) {
      content = rule.text;
    } else {
      content = JSON.stringify(rule);
    }

    return (
      <span key={i}>
        {" "}<RuleItemContainer
          selected={this.state.selectedIndex === i}
          hovered={this.state.hoverIndex === i}
          onClick={() => this.setState({ selectedIndex: i })}
          onMouseEnter={() => this.setState({ hoverIndex: i })}
          onMouseLeave={() => this.setState({ hoverIndex: null })}
        >
          {content}
        </RuleItemContainer>
      </span>
    );
  };

  renderPartItem = ({ parts }: { parts: TPart[] }, i: number) => {
    if (parts.length === 0) {
      return null;
    }

    const { selectedIndex, hoverIndex } = this.state;
    let affectIndex;
    if (hoverIndex != null) {
      affectIndex = hoverIndex;
    } else {
      affectIndex = selectedIndex;
    }
    const selected = affectIndex === i;
    const rule = this.props.data[i].rule_item;
    const isRulePart = rule !== "..." && rule != null;

    return (
      <span key={i}>
        {" "}<PartContainer selected={selected} isRulePart={isRulePart}>
          {parts.map((part, j) => {
            let content;
            if (part.supplier === true) {
              content = (
                <span
                  style={{ color: "blue", fontWeight: "bold" }}
                  title="Supplier"
                >
                  {part.text}
                </span>
              );
            } else if (part.entity_type != null) {
              content = (
                <span
                  style={{
                    color: entityTypeColorMap[part.entity_type],
                    fontWeight: "bold",
                  }}
                  title={part.entity_type}
                >
                  {part.text}
                </span>
              );
            } else if (part.text != null) {
              content = part.text;
            } else {
              content = JSON.stringify(part);
            }
            return (
              <span key={j}>
                {" "}<span selected={selected}>{content}</span>
              </span>
            );
          })}
        </PartContainer>
      </span>
    );
  };
}

const RuleItemContainer = styled.span`
  background-color: ${props =>
    props.selected || props.hovered ? "#aaa" : "inherit"};
  &:hover {
    cursor: pointer;
  }
`;

const PartContainer = styled.span`
  background-color: ${props => (props.selected ? "yellow" : "inherit")};
  text-decoration: ${props => (props.isRulePart ? "underline" : "inherit")};
  text-decoration-style: dashed;
`;

// @flow

import { rpcCall } from "../../../../addons/RPC";
import React, { Component } from "react";
import { Checkbox, Modal, Button } from "antd";
import { downloadFile } from "../../../../addons/FileUtils";

type TProps = {
  columns: string[],
  defaultColumns: string[],
  onChange: (columns: string[]) => void,
};

type TState = {
  show: boolean,
  selectedColumns: string[],
};

const columns = [
  "DigitID",
  "Name",
  "PrimaryTPM",
  "PrimaryAE",
  "TierFormula",
  "VPManager",
  "Website",
  "EmailDomain",
  "Facebook",
  "Linkedin",
  "Twitter",
  "Instagram",
  "Keywords",
  "Last10k",
  "ConsumerFinance",
  "Crunchbase",
  "Ticker",
];

const defaultColumns = [
  "SRIASupplierID",
  "Name",
  "VPManager",
  "Website",
  "Keywords",
];

export default class SRIASupplierExport extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      show: false,
      selectedColumns: defaultColumns,
    };
  }

  download = async () => {
    const data = await rpcCall("EventService/sria_admin_suppliers_export", {
      columns: this.state.selectedColumns,
    });
    downloadFile(data.blob, data.filename);
  };

  render() {
    const { selectedColumns } = this.state;
    return (
      <React.Fragment>
        <Button
          icon="download"
          onClick={() => {
            this.setState({ show: true });
          }}
        >
          Suppliers Export
        </Button>

        <Modal
          title="Select columns to export"
          visible={this.state.show}
          onOk={this.handleOk}
          onCancel={() => this.setState({ show: false })}
        >
          {columns.map(column =>
            <div key={column}>
              <Checkbox
                type="checkbox"
                defaultChecked={selectedColumns.indexOf(column) >= 0}
                onChange={this.handleChecked(column)}
              >
                {column}
              </Checkbox>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }

  getSelectedColumns = () => this.state.selectedColumns;

  handleChecked = (column: string) => ({
    target: { checked },
  }: {
    target: { checked: boolean },
  }) => {
    const { selectedColumns } = this.state;
    if (checked) {
      this.setState({
        selectedColumns: [...selectedColumns, column],
      });
    } else {
      this.setState({
        selectedColumns: selectedColumns.filter(item => item !== column),
      });
    }
  };

  handleOk = () => {
    // const { selectedColumns } = this.state;

    // this.props.onChange(selectedColumns);
    this.download();

    this.setState({ show: false });
  };
}

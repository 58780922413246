// @flow
import { lightenColor } from "../../addons/Color";
// Color for red; green; yellow to consistent with category20
export const vizColorRed = "#de2d26";
export const vizColorGreen = "#76A732";
export const vizColorYellow = "#fec44f";

export const addyColorBlack = "#1e171f";
export const addyColorGray = "#716870";
export const addyColorWarn = "#ffa32d";
export const addyColorDanger = "#ff5252";
export const addyColorCyan = "#00c5b2";
export const addyColorInfo = "#108fdd";
export const addyColorWhite = "#fff";

export const addyColorMoonGray = lightenColor(0.7, addyColorGray);
export const addyColorLightBlue = lightenColor(0.2, addyColorInfo);

export const addyScoreDomain = [0, 0.025, 0.04, 0.18, 0.35, 1];
export const nonAddyScoreDomain = [0, 0.01, 0.03, 0.06, 0.1, 1];

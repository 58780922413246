import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sriaFeedbackDashboardLoad } from "../../actions/SRIAFeedbackAction";
import { normalizeItems } from "../event/EventUtils";
import FeedbackListView from "./FeedbackListView";

class SRIAFeedback extends Component {
  state = {
    filters: {
      suppliers: [],
      events: [],
      users: [],
    },
  };
  componentWillMount() {
    const payload = {
      ...this.getPayload(this.props),
      with_filter_data: true,
    };
    this.loadData(payload);
  }

  loadData = async payload => {
    // console.log("load data");
    const result = await this.props.sriaFeedbackDashboardLoad(payload);

    if (result.filters) {
      this.setState({ filters: result.filters });
    }

    return result;
  };

  getPayload = props => {
    const {
      match,
      location,
      history,
      sriaFeedbackDashboardLoad,
      ok,
      loading,
      message,
      data,
      params,
      staticContext,
      ...payload
    } = props;
    const params2 = queryString.parse(location.search);

    return {
      ...payload,
      ...params2,
    };
  };

  componentWillReceiveProps(nextProps) {
    const payload = this.getPayload(this.props);
    const nextPayload = this.getPayload(nextProps);
    if (!isEqual(payload, nextPayload)) {
      // console.log("change", nextPayload);
      this.loadData(nextPayload);
    }
  }

  render() {
    return (
      <FeedbackListView
        {...this.props}
        filters={this.state.filters}
        reload={() => this.loadData(this.getPayload(this.props))}
      />
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    page = 1,
    supplier_ids,
    event_codes,
    time_period,
    sortField,
    sortOrder,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);
  return {
    ...state.Feedback,
    page,
    supplierIds: supplier_ids,
    eventCodes: normalizeItems(event_codes),
    timePeriod: time_period,
    sortField,
    sortOrder,
    mySuppliers: mySuppliers === "1",
  };
};

const mapDispatchToProps = {
  sriaFeedbackDashboardLoad,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAFeedback)
);

import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_UPDATE_FEEDBACK_DASHBOARD = "SRIA_UPDATE_FEEDBACK_DASHBOARD";

export function sriaUpdateFeedbackDashboard(payload) {
  return {
    type: SRIA_UPDATE_FEEDBACK_DASHBOARD,
    payload,
  };
}

export function sriaFeedbackDashboardLoad(payload: {
  page: number,
  supplierIds: ?string,
  eventCodes: ?string,
  timePeriod: string,
  sortField: ?string,
  sortOrder: ?string,
  mySuppliers: boolean,
}) {
  return buildRPCThunk({
    func: "EventService/sria_event_report_list",
    params: payload,
    action: sriaUpdateFeedbackDashboard,
  });
}

import { rpcCall } from "../../../addons/RPC";
import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_UPDATE_EVENT_DASHBOARD = "SRIA_UPDATE_EVENT_DASHBOARD";
export const SRIA_UPDATE_MY_SUPPLIERS = "SRIA_UPDATE_MY_SUPPLIERS";
export const SRIA_EVENT_REMOVE = "SRIA_EVENT_REMOVE";
export const SRIA_ADD_EVENT_REPORT = "SRIA_ADD_EVENT_REPORT";
export const SRIA_REMOVE_EVENT_REPORT = "SRIA_REMOVE_EVENT_REPORT";
export const SRIA_UPDATE_EVENT_REPORT = "SRIA_UPDATE_EVENT_REPORT";

export function sriaUpdateEventDashboard(payload) {
  return {
    type: SRIA_UPDATE_EVENT_DASHBOARD,
    payload,
  };
}

export function sriaAddEventReport(payload) {
  return {
    type: SRIA_ADD_EVENT_REPORT,
    payload,
  };
}

export function sriaRemoveEventReport(payload) {
  return {
    type: SRIA_REMOVE_EVENT_REPORT,
    payload,
  };
}

export function sriaUpdateEventReport(payload) {
  return {
    type: SRIA_UPDATE_EVENT_REPORT,
    payload,
  };
}

export function sriaUpdateMySuppliers(payload) {
  return {
    type: SRIA_UPDATE_MY_SUPPLIERS,
    payload,
  };
}

export function sriaEventDashboardLoad(
  page: number,
  supplierIds: ?string,
  eventCodes: ?string,
  timePeriod: string,
  eventImportances: ?string,
  sortField: ?string,
  sortOrder: ?string,
  mySuppliers: boolean
) {
  return buildRPCThunk({
    func: "EventService/sria_events",
    params: {
      page,
      supplier_ids: supplierIds,
      event_codes: eventCodes,
      time_period: timePeriod,
      importances: eventImportances,
      sort_field: sortField,
      sort_order: sortOrder,
      my_suppliers: mySuppliers,
    },
    action: sriaUpdateEventDashboard,
  });
}

export function sriaLoadMySuppliers(mySuppliers) {
  return buildRPCThunk({
    func: "EventService/sria_my_event_filter",
    params: {
      my_suppliers: mySuppliers,
    },
    action: sriaUpdateMySuppliers,
  });
}

export function sriaEventDashboardRemoveAction(eventId) {
  return async dispatch => {
    dispatch({
      type: SRIA_EVENT_REMOVE,
      payload: {
        eventId,
      },
    });

    rpcCall("EventService/sria_admin_event_remove", {
      event_id: eventId,
    });
  };
}

import { Typography, Row, Col } from "antd";
import React from "react";
import { NonNewsEventInfo } from "./NonNewsEventInfo";

const EventWeatherView = ({ record }) => {
  return (
    <div>
      <div style={{ marginBottom: 10 }}>
        <NonNewsEventInfo record={record} />
      </div>

      <div style={{ color: "#0F8BAD", float: "left", width: 100 }}>Area:</div>
      <div style={{ paddingLeft: 20 }}>
        <b>
          {record.area_description}
        </b>
      </div>
      <Row>
        <Col style={{ color: "#0F8BAD" }}>Description:</Col>
      </Row>
      {record.docs.map((doc, j) => {
        return (
          doc.content &&
          <div
            key={j}
            style={{
              marginLeft: 95,
              paddingLeft: 8,
              borderLeft: "5px solid #eee",
            }}
          >
            <Typography.Paragraph
              ellipsis={{ rows: 3, expandable: true }}
              style={{
                whiteSpace: "pre-wrap",
                wordWrap: "break-word",
              }}
            >
              {doc.content}
            </Typography.Paragraph>
          </div>
        );
      })}
    </div>
  );
};

export default EventWeatherView;

import {
  SRIA_ADD_EVENT_REPORT,
  SRIA_REMOVE_EVENT_REPORT,
  SRIA_EVENT_REMOVE,
  SRIA_UPDATE_EVENT_DASHBOARD,
  SRIA_UPDATE_EVENT_REPORT,
} from "../actions/SRIAEventDashboardAction";

const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {
    items: [],
    pagination: {
      total: 0,
      pageSize: 0,
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_UPDATE_EVENT_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    case SRIA_EVENT_REMOVE:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data.items.filter(
            item => item.id !== action.payload.eventId
          ),
        },
      };
    case SRIA_ADD_EVENT_REPORT: {
      // console.log(action.payload);
      // "{"start":326,"end":889,"event_id":"2019-07-19T00:00:00_26_LeadershipNeg","event_code":"LeadershipNeg","doc_url_hash":"534f22033984b291708f301f2c867061","vote":"Down"}"
      const {
        event_id: eventId,
        doc_url_hash: urlHash,
        // start,
      } = action.payload;
      const nextItems = state.data.items.map(item => {
        if (item.id !== eventId) {
          return item;
        }

        const nextDocs = item.docs.map(doc => {
          if (doc.url_hash !== urlHash) {
            return doc;
          }

          return {
            ...doc,
            report: [...(doc.report || []), action.payload],
          };
        });

        return {
          ...item,
          docs: nextDocs,
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          items: nextItems,
        },
      };
    }
    case SRIA_REMOVE_EVENT_REPORT:
      const {
        event_id: eventId,
        doc_url_hash: urlHash,
        start,
      } = action.payload;
      const nextItems = state.data.items.map(item => {
        if (item.id !== eventId) {
          return item;
        }

        const nextDocs = item.docs.map(doc => {
          if (doc.url_hash !== urlHash) {
            return doc;
          }

          return {
            ...doc,
            report: (doc.report || []).filter(r => r.start !== start),
          };
        });

        return {
          ...item,
          docs: nextDocs,
        };
      });
      return {
        ...state,
        data: {
          ...state.data,
          items: nextItems,
        },
      };

    case SRIA_UPDATE_EVENT_REPORT: {
      const { event_id: eventId, vote, comment, actions } = action.payload;

      const nextItems = state.data.items.map(item => {
        if (item.id !== eventId) {
          return item;
        }

        if (actions != null && actions.length > 0) {
          item.actions = actions;
        }

        item.report = { ...item.report, comment: comment };

        if (vote !== "Action") {
          item.report = { ...item.report, vote: vote };
        }

        return item;
      });

      return {
        ...state,
        data: {
          ...state.data,
          items: nextItems,
        },
      };
    }

    default:
      return state;
  }
};

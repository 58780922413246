// @flow
import { Popconfirm, Button, message } from "antd";
import React, { Component } from "react";

import { rpcCall } from "../../../../addons/RPC";
import { connect } from "react-redux";
import { sriaDeleteSupplierAction } from "../../actions/SRIAAdminSupplierAction";

type TProps = {
  supplierId: number,
  removeSupplier: void,
};

type TState = {
  loading: boolean,
};

class SupplierDeleteButton extends Component<TProps, TState> {
  state = {
    loading: false,
  };
  render() {
    return (
      <Popconfirm
        title="Are you sure you want to delete the supplier?"
        onConfirm={this.deleteSupplier}
        okText="Yes"
        cancelText="No"
      >
        <Button
          loading={this.state.loading}
          type="danger"
          size="small"
          icon="delete"
          style={{ marginLeft: 7 }}
        >
          Delete
        </Button>
      </Popconfirm>
    );
  }

  deleteSupplier = async () => {
    this.setState({ loading: true });

    const { supplierId, removeSupplier } = this.props;
    await rpcCall("EventService/sria_delete_future_supplier", {
      supplier_id: supplierId,
    });

    this.setState({ loading: false });
    removeSupplier(supplierId);
    message.info("Supplier has been deleted");
  };
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    removeSupplier: supplierId => {
      return dispatch(sriaDeleteSupplierAction({ supplierId }));
    },
  };
};

export default connect(null, mapDispatchToProps)(SupplierDeleteButton);

// @flow
import { rgb } from "d3-color";
import { interpolateHcl } from "d3-interpolate";
import { schemeCategory20, scaleOrdinal, scaleLinear } from "d3-scale";

/**
 * Convert (hash) a key to color
 */
export type TColorFunction = (key: any) => string;

export const c20: TColorFunction = scaleOrdinal(schemeCategory20);

export type TColorRange = {
  valueMin: number,
  valueMax: number,
  colorStart: string,
  colorEnd: string,
};

/**
 * Create a color range function base on the color range settings
 * 
 * Usage:
 * 
 *  let colorProvider = colorRange({valueMin: 1, valueMax: 5, colorStart: '#eee', colorEnd: '#fff'})
 *  const color1 = colorProvider(1);
 *  const color2 = colorProvider(2);
 * 
 * @param {TColorRange} colorRange The color settings
 */
export function colorRange({
  valueMin,
  valueMax,
  colorStart,
  colorEnd,
}: TColorRange): TColorFunction {
  return scaleLinear()
    .domain([valueMin, valueMax])
    .interpolate(interpolateHcl)
    .range([rgb(colorStart), rgb(colorEnd)]);
}

type TFill = {
  min: number,
  max: number,
  from: string,
  to: string,
};

export function colorRangeFromFill(fill: TFill) {
  return colorRange({
    valueMin: fill.min,
    valueMax: fill.max,
    colorStart: fill.from,
    colorEnd: fill.to,
  });
}

type TColorProviderConfig = TFill & {
  type: "ColorRange",
};

export function getColorProviderFromConfig(config: TColorProviderConfig) {
  const { type, ...providerConfig } = config;
  if (type === "ColorRange") {
    return colorRangeFromFill(providerConfig);
  }

  throw new TypeError(`Unsupport color provider ${type}`);
}

function _shadeBlend(percent: number, color0: string, color1?: string) {
  /*
    lighten: shadeBlend(0.75, "#FF343B");
    darken: shadeBlend(-0.5, rgb(234,47,120));
    blend: shadeBlend(0.333, color1, color2);
    blend: shadeBlend(-0.8, color1, color2); // Same as using 0.8
  */
  var n = percent < 0 ? percent * -1 : percent;
  if (color0.length > 7) {
    let f = color0.split(","),
      t = (color1 != null
        ? color1
        : percent < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","),
      R = parseInt(f[0].slice(4), 10),
      G = parseInt(f[1], 10),
      B = parseInt(f[2], 10);
    return (
      "rgb(" +
      (Math.round((parseInt(t[0].slice(4), 10) - R) * n) + R) +
      "," +
      (Math.round((parseInt(t[1], 10) - G) * n) + G) +
      "," +
      (Math.round((parseInt(t[2], 10) - B) * n) + B) +
      ")"
    );
  } else {
    let f = parseInt(color0.slice(1), 16),
      t = parseInt(
        (color1 != null ? color1 : percent < 0 ? "#000000" : "#FFFFFF").slice(
          1
        ),
        16
      ),
      R1 = f >> 16,
      G1 = (f >> 8) & 0x00ff,
      B1 = f & 0x0000ff;
    return (
      "#" +
      (0x1000000 +
        (Math.round(((t >> 16) - R1) * n) + R1) * 0x10000 +
        (Math.round((((t >> 8) & 0x00ff) - G1) * n) + G1) * 0x100 +
        (Math.round(((t & 0x0000ff) - B1) * n) + B1))
        .toString(16)
        .slice(1)
    );
  }
}

export function lightenColor(percent: number, color: string) {
  if (percent < 0 || percent > 1) {
    throw Error("Percentage must be in range [0, 1]");
  }
  return _shadeBlend(percent, color);
}

export function darkenColor(percent: number, color: string) {
  if (percent < 0 || percent > 1) {
    throw Error("Percentage must be in range [0, 1]");
  }
  return _shadeBlend(-percent, color);
}

import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sriaActionDashboardLoad } from "../../actions/SRIAActionDashboardAction";
import { normalizeItems } from "../event/EventUtils";
import ActionListView from "./ActionListView";

class ActionList extends Component {
  componentWillMount() {
    this.props.sriaActionDashboardLoad(
      this.props.page,
      this.props.supplierIds,
      this.props.eventCodes,
      this.props.timePeriod,
      this.props.sortField,
      this.props.sortOrder,
      this.props.mySuppliers
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      !isEqual(nextProps.supplierIds, this.props.supplierIds) ||
      !isEqual(nextProps.eventCodes, this.props.eventCodes) ||
      !isEqual(nextProps.timePeriod, this.props.timePeriod) ||
      !isEqual(nextProps.sortField, this.props.sortField) ||
      !isEqual(nextProps.sortOrder, this.props.sortOrder) ||
      !isEqual(nextProps.mySuppliers, this.props.mySuppliers)
    ) {
      nextProps.sriaActionDashboardLoad(
        nextProps.page,
        nextProps.supplierIds,
        nextProps.eventCodes,
        nextProps.timePeriod,
        nextProps.sortField,
        nextProps.sortOrder,
        nextProps.mySuppliers
      );
    }
  }

  render() {
    return <ActionListView {...this.props} />;
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    page = 1,
    supplier_ids,
    event_codes,
    time_period,
    sortField,
    sortOrder,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);
  return {
    ...state.ActionDashboard,
    page,
    supplierIds: supplier_ids,
    eventCodes: normalizeItems(event_codes),
    timePeriod: time_period,
    sortField,
    sortOrder,
    mySuppliers: mySuppliers === "1",
  };
};

const mapDispatchToProps = {
  sriaActionDashboardLoad,
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionList)
);

import { Button, Popover, message } from "antd";
import { isEqual } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import SelectionPopover from "react-text-selection-popover";

import styled from "styled-components";

import { rpcCall } from "../../../../addons/RPC";

import { sriaRemoveEventReport } from "../../actions/SRIAEventDashboardAction";

import { showEventReportForm } from "../../actions/SRIAEventReportAction";
import { actionCodeMap } from "../action/ActionListView";

type TProps = {
  eventProps: {
    event_id: string,
    event_code: string,
    doc_url_hash: string,
  },
};

const voteColor = {
  Up: "#DBF5C7",
  Down: "#FBA39E",
  Action: "#91d5ff",
};

type TState = {};
function getSelectionCharOffsetsWithin(element) {
  let start = 0,
    end = 0;
  let sel, range, priorRange, text;
  if (typeof window.getSelection !== "undefined") {
    range = window.getSelection().getRangeAt(0);
    priorRange = range.cloneRange();
    priorRange.selectNodeContents(element);
    priorRange.setEnd(range.startContainer, range.startOffset);
    start = priorRange.toString().length;
    end = start + range.toString().length;
    text = window.getSelection().toString();
  } else if (
    typeof document.selection !== "undefined" &&
    (sel = document.selection).type !== "Control"
  ) {
    range = sel.createRange();
    priorRange = document.body.createTextRange();
    priorRange.moveToElementText(element);
    priorRange.setEndPoint("EndToStart", range);
    start = priorRange.text.length;
    end = start + range.text.length;
    text = range.text;
  }
  return {
    start,
    end,
    snippet: text,
  };
}
const HightlightText = styled.span`
  background-color: ${props => voteColor[props.vote]};
`;

class EventSnippet extends Component<TProps, TState> {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    this.state = this.buildState(props);
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.report, this.props.report)) {
      this.setState(this.buildState(nextProps));
    }
  }

  buildState = props => {
    return {
      report: props.report,
    };
  };

  removeReport = async start => {
    const { eventProps } = this.props;

    const nextReport = this.state.report.filter(item => item.start !== start);
    this.setState({ report: nextReport });
    this.props.sriaRemoveEventReport({
      ...eventProps,
      start,
    });

    await rpcCall("EventService/sria_event_report_remove", {
      ...eventProps,
      start,
    });

    message.info("Removed");
  };

  buildContent = () => {
    const { content } = this.props;
    const { report: indexes = [] } = this.state;

    let parts = [];
    let from = 0;
    let key = 0;

    indexes
      .sort((a, b) => a.start - b.start)
      .forEach(({ start: _start, end: _end, comment, vote, actions }) => {
        if (from < _start) {
          parts.push(
            <span key={key}>
              {content.slice(from, _start)}
            </span>
          );
          key += 1;
        } else {
          _start = from;
        }

        if (_start < _end) {
          const highlightText = content.slice(_start, _end);
          let textPart = (
            <HightlightText key={key} vote={vote}>
              <Popover
                content={
                  <div>
                    {actions &&
                      actions.length > 0 &&
                      <div>
                        <div>
                          <b>Actions</b>
                        </div>
                        <ul>
                          {actions.map(code =>
                            <li key={code}>
                              {actionCodeMap[code] || code}
                            </li>
                          )}
                        </ul>
                      </div>}
                    {comment &&
                      <div>
                        <b>Comment:</b> <i>{comment}</i>
                      </div>}

                    <Button
                      ghost
                      icon="delete"
                      type="danger"
                      onClick={() => this.removeReport(_start)}
                    />
                  </div>
                }
              >
                {highlightText}
              </Popover>
            </HightlightText>
          );

          parts.push(textPart);
          from = _end;
          key += 1;
        }
      });

    // Concat rest text
    parts.push(
      <span key={key}>
        {content.slice(from, content.length)}
      </span>
    );

    return parts;
  };

  render() {
    return (
      <div>
        <div
          ellipsis={{ rows: 3, expandable: true }}
          style={{
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            textOverflow: "ellipsis",
          }}
          ref={this.ref}
        >
          {this.buildContent()}
        </div>
        <SelectionPopover selectionRef={this.ref}>
          <div
            style={{
              border: "1px solid #555",
              borderRadius: 4,
              backgroundColor: "white",
              padding: 8,
            }}
          >
            <Button
              icon="like"
              title="Correct event"
              type="success"
              onClick={() => this.showForm({ vote: "Up" })}
            />{" "}
            <Button
              icon="dislike"
              title="In-correct event"
              type="danger"
              onClick={() => this.showForm({ vote: "Down" })}
            />{" "}
            <Button
              icon="plus"
              title="Create action"
              type="primary"
              onClick={() => this.showForm({ vote: "Action" })}
            />
          </div>
        </SelectionPopover>
      </div>
    );
  }

  showForm = ({ vote }) => {
    this.props.showEventReportForm({
      ...this.props.eventProps,
      vote,
      ...getSelectionCharOffsetsWithin(this.ref.current),
    });
  };
}

const mapDispatchToProps = {
  showEventReportForm,
  sriaRemoveEventReport,
};

export default connect(null, mapDispatchToProps)(EventSnippet);

import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_ADMIN_STORE_SUPPLIERS = "SRIA_ADMIN_STORE_SUPPLIERS";
export const SRIA_ADMIN_STORE_SUPPLIER_NEWS = "SRIA_ADMIN_STORE_SUPPLIER_NEWS";
export const SRIA_ADMIN_STORE_SUPPLIER_EVENTS =
  "SRIA_ADMIN_STORE_SUPPLIER_EVENTS";
export const SRIA_ADMIN_STORE_SUPPLIER_PROFILE =
  "SRIA_ADMIN_STORE_SUPPLIER_PROFILE";
export const SRIA_ADMIN_STORE_SUPPLIER_CRAWLED_DATA =
  "SRIA_ADMIN_STORE_SUPPLIER_CRAWLED_DATA";
export const SRIA_ADMIN_STORE_SUPPLIER_ACTIONS =
  "SRIA_ADMIN_STORE_SUPPLIER_ACTIONS";
export const SRIA_ADMIN_STORE_SUPPLIER_COMPETITORS =
  "SRIA_ADMIN_STORE_SUPPLIER_COMPETITORS";

export const SRIA_UPDATE_SUPPLIER_INGESTION_STATUS =
  "SRIA_UPDATE_SUPPLIER_INGESTION_STATUS";
export const SRIA_DELETE_SUPPLIER = "SRIA_DELETE_SUPPLIER";

function _sriaStoreAdminSuppliers(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIERS,
    payload,
  };
}

function _sriaStoreAdminSupplierProfile(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_PROFILE,
    payload,
  };
}

function _sriaStoreAdminSupplierNews(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_NEWS,
    payload,
  };
}

function _sriaStoreAdminSupplierEvents(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_EVENTS,
    payload,
  };
}

function _sriaStoreAdminSupplierActions(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_ACTIONS,
    payload,
  };
}

function _sriaStoreAdminSupplierCrawledData(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_CRAWLED_DATA,
    payload,
  };
}

function _sriaStoreAdminSupplierCompetitors(payload) {
  return {
    type: SRIA_ADMIN_STORE_SUPPLIER_COMPETITORS,
    payload,
  };
}

export function sriaAdminSuppliersLoadAction(
  page,
  supplierIds: ?string,
  mySuppliers
) {
  return buildRPCThunk({
    func: "EventService/sria_admin_suppliers",
    params: {
      page,
      supplier_ids: supplierIds,
      my_suppliers: mySuppliers,
    },
    action: _sriaStoreAdminSuppliers,
  });
}

export function sriaAdminSupplierProfileAction(supplierId) {
  return buildRPCThunk({
    func: "EventService/sria_admin_supplier_profile",
    params: {
      supplier_id: supplierId,
    },
    action: _sriaStoreAdminSupplierProfile,
  });
}

export function sriaAdminSupplierNewsAction({ supplierId, page, query }) {
  return buildRPCThunk({
    func: "EventService/sria_admin_supplier_news",
    params: {
      supplier_id: supplierId,
      page,
      query,
    },
    action: _sriaStoreAdminSupplierNews,
  });
}

export function sriaAdminSupplierEventsAction({
  supplierId,
  page,
  sortField,
  sortOrder,
}) {
  return buildRPCThunk({
    func: "EventService/sria_admin_supplier_events",
    params: {
      supplier_id: supplierId,
      page,
      sort_field: sortField,
      sort_order: sortOrder,
    },
    action: _sriaStoreAdminSupplierEvents,
  });
}

export function sriaAdminSupplierActionsAction({
  supplierId,
  page,
  sortOrder,
  sortField,
}) {
  return buildRPCThunk({
    func: "EventService/sria_admin_supplier_actions",
    params: {
      supplier_id: supplierId,
      page,
      sort_order: sortOrder,
      sort_field: sortField,
    },
    action: _sriaStoreAdminSupplierActions,
  });
}

export function sriaAdminSupplierCrawledDataAction({
  supplierId,
  dataSource,
  page,
}) {
  return buildRPCThunk({
    func: "EventService/sria_admin_supplier_data",
    params: {
      supplier_id: supplierId,
      data_source: dataSource,
      page,
    },
    action: _sriaStoreAdminSupplierCrawledData,
  });
}

export function sriaAdminSupplierCompetitorsAction({ supplierId, page }) {
  return buildRPCThunk({
    func: "EventService/sria_admin_competitors",
    params: {
      supplier_id: supplierId,
      page,
    },
    action: _sriaStoreAdminSupplierCompetitors,
  });
}

export function sriaUpdateSupplierIngestionStatusAction(payload) {
  return {
    type: SRIA_UPDATE_SUPPLIER_INGESTION_STATUS,
    payload,
  };
}

export function sriaDeleteSupplierAction(payload) {
  return {
    type: SRIA_DELETE_SUPPLIER,
    payload,
  };
}

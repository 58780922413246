import { Row, Col } from "antd";
import React from "react";
import { NonNewsEventInfo } from "./NonNewsEventInfo";

const EventViolationView = ({ record }) => {
  return (
    <div>
      <div style={{ marginLeft: 60, marginBottom: 10 }}>
        <NonNewsEventInfo record={record} />
      </div>

      <div style={{ marginLeft: 60, marginBottom: 10 }}>
        <Row>
          <Col span={8} style={{ fontSize: "160%", color: "#1e5352" }}>
            Violation Tracker Individual Record
          </Col>
        </Row>
        <Row>
          <Row>
            <Col
              lg={{ span: 5 }}
              xxl={{ span: 3 }}
              style={{ fontWeight: "bold", fontSize: 13 }}
            >
              Tracker URL:
            </Col>
            <Col lg={{ span: 17 }} xxl={{ span: 18 }}>
              <a
                href={record.docs[0].url}
                target="_blank"
                rel="noopener noreferrer"
              >
                Violation Tracker Record
              </a>
            </Col>
          </Row>
        </Row>
        <TrackerField record={record} field="Company" />
        <TrackerField record={record} field="Parent Company" />
        <TrackerField record={record} field="Penalty Amount" />
        <TrackerField record={record} field="Subtraction From Penalty" />

        <TrackerField record={record} field="Penalty Year" />
        <TrackerField record={record} field="Penalty Date" />
        <TrackerField record={record} field="Primary Offense" />
        <TrackerField
          record={record}
          field="Description"
          label="Violation Description"
        />

        <TrackerField record={record} field="Level of Government" />
        <TrackerField record={record} field="Action Type" />
        <TrackerField record={record} field="Agency" />
        <TrackerField
          record={record}
          field="Civil/Criminal"
          label="Civil or Criminal Case"
        />
        <TrackerField record={record} field="HQ Country of Parent" />
        <TrackerField record={record} field="HQ State of Parent" />
        <TrackerField
          record={record}
          field="Ownership Structure"
          label="Ownership Structure of Parent"
        />
        <TrackerField record={record} field="Major Industry of Parent" />

        <TrackerField record={record} field="Specific Industry of Parent" />

        <TrackerField
          record={record}
          field="Info Source"
          label="Source of Data"
          link={true}
        />
      </div>
    </div>
  );
};

const TrackerField = ({ record, field, label, link }) => {
  const content = JSON.parse(record.docs[0].content);

  const fieldLabel = label ? label : field;
  let fieldValue = content[field];
  if (!fieldValue) {
    return null;
  }
  if (link) {
    const sourceURL = fieldValue.match(/\bhttps?:\/\/\S+/gi);
    const url = sourceURL && sourceURL[0];

    fieldValue = (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }
  return (
    <Row>
      <Col
        lg={{ span: 5 }}
        xxl={{ span: 3 }}
        style={{ fontWeight: "bold", fontSize: 13 }}
      >
        {fieldLabel}:
      </Col>
      <Col lg={{ span: 17 }} xxl={{ span: 18 }}>
        {fieldValue}
      </Col>
    </Row>
  );
};

export default EventViolationView;

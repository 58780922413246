import { Form, Radio } from "antd";
import React, { Component } from "react";
import { connect, FormikProps } from "formik";
import styled from "styled-components";

type TProps = FormikProps & {};
type TState = {};

class SRIASupplierRelationship extends Component<TProps, TState> {
  state = {};

  handleChange = e => {
    this.setValue(e.target.value);
  };

  getValue = () => {
    const { name, formik: { values } } = this.props;
    return values[name] || {};
  };

  setValue = value => {
    const { name, formik: { setFieldValue } } = this.props;

    setFieldValue(name, value);
  };

  render() {
    const { formik: { values }, name, labelCol, wrapperCol } = this.props;

    const value = values[name] || "current";

    return (
      <Form.Item
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        label="Relationship Status"
        colon={true}
      >
        <RadioGroupStyled
          value={value}
          buttonStyle="solid"
          onChange={this.handleChange}
        >
          <Radio.Button value="current">Current</Radio.Button>
          <Radio.Button value="future">Future</Radio.Button>
        </RadioGroupStyled>
      </Form.Item>
    );
  }
}

const RadioGroupStyled = styled(Radio.Group)`
  .ant-radio-button-wrapper-checked {
    color: #fff;
    background: #f79828 !important;
    border-color: #f79828 !important;
    box-shadow: -1px 0 0 0 #f79828 !important;;
  }
`;

export default connect(SRIASupplierRelationship);

// @flow
import { Button, Checkbox, Modal } from "antd";

import React, { Component } from "react";

type TProps = {
  name: string,
  columns: string[],
  defaultColumns: string[],
  onChange: (columns: string[]) => void,
};

type TState = {
  show: boolean,
  selectedColumns: string[],
};

export default class ColumnConfigButton extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = this.loadData(props);
  }

  loadData = (props: TProps) => {
    const { name, defaultColumns } = props;

    return {
      show: false,
      selectedColumns: getColumnsInLocalStorage(name, defaultColumns),
    };
  };

  render() {
    const { selectedColumns } = this.state;
    const { columns } = this.props;
    return (
      <React.Fragment>
        <Button
          icon="setting"
          title="Show/Hide Columns"
          onClick={() => this.setState({ show: true })}
        />
        <Modal
          title="Show/Hide Columns"
          visible={this.state.show}
          onOk={this.handleOk}
          onCancel={() => this.setState({ show: false })}
        >
          {columns.map(column =>
            <div key={column}>
              <Checkbox
                type="checkbox"
                defaultChecked={selectedColumns.indexOf(column) >= 0}
                onChange={this.handleChecked(column)}
              >
                {column}
              </Checkbox>
            </div>
          )}
        </Modal>
      </React.Fragment>
    );
  }

  getSelectedColumns = () => this.state.selectedColumns;

  handleChecked = (column: string) => ({
    target: { checked },
  }: {
    target: { checked: boolean },
  }) => {
    const { selectedColumns } = this.state;
    if (checked) {
      this.setState({
        selectedColumns: [...selectedColumns, column],
      });
    } else {
      this.setState({
        selectedColumns: selectedColumns.filter(item => item !== column),
      });
    }
  };

  handleOk = () => {
    const { selectedColumns } = this.state;

    // Store to localStorage
    localStorage.setItem(this.props.name, JSON.stringify(selectedColumns));

    this.props.onChange(selectedColumns);

    this.setState({ show: false });
  };
}

export function getColumnsInLocalStorage(
  name: string,
  defaultColumns: string[]
) {
  const result = localStorage.getItem(name);
  if (result == null) {
    return defaultColumns;
  } else {
    return JSON.parse(result);
  }
}

// @flow
import type { TTheme } from "../reducers/ThemeReducer";

export const LOAD_THEME = "LOAD_THEME";
export const CLEAR_THEME = "CLEAR_THEME";

export function loadTheme(theme: TTheme) {
  return {
    type: LOAD_THEME,
    payload: {
      theme,
    },
  };
}

export function clearTheme() {
  return {
    type: CLEAR_THEME,
  };
}

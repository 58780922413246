// @flow
import { Button, Checkbox } from "antd";

import React, { Component } from "react";
import styled from "styled-components";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

type TProps = {
  options: number[],
  defaultOption: number,
  confirm: () => void,
  location: any,
};

type TState = {
  selectedOption: number,
};

class SRIAAdminScoreFilter extends Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);

    this.state = {
      selectedOption: this.getDefaultOption(props),
    };
  }

  render() {
    const { selectedOption } = this.state;
    const { options } = this.props;
    return (
      <FilterMenu>
        <OptionDiv>
          {options.map(value =>
            <CheckboxDiv key={value}>
              <Checkbox
                type="checkbox"
                checked={selectedOption === value}
                onChange={() => this.handleChecked(value)}
              >
                {value} days
              </Checkbox>
            </CheckboxDiv>
          )}
        </OptionDiv>
        <ButtonDiv>
          <ButtonStyled onClick={this.handleOk}>OK</ButtonStyled>
          <ButtonStyled onClick={this.handleReset}>Reset</ButtonStyled>
        </ButtonDiv>
      </FilterMenu>
    );
  }

  handleChecked = (value: number) => {
    this.setState({
      selectedOption: value,
    });
  };

  handleOk = () => {
    this.props.confirm();
    const { selectedOption } = this.state;
    changeUrl(this.props, { score_interal: selectedOption });
  };

  handleReset = () => {
    this.props.confirm();
    const { defaultOption } = this.props;
    this.setState({
      selectedOption: defaultOption,
    });
    changeUrl(this.props, { score_interal: defaultOption });
  };

  getDefaultOption = props => {
    const { score_interal: scoreInterval } = queryString.parse(
      props.location.search
    );

    if (!scoreInterval) {
      return props.defaultOption;
    }

    // eslint-disable-next-line radix
    return parseInt(scoreInterval);
  };
}

const FilterMenu = styled.div`padding: 0px;`;
const OptionDiv = styled.div`
  padding-left: 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e8e8e8;
`;

const ButtonDiv = styled.div`padding: 3px 10px;`;
const ButtonStyled = styled(Button)`
    color: #1890ff !important;
    border: none !important;
`;
const CheckboxDiv = styled.div`padding: 5px 12px;`;

export default withRouter(SRIAAdminScoreFilter);

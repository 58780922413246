// @flow
import { rpcCall } from "../../../addons/RPC";

export const defaultRPCState = {
  loading: true,
  ok: true,
  message: null,
  params: {},
};

export function buildRPCThunk({ func, params, action, updateData = true }) {
  return async (dispatch: Function) => {
    let payload;

    dispatch(
      action({
        loading: true,
        params,
      })
    );

    let data;
    try {
      data = await rpcCall(func, params);
      payload = {
        ok: true,
        loading: false,
      };

      if (updateData) {
        payload.data = data;
      }
    } catch (e) {
      payload = { ok: false, message: e.message, loading: false };
    }

    dispatch(action(payload));

    return data;
  };
}

import { Button, message } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { rpcCall } from "../../../../addons/RPC";
import { sriaAdminSupplierEventsAction } from "../../actions/SRIAAdminSupplierAction";
import EventListView from "../event/EventListView";

type TProps = {
  loadData: Function,
  supplierId: number,
  page: number,
  sortOrder: string,
  sortField: string,
};

type TState = {
  loading: boolean,
};

class SRIAAdminSupplierEvent extends Component<TProps, TState> {
  state = {
    loading: false,
  };
  componentDidMount() {
    this.props.loadData(
      this.props.supplierId,
      this.props.page,
      this.props.sortField,
      this.props.sortOrder
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.supplierId !== this.props.supplierId ||
      nextProps.page !== this.props.page ||
      nextProps.sortField !== this.props.sortField ||
      nextProps.sortOrder !== this.props.sortOrder
    ) {
      nextProps.loadData(
        nextProps.supplierId,
        nextProps.page,
        nextProps.sortField,
        nextProps.sortOrder
      );
    }
  }

  execute = async () => {
    this.setState({ loading: true });
    await rpcCall("EventService/sria_admin_event_detection_rerun", {
      supplier_id: this.props.supplierId,
    });
    this.setState({ loading: false });
    message.info("Pipeline has been triggered. Please check later");
  };

  render() {
    return (
      <div>
        <div style={{ marginBottom: 16 }}>
          <Button loading={this.state.loading} onClick={this.execute}>
            Re-detect news events
          </Button>
        </div>
        <EventListView showSupplier={false} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const { page = 1, sortField, sortOrder } = queryString.parse(
    ownedProps.location.search
  );
  return {
    supplierId: Number(ownedProps.match.params.supplierId),
    page: Number(page),
    sortField,
    sortOrder,
    ...state.SupplierEvents,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (supplierId, page, sortField, sortOrder) =>
      dispatch(
        sriaAdminSupplierEventsAction({
          supplierId,
          page,
          sortField,
          sortOrder,
        })
      ),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplierEvent)
);

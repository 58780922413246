import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_UPDATE_COMPETITOR_DASHBOARD =
  "SRIA_UPDATE_COMPETITOR_DASHBOARD";
export const SRIA_LOAD_COMPETITOR_FILTER = "SRIA_LOAD_COMPETITOR_FILTER";

export function _sriaUpdateCompetitorDashboard(payload) {
  return {
    type: SRIA_UPDATE_COMPETITOR_DASHBOARD,
    payload,
  };
}

export function _sriaLoadCompetitorFilter(payload) {
  return {
    type: SRIA_LOAD_COMPETITOR_FILTER,
    payload,
  };
}

export function sriaCompetitorDashboardLoad(
  page: number,
  supplierIds: ?string,
  mySuppliers: boolean,
  competitorNames: ?string
) {
  return buildRPCThunk({
    func: "EventService/sria_competitors",
    params: {
      page,
      supplier_ids: supplierIds,
      my_suppliers: mySuppliers,
      competitor_names: competitorNames,
    },
    action: _sriaUpdateCompetitorDashboard,
  });
}

export function sriaCompetitorFilterLoad(mySuppliers) {
  return buildRPCThunk({
    func: "EventService/sria_my_competitor_filter",
    params: {
      my_suppliers: mySuppliers,
    },
    action: _sriaLoadCompetitorFilter,
  });
}

import { Select, Row, Col } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SwitchRouteParams, {
  changeUrl,
} from "../../../co/modules/layout/SwitchRouteParams";
import { sriaCompetitorFilterLoad } from "../../actions/SRIACompetitorDashboardAction";
import { normalizeIds, normalizeItems } from "../event/EventUtils";

class CompetitorFilter extends Component<TProps> {
  state = {
    value: null,
  };

  componentDidMount() {
    this.props.loadData(this.props.mySuppliers);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.mySuppliers !== this.props.mySuppliers) {
      nextProps.loadData(nextProps.mySuppliers);
    }
  }

  handleSupplierChange = changes => {
    changeUrl(this.props, { supplier_ids: changes, page: 1 });
  };

  handleCompetitorChange = changes => {
    changeUrl(this.props, { competitor_names: changes, page: 1 });
  };

  render() {
    let {
      supplierIds,
      competitorNames,
      data: { suppliers = [], competitors = [] },
      loading,
    } = this.props;

    return (
      <div>
        <Row gutter={8}>
          <Col span={9}>
            {!loading &&
              <Select
                mode="multiple"
                style={{ width: "100%", marginBottom: 4 }}
                placeholder="Filter by supplier..."
                value={supplierIds}
                onChange={this.handleSupplierChange}
                optionLabelProp="label"
                optionFilterProp="label"
                allowClear
              >
                {suppliers.map(supplier =>
                  <Select.Option
                    key={supplier.id}
                    value={supplier.id}
                    label={supplier.name}
                  >
                    {supplier.name}
                  </Select.Option>
                )}
              </Select>}
          </Col>

          <Col span={9}>
            {!loading &&
              <Select
                mode="multiple"
                style={{ width: "100%", marginBottom: 4 }}
                placeholder="Filter by competitor..."
                value={competitorNames}
                onChange={this.handleCompetitorChange}
                optionLabelProp="label"
                optionFilterProp="label"
                allowClear
              >
                {competitors.map(name =>
                  <Select.Option key={name} value={name} label={name}>
                    {name}
                  </Select.Option>
                )}
              </Select>}
          </Col>

          <Col span={6}>
            <SwitchRouteParams
              field="my_suppliers"
              title="My Suppliers"
              defaultChecked={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    supplier_ids: supplierIds,
    competitor_names: competitorNames,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);

  return {
    ...state.CompetitorFilter,
    supplierIds: normalizeIds(supplierIds),
    competitorNames: normalizeItems(competitorNames),
    mySuppliers: mySuppliers === "1",
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: mySuppliers => {
      return dispatch(sriaCompetitorFilterLoad(mySuppliers));
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompetitorFilter)
);

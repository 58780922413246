// @flow
import { message, Button, Upload } from "antd";
import React, { Component } from "react";
import { buildAPIUrl } from "../../../../addons/RPC";

type TProps = {
  onUploaded: Function,
};

type TState = {};

const props = {
  name: "file__document",
  multiple: false,
  showUploadList: false,
  withCredentials: true,
  action: buildAPIUrl("/rpc/EventService/sria_admin_suppliers_import"),
};

const csvMimeType = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

function beforeUpload(file) {
  const isExcel =
    csvMimeType.includes(file.type) || file.name.endsWith(".xlsx");

  if (!isExcel) {
    message.error("You must have upload excel file!");
  }

  return isExcel;
}

export default class SupplierUpload extends Component<TProps, TState> {
  state = {
    file: null,
  };

  onUploaded = (info: Object) => {
    const status = info.file.status;
    if (status !== "uploading") {
      if (this.props.onUploaded) {
        this.props.onUploaded({
          fileName: info.file.name,
        });
      }
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  render() {
    return (
      <Upload beforeUpload={beforeUpload} onChange={this.onUploaded} {...props}>
        <Button icon="cloud-upload">Suppliers Import</Button>
      </Upload>
    );
  }
}

import { Icon } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { buildUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import DataTable from "../SRIAAdminSupplier/DataTable";

export const actionCodeMap = {
  InformBusiness: "Inform Business",
  ReviewContract: "Review Contract",
  ContactSupplier: "Contact Supplier",
  CaptureForContractRenewal: "Capture For Contract Renewal",
  PerformResearchTasks: "Perform Research Tasks",
  RiskAnalysisOfSubcontractor: "Risk Analysis Of Subcontractor",
  CaptureForFutureQBRQuarterlyBusinessReview:
    "Capture For Future QBR Quarterly Business Review",
  MeetWithTenpoint7Compliance: "Meet With Capital One Compliance",
};

class ActionListView extends Component {
  handleTableChange = (pagination, sorter) => {
    const extraParams = {
      ...this.state,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      page: pagination.current,
    };

    changeUrl(this.props, extraParams);
  };

  render() {
    return (
      <div>
        {this.renderDataTable()}
      </div>
    );
  }

  renderDataTable = () => {
    return (
      <DataTable
        {...this.props}
        rowKey={record => {
          return `${record.effective_date}_${record.supplier_id}_${record.event_code}_${record.action_code}`;
        }}
        rowClassName={record => `Action_${record.event_risk_level}`}
        columns={this.getColumns()}
        onChange={(pagination, filters, sorter) => {
          this.handleTableChange(pagination, sorter);
        }}
      />
    );
  };

  getColumns = () => {
    const { showSupplier = true } = this.props;
    let ret = [
      {
        title: "Effective Date",
        render: record => {
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {moment(record.effective_date).format("ll")}
            </span>
          );
        },
        key: "effective_date",
        sorter: true,
      },
    ];

    if (showSupplier) {
      ret.push({
        title: "Supplier",
        key: "supplier_name",
        sorter: true,
        render: record =>
          <span>
            <Link
              to={buildUrl(this.props, { supplier_ids: record.supplier_id })}
            >
              {record.supplier_name}
            </Link>{" "}
            <Link to={`/suppliers/view/${record.supplier_id}`}>
              <Icon type="link" />
            </Link>
          </span>,
      });
    }

    ret = [
      ...ret,
      {
        title: "Event",
        key: "event_code",
        sorter: true,
        render: record =>
          <Link
            to={buildUrl(this.props, { event_codes: record.event_code })}
            style={{ whiteSpace: "nowrap" }}
          >
            {record.event_name}
          </Link>,
      },
      {
        title: "Action",
        key: "action_code",
        sorter: true,
        render: record => {
          return actionCodeMap[record.action_code] || record.action_code;
        },
      },
    ];

    return ret;
  };
}

export default withRouter(ActionListView);

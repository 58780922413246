// @flow
import { Col, DatePicker, Row, Spin, Select, Alert } from "antd";
import { isEqual } from "lodash";
import moment from "moment";
import queryString from "query-string";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import styled from "styled-components";
import { floor2Digits } from "../../../../addons/MathUtils";
import { rpcCall } from "../../../../addons/RPC";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
const { Option } = Select;
type TProps = {};

type TState = {
  loading: boolean,
  data: ?Object,
  error: ?string,
};

function SampleChart({ name, data }) {
  const total = data.map(({ count }) => count).reduce((a, b) => a + b, 0);
  return (
    <div>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis
          type="number"
          tickFormatter={tick => {
            return tick.toLocaleString();
          }}
        />
        <Tooltip />
        <Legend />
        <Line
          name={`# ${name}`}
          type="monotone"
          dataKey="count"
          stroke="#8884d8"
          // activeDot={{ r: 8 }}
          dot={false}
        />
      </LineChart>
      <div style={{ textAlign: "center" }}>
        <b>Total {name}</b>: {total.toLocaleString()}
      </div>
    </div>
  );
}

class MetricsDashboard extends Component<TProps, TState> {
  state = {
    loading: false,
    data: null,
    error: null,
  };

  componentDidMount() {
    this.loadData(this.props);
  }

  loadData = async props => {
    const params = queryString.parse(props.location.search);

    this.setState({ loading: true });
    try {
      const data = await rpcCall("EventService/sria_metrics", params);
      this.setState({ data, loading: false });
    } catch (error) {
      this.setState({ error: error.toString(), loading: false });
    }
  };

  componentWillReceiveProps = nextProps => {
    const params = queryString.parse(this.props.location.search);
    const nextParams = queryString.parse(nextProps.location.search);
    if (!isEqual(params, nextParams)) {
      this.loadData(nextProps);
    }
  };

  render() {
    const { loading, data, error } = this.state;
    const params = queryString.parse(this.props.location.search);

    if (!loading && error !== null) {
      return <Alert type="error" description={error} />;
    }

    return (
      <Spin spinning={loading}>
        {data &&
          <div>
            <div>
              <Label># Suppliers:</Label>
              <Value>
                {data.suppliers.toLocaleString()}
              </Value>
            </div>

            <div>
              <Label># Supplier Managers:</Label>
              <Value>
                {data.supplier_managers.toLocaleString()}
              </Value>
            </div>

            <div>
              <Label># Competitors:</Label>
              <Value>
                {data.competitors.toLocaleString()}
              </Value>
            </div>

            <div>
              <Label># Unique Sources: </Label>
              <Value>
                {data.unique_sources.toLocaleString()}
              </Value>
            </div>

            <div>
              <Label># Event Types:</Label>
              <Value>
                {data.event_types}
              </Value>
            </div>

            <div>
              <Label># Action Types:</Label>
              <Value>
                {data.action_types}
              </Value>
            </div>

            <div title="COUNT(Events) / Count (Suppliers) where Effective Date between (today-30) and today">
              # Average Events per Supplier (past 30 days):{" "}
              <b>
                {data.suppliers > 0
                  ? floor2Digits(data.events_30 / data.suppliers)
                  : <i>--</i>}
              </b>
            </div>
            <Row style={{ marginBottom: 16 }}>
              <Col span={4}>
                <FieldLabel>Start Date</FieldLabel>
                <DatePicker
                  defaultValue={
                    params.start_date ? moment(params.start_date) : null
                  }
                  onChange={value => {
                    changeUrl(this.props, {
                      start_date: value
                        ? value.format("YYYY-MM-DD")
                        : undefined,
                    });
                  }}
                />
              </Col>
              <Col span={4}>
                <FieldLabel>End Date</FieldLabel>
                <DatePicker
                  defaultValue={
                    params.end_date ? moment(params.end_date) : null
                  }
                  onChange={value => {
                    changeUrl(this.props, {
                      end_date: value ? value.format("YYYY-MM-DD") : undefined,
                    });
                  }}
                />
              </Col>
              <Col span={4}>
                <FieldLabel>Interval</FieldLabel>
                <Select
                  defaultValue={params.interval || "week"}
                  placeholder="Select time period"
                  style={{ width: "100%" }}
                  onChange={value =>
                    changeUrl(this.props, {
                      interval: value,
                    })}
                >
                  <Option value="day">Day</Option>
                  <Option value="week">Week</Option>
                  <Option value="month">Month</Option>
                  <Option value="year">Year</Option>
                </Select>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col lg={{ span: 12 }} xxl={{ span: 8 }}>
                <SampleChart
                  name="News"
                  data={data.news.map(([date, count]) => ({
                    name: date,
                    count,
                  }))}
                />
              </Col>

              <Col lg={{ span: 12 }} xxl={{ span: 8 }}>
                <SampleChart
                  name="Events"
                  data={data.events.map(([date, count]) => ({
                    name: date,
                    count,
                  }))}
                />
              </Col>

              <Col lg={{ span: 12 }} xxl={{ span: 8 }}>
                <SampleChart
                  name="Actions"
                  data={data.actions.map(([date, count]) => ({
                    name: date,
                    count,
                  }))}
                />
              </Col>

              <Col lg={{ span: 12 }} xxl={{ span: 8 }}>
                <SampleChart
                  name="Feedback"
                  data={data.feedbacks.map(([date, count]) => ({
                    name: date,
                    count,
                  }))}
                />
              </Col>
            </Row>
          </div>}
      </Spin>
    );
  }
}

const FieldLabel = styled.div`font-weight: bold;`;
const Label = styled.span`
  display: inline-block;
  width: 142px;
`;
const Value = styled.span`font-weight: bold;`;

export default withRouter(MetricsDashboard);

import { Alert } from "antd";
import { Auth, I18n } from "aws-amplify";
import { TOTPSetup } from "aws-amplify-react";
import React from "react";

import {
  FormSection,
  SectionHeader,
  SectionBody,
  SectionFooter,
  InputLabel,
  Input,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React";
import TOTPSetupComp from "aws-amplify-react/dist/Widget/TOTPSetupComp";
import AmplifyTheme from "../../../ui/AmplifyTheme";
import { handleSignInPayload } from "../../COSignInUtils";
import { COAuthButton } from "./COAuthButton";

import { totpQrcode } from "@aws-amplify/ui";

const QRCode = require("qrcode.react");

class COTOTPSetupComp extends TOTPSetupComp {
  state = {
    loading: false,
    code: null,
    setupMessage: null,
  };

  verifyTotpToken = async () => {
    if (!this.inputs) {
      console.log("no input");
      return;
    }

    this.setState({ loading: true });
    const user = this.props.authData;
    const { totpCode } = this.inputs;
    if (
      !Auth ||
      typeof Auth.verifyTotpToken !== "function" ||
      typeof Auth.setPreferredMFA !== "function"
    ) {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }
    await Auth.verifyTotpToken(user, totpCode)
      .then(async () => {
        // set it to preferred mfa
        Auth.setPreferredMFA(user, "TOTP");
        this.setState({ setupMessage: "Setup TOTP successfully!" });
        console.log("set up totp success!", user);
        this.triggerTOTPEvent("Setup TOTP", "SUCCESS", user);

        // Call handle signin
        await handleSignInPayload(user, window.dispatch);

        // reload
        window.location.reload();
      })
      .catch(err => {
        this.setState({ setupMessage: err.message });
        console.error(err);
      });
    this.setState({ loading: false });
  };

  showSecretCode = (code, theme) => {
    if (!code) return null;
    return (
      <div>
        <div className={totpQrcode}>
          <QRCode value={code} />
        </div>
        <InputLabel theme={theme}>Enter Security Code:</InputLabel>
        <Input
          autoFocus
          theme={theme}
          key="totpCode"
          name="totpCode"
          onChange={this.handleInputChange}
          onKeyPress={this.handleKeyPress}
        />
        (<i>
          Each time you login to the system, you will be required to enter this
          one-time password from the Authenticator application.
        </i>)
      </div>
    );
  };

  handleKeyPress = (e: Event) => {
    if (e.key === "Enter") {
      this.verifyTotpToken();
    }
  };
  render() {
    const theme = this.props.theme ? this.props.theme : AmplifyTheme;
    const { code, setupMessage, loading } = this.state;

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Scan then enter verification code")}
        </SectionHeader>
        <SectionBody theme={theme}>
          <p>
            Install the Authenticator application, and scan the above QR code to
            get the Security Code (one-time password).
          </p>

          <ul style={{ marginLeft: 16, listStyleType: "circle" }}>
            <li>
              Android:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              >
                Google Authenticator
              </a>
            </li>
            <li>
              IOS:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://itunes.apple.com/us/app/google-authenticator/id388497605?mt=8"
              >
                Google Authenticator
              </a>
            </li>
            <li>
              Chrome:{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en"
              >
                Google Authenticator extension
              </a>
            </li>
          </ul>

          {setupMessage &&
            <Alert
              type="error"
              message={
                <span>
                  {setupMessage}
                </span>
              }
              style={{ marginBottom: 8, marginTop: -16 }}
            />}
          {this.showSecretCode(code, theme)}
        </SectionBody>

        <SectionFooter theme={theme}>
          <COAuthButton
            loading={loading}
            onClick={this.verifyTotpToken}
            style={{ width: "100%" }}
          >
            {I18n.get("Verify Security Token")}
          </COAuthButton>
        </SectionFooter>
      </FormSection>
    );
  }
}
export default class COTOTPSetup extends TOTPSetup {
  showComponent(theme) {
    const { hide } = this.props;
    if (hide && hide.includes(COTOTPSetup)) {
      return null;
    }

    return <COTOTPSetupComp {...this.props} onTOTPEvent={this.onTOTPEvent} />;
  }
}

import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logoutAction } from "../actions/login";

type TProps = {
  doLogout: Function,
  history: {
    replace: string => void,
  },
};

class Logout extends Component<TProps, void> {
  componentDidMount() {
    this.logout();
  }

  logout = async () => {
    await this.props.doLogout();

    // Go to login page
    // this.props.history.replace("/");
    window.location = "/";
  };

  render() {
    return null;
  }
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    doLogout: () => {
      dispatch(logoutAction());
    },
  };
};

export default withRouter(connect(null, mapDispatchToProps)(Logout));

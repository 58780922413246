import React, { Component } from "react";

import CompetitorFilter from "./CompetitorFilter";

import CompetitorList from "./CompetitorList";

class CompetitorDashboard extends Component {
  render() {
    return (
      <div>
        <CompetitorFilter />
        <CompetitorList />
      </div>
    );
  }
}

export default CompetitorDashboard;

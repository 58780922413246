import { Input, message, Modal } from "antd";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { rpcCall } from "../../../../addons/RPC";
import {
  sriaAddEventReport,
  sriaUpdateEventReport,
} from "../../actions/SRIAEventDashboardAction";
import { hideEventReportForm } from "../../actions/SRIAEventReportAction";
import { useFormInput } from "../../utils/FormUtils";
import { SectionActions } from "./SelectActions";

type TProps = {
  eventId: string,
  snippet?: string,
};

export const EventThumbUpDown2 = (props: TProps) => {
  const {
    index,
    showForm,
    vote,
    snippet,
    actions,
    recommend_actions: recommendActions,
    commentText,
    ...rest
  } = useSelector(state => state.SRIAEventReport);
  const dispatch = useDispatch();
  const hideModal = () => dispatch(hideEventReportForm());

  const [selectedActions, setSelectedActions] = useState([]);
  let comment = useFormInput("");
  useEffect(
    () => {
      comment.onChange({ target: { value: commentText } });
    },
    [rest.event_id, vote]
  );

  async function submitForm() {
    if (selectedActions.length === 0 && vote === "Action") {
      message.warn("You must select at least one action");
      return;
    }

    dispatch(
      sriaAddEventReport({
        comment: comment.value,
        vote,
        ...rest,
      })
    );

    if (index !== "event_news") {
      dispatch(
        sriaUpdateEventReport({
          comment: comment.value,
          vote,
          actions: selectedActions,
          ...rest,
        })
      );
    }

    await rpcCall("EventService/sria_event_report", {
      comment: comment.value,
      snippet,
      vote,
      actions: selectedActions,
      index,
      ...rest,
    });

    message.info("Your vote has been recorded");

    // Reset comment
    comment.onChange({ target: { value: "" } });
    setSelectedActions([]);

    hideModal();
  }

  let title;
  if (vote === "Action") {
    title = "Create action";
  } else {
    title = `Report ${vote === "Up" ? "correct" : "in-correct"} event`;
  }

  return (
    <Modal
      title={title}
      visible={showForm}
      onCancel={() => hideModal()}
      onOk={submitForm}
    >
      {snippet &&
        <div>
          <b>Snippet:</b> <i>{snippet}</i>
        </div>}
      {vote === "Action" &&
        <SectionActions
          recommendActions={recommendActions}
          selectedActions={selectedActions}
          onChange={actions => setSelectedActions(actions)}
        />}
      <div style={{ marginTop: 10 }}>
        <h2 style={{ marginBottom: 0 }}>Comment:</h2>
        <Input.TextArea rows={4} {...comment} />
      </div>
    </Modal>
  );
};

// @flow
import { Descriptions, Icon, Tag, Row, Col } from "antd";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Bar,
  BarChart,
  Cell,
  Tooltip,
  XAxis,
  YAxis,
  ReferenceLine,
} from "recharts";

type TProps = {};

type TState = {};

const fields = [
  { field: "Name" },
  {
    field: "Keywords",
    title: "Entities",
    render: ({ value }) =>
      value.map(entity =>
        <Tag key={entity}>
          {entity}
        </Tag>
      ),
  },
  {
    field: "EntityExclude",
    title: "Entities Exclude",
    render: ({ value }) =>
      Object.keys(value).map(entity =>
        <span key={entity}>
          <Tag>{entity}</Tag>({value[entity].join(", ")})
        </span>
      ),
  },
  { field: "CategoryManager", title: "Category Manager" },
  // { field: "CreateUserID" },
  // { field: "CreatedAt" },
  { field: "DigitID" },
  { field: "EmailDomain", title: "Email Domain" },
  // { field: "IsActive" },
  { field: "PrimaryAE", title: "Primary AE" },
  { field: "PrimaryTPM", title: "Primary TPM" },
  { field: "RelationshipStatus", title: "Relationship Status" },
  // { field: "SRIASupplierID" },
  { field: "TierFormula", title: "Tier Formula" },
  { field: "Facebook" },
  { field: "Twitter" },
  { field: "Linkedin" },
  { field: "Instagram" },
  // { field: "UpdateUserID" },
  // { field: "UpdatedAt" },
  { field: "VPManager", title: "VP Manager" },
  {
    field: "Website",
    render: ({ value }) => {
      return value.map((url, i) =>
        <a key={i} href={url} target="_blank" rel="noopener noreferrer">
          {url}
          <br />
        </a>
      );
    },
  },
  { field: "ImportStatus", title: "Import Status" },
];

export default class SRIAAdminSupplierProfile extends Component<
  TProps,
  TState
> {
  defaultFieldRender = ({ value }) => {
    return value;
  };

  buildScoreData = () => {
    const { profile } = this.props;
    return [
      {
        name: "7 days",
        value: profile.RiskScore7Days,
      },
      {
        name: "30 days",
        value: profile.RiskScore30Days,
      },
      {
        name: "60 days",
        value: profile.RiskScore60Days,
      },
      {
        name: "90 days",
        value: profile.RiskScore90Days,
      },
    ];
  };

  buildScoreChangeData = () => {
    const { profile } = this.props;
    return [
      {
        name: "7 days",
        value: profile.RiskScoreChange7Days,
      },
      {
        name: "30 days",
        value: profile.RiskScoreChange30Days,
      },
      {
        name: "60 days",
        value: profile.RiskScoreChange60Days,
      },
      {
        name: "90 days",
        value: profile.RiskScoreChange90Days,
      },
    ];
  };

  render() {
    const { profile } = this.props;
    return (
      <div>
        <Descriptions
          title={
            <div>
              Profile{" "}
              <Link to={`/suppliers/edit/${profile.SRIASupplierID}`}>
                <Icon type="edit" />
              </Link>
            </div>
          }
          column={4}
        >
          {fields.map(({ field, title, render = this.defaultFieldRender }) =>
            <Descriptions.Item key={field} label={title || field}>
              {profile != null && profile[field]
                ? render({ value: profile[field], profile })
                : <i>---</i>}
            </Descriptions.Item>
          )}
        </Descriptions>

        <Row>
          <Col span={12} style={{ display: "flex" }}>
            <ScoreChart data={this.buildScoreData()} title="Risk Score" />
          </Col>
          <Col span={12} style={{ display: "flex" }}>
            <ScoreChart
              data={this.buildScoreChangeData()}
              title="Risk Score Change(%)"
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const colorMap = {
  "7 days": "#007EB9",
  "30 days": "#0ec254",
  "60 days": "#00a1c9",
  "90 days": "#FF9900",
};

function ScoreChart({ data, title }) {
  return (
    <div>
      <div style={{ fontWeight: "bold", fontSize: 18, textAlign: "center" }}>
        {title}
      </div>
      <BarChart
        width={600}
        height={400}
        data={data}
        margin={{
          top: 5,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        barSize={50}
      >
        <XAxis type="category" dataKey="name" width={20} />
        <YAxis type="number" />

        <Tooltip />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="value" background={{ fill: "#eee" }} minPointSize={2}>
          {data.map((entry, index) => {
            const { name } = entry;

            return <Cell key={`cell-${index}`} fill={colorMap[name]} />;
          })}
        </Bar>
      </BarChart>
    </div>
  );
}

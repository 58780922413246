// @flow
import { ConfigProvider } from "antd";

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import enUS from "antd/lib/locale-provider/en_US";
import "./sria.css";
import SRIAApp from "./SRIAApp";

ReactDOM.render(
  <Router>
    <ConfigProvider locale={enUS}>
      <SRIAApp />
    </ConfigProvider>
  </Router>,
  document.getElementById("root")
);

// @flow
import { Button, message } from "antd";
import React, { Component } from "react";
import { rpcCall } from "../../../../addons/RPC";

type TVote = "Up" | "Down" | null;
type TProps = {
  id: string,
  code: string,
  rule: string,
  sentence: string,
  url: string,
  vote: TVote,
};

type TState = {
  vote: TVote,
};

export default class EventVote extends Component<TProps, TState> {
  state = {
    vote: this.props.vote,
  };

  vote = async (vote: "Up" | "Down") => {
    this.setState({ vote });
    const { id, rule, sentence, url, code } = this.props;
    await rpcCall("EventService/sria_admin_event_vote", {
      event_id: id,
      vote: vote,
      rule,
      sentence,
      event_code: code,
      url,
    });

    message.info("Updated!");
  };

  render() {
    const { vote } = this.state;
    return (
      <span>
        <Button
          type={vote === "Up" ? "primary" : null}
          icon="like"
          title="Correct"
          onClick={() => this.vote("Up")}
        />{" "}
        <Button
          type={vote === "Down" ? "primary" : null}
          icon="dislike"
          title="In-correct event"
          onClick={() => this.vote("Down")}
        />
      </span>
    );
  }
}

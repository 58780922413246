import { Button, DatePicker, Modal, Popconfirm, Select, Spin } from "antd";
import moment from "moment";
import queryString from "query-string";
import React, { Component, useState } from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { rpcCall } from "../../../../addons/RPC";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { actionCodeMap } from "../action/ActionListView";
import EventSnippet from "../event/EventSnippet";
import DataTable from "../SRIAAdminSupplier/DataTable";

const FilterBox = styled.div`
  margin-right: 16px;
  margin-bottom: 16px;
`;

function Snippet({ record }) {
  const {
    id,
    event_code,
    actions,
    recommend_actions,
    Snippet,
    DocUrlHash,
    event: { docs },
  } = record;
  const [visible, setVisible] = useState(false);

  const doc = docs.filter(doc => doc.url_hash === DocUrlHash)[0];
  if (!doc) {
    return null;
  }
  return (
    <div>
      {Snippet} [<a onClick={() => setVisible(true)}>
        <i>detail</i>
      </a>]
      <Modal
        visible={visible}
        onCancel={() => setVisible(false)}
        width={800}
        footer={null}
      >
        <a
          href={doc.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ marginBottom: 16, display: "block" }}
        >
          {doc.url}
        </a>
        <EventSnippet
          content={doc.content}
          eventProps={{
            event_id: id,
            event_code: event_code,
            doc_url_hash: doc.url_hash,
            actions: actions,
            recommend_actions: recommend_actions,
          }}
          report={[
            {
              start: record.Start,
              end: record.End,
              comment: record.Comment,
              vote: record.Vote,
              actions: record.Actions,
            },
          ]}
        />
      </Modal>
    </div>
  );
}

class FeedbackListView extends Component {
  handleTableChange = (pagination, sorter) => {
    const extraParams = {
      ...this.state,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      page: pagination.current,
    };

    changeUrl(this.props, extraParams);
  };

  render() {
    const params = queryString.parse(this.props.location.search);
    const { filters: { suppliers, events, users } } = this.props;
    if (suppliers.length === 0) {
      return <Spin />;
    }

    return (
      <div>
        <div style={{ display: "flex" }}>
          <FilterBox>
            <FieldLabel>Start Date</FieldLabel>
            <DatePicker
              defaultValue={
                params.start_date ? moment(params.start_date) : null
              }
              onChange={value => {
                changeUrl(this.props, {
                  start_date: value ? value.format("YYYY-MM-DD") : undefined,
                });
              }}
            />
          </FilterBox>
          <FilterBox>
            <FieldLabel>End Date</FieldLabel>
            <DatePicker
              defaultValue={params.end_date ? moment(params.end_date) : null}
              onChange={value => {
                changeUrl(this.props, {
                  end_date: value ? value.format("YYYY-MM-DD") : undefined,
                });
              }}
            />
          </FilterBox>
          <FilterBox>
            <FieldLabel>Supplier</FieldLabel>
            <Select
              showSearch
              style={{ width: 200, marginBottom: 4 }}
              placeholder="Supplier"
              value={
                params.supplier_id ? parseInt(params.supplier_id, 10) : null
              }
              onChange={supplier_id =>
                changeUrl(this.props, {
                  supplier_id,
                })}
              optionLabelProp="label"
              optionFilterProp="label"
              allowClear
            >
              {suppliers.map(supplier =>
                <Select.Option
                  key={supplier.id}
                  value={supplier.id}
                  label={supplier.name}
                >
                  {supplier.name}
                </Select.Option>
              )}
            </Select>
          </FilterBox>
          <FilterBox>
            <FieldLabel>Type</FieldLabel>
            <Select
              style={{ width: 200, marginBottom: 4 }}
              placeholder="Type"
              value={params.vote}
              onChange={vote =>
                changeUrl(this.props, {
                  vote,
                })}
              allowClear
            >
              {["Up", "Down", "Action"].map(vote =>
                <Select.Option key={vote} value={vote}>
                  {vote}
                </Select.Option>
              )}
            </Select>
          </FilterBox>
          <FilterBox>
            <FieldLabel>Event</FieldLabel>
            <Select
              showSearch
              style={{ width: 200, marginBottom: 4 }}
              placeholder="Event"
              value={params.event_code}
              onChange={event_code =>
                changeUrl(this.props, {
                  event_code,
                })}
              optionLabelProp="label"
              optionFilterProp="label"
              allowClear
            >
              {events.map(event =>
                <Select.Option
                  key={event.code}
                  value={event.code}
                  label={event.name}
                >
                  {event.name}
                </Select.Option>
              )}
            </Select>
          </FilterBox>
          <FilterBox>
            <FieldLabel>Created By</FieldLabel>
            <Select
              showSearch
              style={{ width: 200, marginBottom: 4 }}
              placeholder="User"
              value={params.created_by ? parseInt(params.created_by, 10) : null}
              onChange={created_by =>
                changeUrl(this.props, {
                  created_by,
                })}
              optionLabelProp="label"
              optionFilterProp="label"
              allowClear
            >
              {users.map(user =>
                <Select.Option key={user.id} value={user.id} label={user.name}>
                  {user.name}
                </Select.Option>
              )}
            </Select>
          </FilterBox>
        </div>
        <DataTable
          {...this.props}
          rowKey={record => record.EventReportID}
          columns={this.getColumns()}
          onChange={(pagination, filters, sorter) => {
            this.handleTableChange(pagination, sorter);
          }}
        />
      </div>
    );
  }

  getColumns = () => {
    /*
    {
      "Actions": [
        "InformBusiness",
        "CaptureForContractRenewal"
      ],
      "Comment": "",
      "CreatedAt": "2019-11-01 03:22:57",
      "DocUrlHash": "534f22033984b291708f301f2c867061",
      "End": 45,
      "EventCode": "LeadershipNeg",
      "EventID": "2019-07-19T00:00:00_26_LeadershipNeg",
      "EventReportID": 1,
      "Snippet": "How SCUSD could have done things differently\n",
      "Start": 0,
      "UpdateUserID": 1002,
      "UpdatedAt": "2019-11-01 03:22:57",
      "Vote": "Action"
    },
    */
    const { data: { eventCodeMap }, filters: { users } } = this.props;
    let ret = [
      {
        title: "ID",
        dataIndex: "EventReportID",
        sorter: true,
      },
      {
        title: "Effective Date",
        dataIndex: "EffectiveDate",
        sorter: true,
        render: value =>
          <span style={{ whiteSpace: "nowrap" }}>
            {moment(value).format("ll")}
          </span>,
      },
      {
        title: "Supplier",
        dataIndex: "SupplierID",
        sorter: true,
        render: (value, record) => record.event.supplier_name,
      },
      {
        title: "Event",
        render: record => {
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {eventCodeMap[record.EventCode].Name}
            </span>
          );
        },
      },
      {
        title: "Snippet",
        render: record => <Snippet record={record} />,
      },
      {
        title: "Comment",
        dataIndex: "Comment",
      },
      {
        title: "Type",
        render: record => record.Vote,
      },
      {
        title: "Actions",
        render: record =>
          record.Actions
            ? <ul>
                {record.Actions.map(actionCode =>
                  <li key={actionCode} style={{ whiteSpace: "nowrap" }}>
                    {actionCodeMap[actionCode]}
                  </li>
                )}
              </ul>
            : "",
      },
      {
        title: "Created By",
        dataIndex: "UpdateUserID",
        sorter: true,
        render: (value, record) => {
          const user = users.filter(user => user.id === record.UpdateUserID)[0];
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {user ? user.name : `#${record.UpdateUserID}`}
            </span>
          );
        },
      },
      {
        title: "Updated At",
        dataIndex: "UpdatedAt",
        sorter: true,
        render: UpdatedAt =>
          <span style={{ whiteSpace: "nowrap" }}>
            {moment(UpdatedAt).format("ll")}
          </span>,
      },
      {
        title: "",
        render: record =>
          <Popconfirm
            title="Are you sure delete this feedback?"
            onConfirm={() => {
              this.removeFeedback(record);
            }}
            okText="Yes"
            cancelText="No"
          >
            <Button type="danger" icon="delete">
              Remove
            </Button>
          </Popconfirm>,
      },
    ];

    return ret;
  };

  removeFeedback = async record => {
    await rpcCall("EventService/sria_event_report_remove", {
      event_id: record.EventID,
      doc_url_hash: record.DocUrlHash,
      start: record.Start,
    });

    this.props.reload();
  };
}

const FieldLabel = styled.div`font-weight: bold;`;

export default withRouter(FeedbackListView);

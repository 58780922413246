import { rpcCall } from "../../../addons/RPC";
import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_UPDATE_ACTION_DASHBOARD = "SRIA_UPDATE_ACTION_DASHBOARD";
export const SRIA_UPDATE_MY_SUPPLIERS = "SRIA_UPDATE_MY_SUPPLIERS";
export const SRIA_ACTION_REMOVE = "SRIA_ACTION_REMOVE";

export function sriaUpdateActionDashboard(payload) {
  return {
    type: SRIA_UPDATE_ACTION_DASHBOARD,
    payload,
  };
}

export function sriaUpdateMySuppliers(payload) {
  return {
    type: SRIA_UPDATE_MY_SUPPLIERS,
    payload,
  };
}

export function sriaActionDashboardLoad(
  page: number,
  supplierIds: ?string,
  eventCodes: ?string,
  timePeriod: string,
  sortField: ?string,
  sortOrder: ?string,
  mySuppliers: boolean
) {
  return buildRPCThunk({
    func: "EventService/sria_actions",
    params: {
      page,
      supplier_ids: supplierIds,
      event_codes: eventCodes,
      time_period: timePeriod,
      sort_field: sortField,
      sort_order: sortOrder,
      my_suppliers: mySuppliers,
    },
    action: sriaUpdateActionDashboard,
  });
}

export function sriaLoadMySuppliers() {
  return buildRPCThunk({
    func: "EventService/sria_my_event_filter",
    params: {},
    action: sriaUpdateMySuppliers,
  });
}

export function sriaActionDashboardRemoveAction(eventId) {
  return async dispatch => {
    dispatch({
      type: SRIA_ACTION_REMOVE,
      payload: {
        eventId,
      },
    });

    rpcCall("EventService/sria_admin_event_remove", {
      event_id: eventId,
    });
  };
}

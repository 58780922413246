// @flow
export const downloadFile = (blob: Blob, filename: string) => {
  const link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename ? filename : "download";
  if (document.body) {
    document.body.appendChild(link);
    link.click();
  }
};

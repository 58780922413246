import { Form, Switch, Icon } from "antd";
import React, { Component } from "react";
import { connect, FormikProps } from "formik";

type TProps = FormikProps & {};
type TState = {};

class SRIASupplierSuppress extends Component<TProps, TState> {
  state = {};

  handleChange = (checked, e) => {
    this.setValue(checked);
  };

  getValue = () => {
    const { name, formik: { values } } = this.props;
    return values[name] || false;
  };

  setValue = value => {
    const { name, formik: { setFieldValue } } = this.props;

    setFieldValue(name, value);
  };

  render() {
    const { formik: { values }, name, labelCol, wrapperCol } = this.props;
    const value = values[name] || false;

    return (
      <Form.Item
        labelCol={labelCol}
        wrapperCol={wrapperCol}
        label="Is Suppressed"
        colon={true}
      >
        <Switch
          checkedChildren={<Icon type="check" />}
          unCheckedChildren={<Icon type="close" />}
          checked={value}
          onChange={this.handleChange}
        />
      </Form.Item>
    );
  }
}

export default connect(SRIASupplierSuppress);

import { Auth, I18n } from "aws-amplify";
import { ConfirmSignIn } from "aws-amplify-react";
import React from "react";
import {
  FormSection,
  FormField,
  SectionHeader,
  SectionBody,
  SectionFooter,
  Link,
  Input,
  InputLabel,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
} from "aws-amplify-react/dist/Amplify-UI/Amplify-UI-Components-React";

import { COAuthButton } from "./COAuthButton";

export default class COConfirmSignIn extends ConfirmSignIn {
  constructor(props) {
    super(props);

    this._validAuthStates = ["confirmSignIn"];
    this.state = {
      mfaType: "SMS",
    };
  }

  handleKeyPress = (e: Event) => {
    if (e.key === "Enter") {
      e.preventDefault();
      this.confirm(e);
    }
  };

  confirm = async event => {
    if (event) {
      event.preventDefault();
    }

    this.setState({ loading: true });

    const user = this.props.authData;
    const { code } = this.inputs;
    const mfaType =
      user.challengeName === "SOFTWARE_TOKEN_MFA" ? "SOFTWARE_TOKEN_MFA" : null;
    if (!Auth || typeof Auth.confirmSignIn !== "function") {
      throw new Error(
        "No Auth module found, please ensure @aws-amplify/auth is imported"
      );
    }

    await Auth.confirmSignIn(user, code, mfaType)
      .then(() => {
        this.checkContact(user);
      })
      .catch(err => this.error(err));

    this.setState({ loading: false });
  };

  confirmSignIn = async (e: Event) => {
    e.preventDefault();
    this.confirm(e);
  };

  showComponent(theme) {
    const { hide } = this.props;
    if (hide && hide.includes(COConfirmSignIn)) {
      return null;
    }

    const { loading } = this.state;

    return (
      <FormSection theme={theme}>
        <SectionHeader theme={theme}>
          {I18n.get("Confirm " + this.state.mfaType + " Code")}
        </SectionHeader>
        <form onSubmit={this.confirm}>
          <SectionBody theme={theme}>
            <FormField theme={theme}>
              <InputLabel theme={theme}>
                {I18n.get("Code")} *
              </InputLabel>
              <Input
                autoFocus
                placeholder={I18n.get("Code")}
                theme={theme}
                key="code"
                name="code"
                autoComplete="off"
                onChange={this.handleInputChange}
                onKeyPress={this.handleKeyPress}
              />
            </FormField>
          </SectionBody>
          <SectionFooter theme={theme}>
            <SectionFooterPrimaryContent theme={theme}>
              <COAuthButton
                onClick={this.confirmSignIn}
                disabled={loading}
                loading={loading}
              >
                {I18n.get("Confirm")}
              </COAuthButton>
            </SectionFooterPrimaryContent>
            <SectionFooterSecondaryContent theme={theme}>
              <Link theme={theme} onClick={() => this.changeState("signIn")}>
                {I18n.get("Back to Sign In")}
              </Link>
            </SectionFooterSecondaryContent>
          </SectionFooter>
        </form>
      </FormSection>
    );
  }
}

// @flow
import { Icon } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import InputField from "../../apps/ui/components/common/InputField";
import logo from "../../img/logo-main.png";
import {
  addyColorWhite,
  addyColorDanger,
  addyColorMoonGray,
  addyColorInfo,
  addyColorLightBlue,
} from "../Addy/utils";
type TProps = {
  children: React$Node,
};

export const UnAuthenticateContentAddy = (props: TProps) => (
  <Container>
    <Logo src={logo} alt="Capital One Logo" />
    <StyleCard style={{ paddingBottom: 5 }} noHovering>
      {props.children}
    </StyleCard>
  </Container>
);
const StyleCard = styled.div`
  /* box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, .3);
  background: ${addyColorWhite};
  padding: 10px 20px 15px 20px;
  border-radius: 5px; */
`;

const Container = styled.div`
  font-size: 12px;
  text-align: center;
  width: 450px;
  height: 90vh;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`;

const Logo = styled.img`
  width: 50%;
  position: relative;
  vertical-align: middle;
`;

export const Message = styled.span`
  position: relative;
  top: -8px;
  color: ${addyColorDanger};
  font-weight: 400;
`;
Message.displayName = "ErrorMessage";
const Button = styled.button`
  &:focus {
    outline: 0;
  }
  &:active {
    transform: translateY(1px);
  }
  &:disabled,
  &:disabled:hover {
    background-color: ${addyColorLightBlue};
    cursor: default;
  }
`;
export const SubmitButton = styled(Button)`
  color: ${addyColorWhite};
  text-align: center;
  background-color: ${addyColorInfo};
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 10px 16px;
  font-size: 1.25rem;
  border: 1px solid transparent;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.3);
  margin-bottom: 10px;
  &:hover {
    cursor: pointer;
    background: ${addyColorLightBlue};
  }
`;
export const Redirect = styled(Link)`
  color: ${addyColorInfo};

  &:hover {
    color: ${addyColorLightBlue};
    cursor: pointer;
  }
`;
export const Input = styled(InputField)`
  background: ${addyColorWhite};
  color: rgba(0, 0, 0, 0.65);
  border: 1px solid ${addyColorMoonGray};
  margin-bottom: 15px;
  &:hover {
    border-color: ${addyColorLightBlue};
    outline: 0;
    box-shadow: 0 0 0 2px rgba(16, 142, 233, 0.2);
  }
`;
export const LoadingIcon = styled(Icon)`
  color: ${addyColorWhite};
`;
export const InputContainer = styled.div`
  margin-top: 20px;
`;
export const Title = styled.span`
  font-size: 28px;
  text-align: center;
`;
export const Description = styled.p`
  font-size: 14px;
  text-align: justify;
  margin-bottom: 20px;
`;

// @flow
import { Button, message, Row, Col, Form } from "antd";

import { Formik } from "formik";

// import { isEmpty } from "lodash";

import React, { Component } from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { rpcCall } from "../../../../../addons/RPC";

import { FormInput, yup } from "../../Form";

import EntityExclude from "./EntityExclude";
import SRIAPrimaryTPM from "./SRIAPrimaryTPM";
import SRIASupplierRelationship from "./SRIASupplierRelationship";
import SRIASupplierSuppress from "./SRIASupplierSuppress";

type TProps = {
  supplierId: ?number,
  form: any,
};

type TState = {
  data: Object,
  loading: boolean,
};

const validationSchema = yup.object().shape({
  Name: yup.string().required("Name is required"),
  // entities: yup
  //   .array()
  //   .compact(v => isEmpty(v.trim()))
  //   .min(1, "Entities is required"),
  entities: yup.string().required("Entities is required"),
  PrimaryTPM: yup.string().required("Primary TPM is required"),
});

class SRIAAdminSupplierCreate extends Component<TProps, TState> {
  state = {
    loading: this.props.supplierId != null,
    data: {},
  };

  componentDidMount() {
    if (this.props.supplierId != null) {
      this.loadData(this.props.supplierId);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.supplierId !== nextProps.supplierId) {
      this.loadData(nextProps.supplierId);
    }
  }

  loadData = async supplierId => {
    this.setState({ loading: true });
    const data = await rpcCall("EventService/sria_admin_supplier_get", {
      supplier_id: supplierId,
    });
    this.setState({ data, loading: false });
  };

  onSubmit = async (values, actions) => {
    const params = { ...values };
    if (this.props.supplierId != null) {
      params.supplier_id = this.props.supplierId;
    }
    const result = await rpcCall(
      "EventService/sria_admin_supplier_upsert",
      params
    );

    actions.setSubmitting(false);
    if (result.error === true) {
      message.error(result.message);
    } else {
      if (this.props.supplierId != null) {
        message.info("Supplier has been updated!");
      } else {
        message.info("Supplier has been created!");
      }
      window.history.back();
    }
  };

  render() {
    const { data } = this.state;
    const { supplierId } = this.props;

    const labelCol = { span: 4 };
    const wrapperCol = { span: 20 };

    return (
      <Row>
        <Col sm={3} xxl={6} />
        <Col sm={18} xxl={12}>
          <div>
            <Form.Item
              wrapperCol={{ span: 20, offset: 4 }}
              style={{ marginBottom: 0 }}
            >
              <h1 style={{ fontWeight: "bold" }}>
                {supplierId != null ? `Update Supplier` : "Create Supplier"}
              </h1>
            </Form.Item>

            <Formik
              enableReinitialize
              initialValues={data}
              validationSchema={validationSchema}
              onSubmit={this.onSubmit}
              render={({
                submitForm,
                isSubmitting,
                handleChange,
                handleBlur,
              }) => {
                return (
                  <div>
                    <SRIASupplierRelationship
                      name="RelationshipStatus"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      id="Name"
                      name="Name"
                      label="Name"
                      colon={true}
                      required
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      inputType="string[]"
                      name="entities"
                      id="entities"
                      label="Entities"
                      help="Each entity should be in one line"
                      required
                      onBlur={handleBlur}
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
                      <EntityExclude name="entity_exclude" />
                    </Form.Item>
                    <SRIAPrimaryTPM
                      name="PrimaryTPM"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      inputType="string[]"
                      name="rss"
                      label="RSS"
                      help="Each RSS should be in one line"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      name="Crunchbase"
                      label="Crunchbase"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      name="Last10k"
                      label="Last10k"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      name="Ticker"
                      label="Ticker"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      name="ConsumerFinance"
                      label="Consumer Finance"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <FormInput
                      inputType="string[]"
                      name="websites"
                      label="Website"
                      help="Each website should be in one line"
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                    />
                    <SRIASupplierSuppress
                      labelCol={labelCol}
                      wrapperCol={wrapperCol}
                      name="IsSuppressed"
                    />
                    <Form.Item
                      wrapperCol={{ span: 20, offset: 4 }}
                      style={{ marginTop: -25 }}
                    >
                      <span style={{ color: "#000", fontWeight: "bold" }}>
                        * {" "}
                      </span>
                      <span color="black">
                        RSS, Crunchbase, Last10k, Ticker, ConsumerFinance.
                        Should ask supporter to provide the correct value.
                      </span>
                    </Form.Item>
                    <Form.Item wrapperCol={{ span: 20, offset: 4 }}>
                      <Button
                        onClick={() => window.history.back()}
                        size="large"
                      >
                        Cancel
                      </Button>{" "}
                      <Button
                        type="primary"
                        onClick={submitForm}
                        loading={isSubmitting}
                        size="large"
                      >
                        {supplierId != null ? "Update" : "Create"}
                      </Button>
                    </Form.Item>
                  </div>
                );
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const supplierId = Number(ownedProps.match.params.supplierId) || null;

  return {
    supplierId,
  };
};

export default withRouter(
  connect(mapStateToProps, {})(SRIAAdminSupplierCreate)
);

import React, { Component } from "react";
import { Button } from "antd";
import { showEventReportForm } from "../../actions/SRIAEventReportAction";
import { connect } from "react-redux";

class NonNewsEventThumbUpDown extends Component {
  state = { vote: this.props.eventProps.vote };

  showForm = ({ vote }) => {
    this.props.showEventReportForm({
      ...this.props.eventProps,
      vote,
    });
  };

  render() {
    return (
      <div
        style={{
          borderRadius: 4,
          backgroundColor: "white",
          float: "left",
          marginLeft: 20,
          marginTop: -4,
        }}
      >
        <Button
          icon="like"
          title="Correct event"
          type={this.props.eventProps.vote === "Up" ? "primary" : null}
          onClick={() => this.showForm({ vote: "Up" })}
        />{" "}
        <Button
          icon="dislike"
          title="In-correct event"
          type={this.props.eventProps.vote === "Down" ? "primary" : null}
          onClick={() => this.showForm({ vote: "Down" })}
        />{" "}
        <Button
          icon="plus"
          title="Create action"
          type="default"
          onClick={() => this.showForm({ vote: "Action" })}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  showEventReportForm,
};

export default connect(null, mapDispatchToProps)(NonNewsEventThumbUpDown);

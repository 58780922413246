import { Form, Input, InputNumber } from "antd";
import { connect, FormikProps } from "formik";
import { get } from "lodash";
import * as React from "react";
import { InputProps } from "antd/lib/input";

type Props = InputProps & {
  label?: string | boolean,
  name: string,
  layout?: object,
  inputType?: "input" | "textArea" | "number",
  defaultValue?: string | number,
  validateOnchange?: boolean,
  help?: string,
  validateStatus?: "success" | "warning" | "error" | "validating",
  colon?: boolean,
};

const _FormikInput = ({
  formik: {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    submitForm,
  },
  name,
  label,
  required,
  placeholder,
  defaultValue,
  layout = {},
  inputType = "input",
  validateOnchange = false,
  help,
  validateStatus,
  colon = false,
  wrapperCol = {},
  labelCol = {},
  ...props
}: {
  formik: FormikProps<{ [name: string]: string }>,
} & Props) => {
  let InputControl: any;
  let controlValue;
  if (inputType === "string[]") {
    const value = get(values, name, undefined) || [];
    controlValue = value.join("\n");
  } else {
    controlValue = get(values, name, undefined);
  }
  let onChange = (event: any) =>
    setFieldValue(name, event.target.value, validateOnchange);
  let onPressEnter = () => {
    setFieldValue(name, get(values, name, undefined), true);
    // wait for validate complete
    // setTimeout(() => setFieldTouched(name));
    setTimeout(() => submitForm());
  };
  const extraProps: any = {};
  if (inputType === "textArea") {
    InputControl = Input.TextArea;
    extraProps.rows = 5;
  } else if (inputType === "number") {
    InputControl = InputNumber;
    onChange = value => setFieldValue(name, value, validateOnchange);
    extraProps.onPressEnter = onPressEnter;
  } else if (inputType === "string[]") {
    InputControl = Input.TextArea;
    extraProps.rows = 5;
    onChange = e => {
      setFieldValue(name, e.target.value.split("\n"), validateOnchange);
    };
  } else {
    InputControl = Input;
    extraProps.onPressEnter = onPressEnter;
  }

  return (
    <Form.Item
      htmlFor={name}
      required={required}
      label={label}
      colon={colon}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      hasFeedback={
        !!(get(touched, name, undefined) && !get(errors, name, undefined))
      }
      validateStatus={(() => {
        if (validateStatus) {
          return validateStatus;
        } else if (get(errors, name, undefined)) {
          return "error";
        } else {
          return "success";
        }
      })()}
      help={(() => {
        if (help) {
          return help;
        }

        return get(errors, name, undefined);
      })()}
      {...layout}
    >
      <InputControl
        defaultValue={defaultValue}
        {...extraProps}
        placeholder={placeholder}
        value={controlValue}
        onChange={onChange}
        // Wait a little bit for form to bind this value before submitForm to avoid validate blink
        // onPressEnter={() => {
        //   setFieldValue(name, get(values, name, undefined), true);
        //   // wait for validate complete
        //   // setTimeout(() => setFieldTouched(name));
        //   setTimeout(() => submitForm());
        // }}
        onBlur={() => {
          setFieldValue(name, get(values, name, undefined), true);
          // wait for validate complete
          setTimeout(() => setFieldTouched(name));
        }}
        {...props}
      />
    </Form.Item>
  );
};
export default connect(_FormikInput);

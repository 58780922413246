// @flow
import { Button, message } from "antd";

import React, { Component } from "react";

import { rpcCall } from "../../../../addons/RPC";
import { connect } from "react-redux";
import { sriaUpdateSupplierIngestionStatusAction } from "../../actions/SRIAAdminSupplierAction";

type TProps = {
  supplierId: number,
  updateIngestionStatus: void,
};

type TState = {
  loading: boolean,
};

class IngestionAndDetectEventButton extends Component<TProps, TState> {
  state = {
    loading: false,
  };
  render() {
    return (
      <Button
        loading={this.state.loading}
        type="primary"
        size="small"
        icon="caret-right"
        onClick={this.ingestionAndDetectEvent}
        style={{ marginLeft: 3 }}
      >
        Detect events
      </Button>
    );
  }

  ingestionAndDetectEvent = async () => {
    this.setState({ loading: true });

    const { supplierId, updateIngestionStatus } = this.props;
    await rpcCall("EventService/sria_admin_ingestion_and_detect_event", {
      supplier_id: supplierId,
    });

    this.setState({ loading: false });
    updateIngestionStatus(supplierId);
    message.info("Pipeline has been triggered");
  };
}

const mapDispatchToProps = (dispatch: Function) => {
  return {
    updateIngestionStatus: supplierId => {
      return dispatch(sriaUpdateSupplierIngestionStatusAction({ supplierId }));
    },
  };
};

export default connect(null, mapDispatchToProps)(IngestionAndDetectEventButton);

import { SRIA_UPDATE_MY_SUPPLIERS } from "../actions/SRIAEventDashboardAction";

const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {
    items: [],
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_UPDATE_MY_SUPPLIERS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

// @flow
import { Input } from "antd";

import { connect, FormikProps } from "formik";
import React, { Component } from "react";

type TProps = FormikProps & {};

type TState = {};

class EntityExclude extends Component<TProps, TState> {
  render() {
    const { formik: { values }, name } = this.props;

    const value = values[name] || {};
    const entities = values.entities || [];

    if (entities.length > 0) {
      return (
        <div>
          <b>Entity Exclude:</b>
          {entities.map((entity, k) => {
            return this.renderItem({
              entity,
              values: value[entity] || [],
              key: k + 1,
            });
          })}
        </div>
      );
    }

    return null;
  }

  getValue = () => {
    const { name, formik: { values } } = this.props;
    return values[name] || {};
  };

  setValue = value => {
    const { name, formik: { setFieldValue } } = this.props;
    setFieldValue(name, value);
  };

  renderItem = ({ entity, values, key }) => {
    return (
      <div style={{ display: "flex", marginLeft: 30, marginTop: 5 }} key={key}>
        <div style={{ marginRight: 16, minWidth: 150, color: "#1890ff" }}>
          <b>
            {key} - {entity}
          </b>
        </div>
        <div style={{ minWidth: 300 }}>
          <Input.TextArea
            rows={4}
            value={values.join("\n")}
            onChange={e => {
              const value = e.target.value;

              const currentValue = this.getValue();
              this.setValue({
                ...currentValue,
                [entity]: value.split("\n"),
              });
            }}
            placeholder="Exclude words, separate by new line"
          />
        </div>
      </div>
    );
  };
}

export default connect(EntityExclude);

// @flow
import { Button, message } from "antd";

import React, { Component } from "react";
import { rpcCall } from "../../../../addons/RPC";

type TState = {
  loading: boolean,
};

class APIButton extends Component<
  {
    func: string,
    successMessage: string,
    children: React$Node,
  },
  TState
> {
  state = {
    loading: false,
  };
  render() {
    return (
      <Button loading={this.state.loading} onClick={this.execute}>
        {this.props.children}
      </Button>
    );
  }

  execute = async () => {
    const { func, successMessage } = this.props;
    this.setState({ loading: true });
    await rpcCall(`EventService/${func}`);
    this.setState({ loading: false });
    message.info(successMessage);
  };
}

export default class SRIAAdminTool extends Component<{}> {
  render() {
    return (
      <div>
        <APIButton
          func="sria_admin_event_detection_rerun"
          successMessage="Pipeline has been triggered. Please check later"
        >
          Delete and re-detect events
        </APIButton>

        <APIButton
          func="sria_admin_action_detection_rerun"
          successMessage="Pipeline has been triggered. Please check later"
        >
          Delete and re-detect actions
        </APIButton>
      </div>
    );
  }
}

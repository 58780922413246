export const SHOW_EVENT_REPORT_FORM = "SHOW_EVENT_REPORT_FORM";
export const HIDE_EVENT_REPORT_FORM = "HIDE_EVENT_REPORT_FORM";

export function showEventReportForm(payload) {
  return {
    type: SHOW_EVENT_REPORT_FORM,
    payload,
  };
}

export function hideEventReportForm() {
  return {
    type: HIDE_EVENT_REPORT_FORM,
  };
}

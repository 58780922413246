import { applyMiddleware, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web and AsyncStorage for react-native

import { logoutAction } from "../ui/actions/login";

import appReducers from "./reducers";

const persistConfig = {
  key: "sria",
  storage,
  whitelist: ["settings", "login", "user", "theme"],
};

const persistedReducer = persistReducer(persistConfig, appReducers);

export default ({ onReady }) => {
  let store = createStore(persistedReducer, applyMiddleware(thunk));
  let persistor = persistStore(store, null, () => {
    onReady();

    window.logout = () => {
      store.dispatch(logoutAction());
    };

    // Save dispatch to use when COTOTPSetup finish
    window.dispatch = store.dispatch;
  });
  return { store, persistor };
};

import { SRIA_UPDATE_FEEDBACK_DASHBOARD } from "../actions/SRIAFeedbackAction";

const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {
    items: [],
    pagination: {
      total: 0,
      pageSize: 0,
    },
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_UPDATE_FEEDBACK_DASHBOARD:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

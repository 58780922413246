export function normalizeIds(ids) {
  if (!ids) {
    return [];
  } else if (typeof ids === "string") {
    return [Number(ids)];
  } else {
    return ids.map(id => Number(id));
  }
}

export function normalizeItems(ids) {
  if (!ids) {
    return [];
  } else if (typeof ids === "string") {
    return [ids];
  } else {
    return ids;
  }
}

// @flow
import { Input, Row, Tooltip, message } from "antd";
import React from "react";
import { rpcCall } from "../../../../../addons/RPC";
import { rowStyle } from "../../layout/COCommon";

export const FormRow = ({ children, ...props }) =>
  <Row type="flex" align="center" style={rowStyle} {...props}>
    {children}
  </Row>;

function formatNumber(value) {
  value += "";
  const list = value.split(".");
  const prefix = list[0].charAt(0) === "-" ? "-" : "";
  let num = prefix ? list[0].slice(1) : list[0];
  let result = "";
  while (num.length > 3) {
    result = `,${num.slice(-3)}${result}`;
    num = num.slice(0, num.length - 3);
  }
  if (num) {
    result = num + result;
  }
  return `${prefix}${result}${list[1] ? `.${list[1]}` : ""}`;
}

export class NumericInput extends React.Component {
  onChange = e => {
    const { value } = e.target;
    const reg = /^-?(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if ((!isNaN(value) && reg.test(value)) || value === "") {
      this.props.onChange(value);
    }
  };
  // '.' at the end in the input box.
  onBlur = () => {
    const { value, onBlur, onChange } = this.props;
    if (typeof value === "string") {
      if (value.charAt(value.length - 1) === ".") {
        onChange({ value: value.slice(0, -1) });
      }
    }

    if (onBlur) {
      onBlur({ value });
    }
  };
  render() {
    const { value } = this.props;
    const title = value
      ? <span className="numeric-input-title">
          {value !== "-" ? formatNumber(value) : "-"}
        </span>
      : "Input a number";
    return (
      <Tooltip
        trigger={["focus"]}
        title={title}
        placement="topLeft"
        overlayClassName="numeric-input"
      >
        <Input
          {...this.props}
          onChange={this.onChange}
          onBlur={this.onBlur}
          placeholder="Input a number"
          maxLength="10"
        />
      </Tooltip>
    );
  }
}

export const requestUpdateField = async (RequestID, field, value) => {
  const output = await rpcCall("RequestService/co_request_field_update", {
    request_id: RequestID,
    field: field,
    value: value,
  });

  const messageFunc = output.ok ? message.info : message.error;
  messageFunc(output.message);

  return output.ok;
};

export const showOutputMessage = (output: { ok: boolean, message: string }) => {
  const messageFunc = output.ok ? message.info : message.error;
  messageFunc(output.message);

  return output;
};

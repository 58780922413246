// @flow
import { Spin, Tabs } from "antd";

import queryString from "query-string";

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";

import { sriaAdminSupplierProfileAction } from "../../actions/SRIAAdminSupplierAction";

import MyBreadcrumb from "./MyBreadcrumb";

import SRIAAdminSupplierNews from "./news/SRIAAdminSupplierNews";
import SRIAAdminSupplierActionTab from "./SRIAAdminSupplierActionTab";
import SRIAAdminSupplierCrawledData from "./SRIAAdminSupplierCrawledData";
import SRIAAdminSupplierEvent from "./SRIAAdminSupplierEvent";
import SRIAAdminSupplierProfile from "./SRIAAdminSupplierProfile";
import SRIAAdminSupplierCompetitor from "./SRIAAdminSupplierCompetitor";

type TProps = {
  tab: string,
  loadData: Function,
  supplierId: number,
  profile: Object,
};

type TState = {
  supplierId: number,
};

class SRIAAdminSupplierView extends Component<TProps, TState> {
  componentDidMount() {
    this.props.loadData(this.props.supplierId);
  }

  onTabChange = tabKey => {
    changeUrl(this.props, { tab: tabKey }, ["page"]);
  };

  render() {
    const { tab, supplierId, profile: { loading, data } } = this.props;
    const { Name } = data;
    const routes = [
      {
        path: "/suppliers",
        breadcrumbName: "Suppliers",
      },
      {
        path: `/suppliers/view/${supplierId}`,
        breadcrumbName: Name,
      },
    ];
    return (
      <Spin spinning={loading}>
        {Name && <MyBreadcrumb routes={routes} />}
        <Tabs defaultActiveKey={tab} onChange={this.onTabChange}>
          <Tabs.TabPane tab="Profile" key="profile">
            <SRIAAdminSupplierProfile profile={data} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="News" key="news">
            <SRIAAdminSupplierNews />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Event" key="event">
            <SRIAAdminSupplierEvent />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Action" key="action">
            <SRIAAdminSupplierActionTab />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Finance File" key="finance-file">
            <SRIAAdminSupplierCrawledData dataSource="Last10k" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Consumer Finance" key="ConsumerFinance">
            <SRIAAdminSupplierCrawledData dataSource="ConsumerFinance" />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Competitor" key="Competior">
            <SRIAAdminSupplierCompetitor />
          </Tabs.TabPane>
        </Tabs>
      </Spin>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const { tab = "profile" } = queryString.parse(ownedProps.location.search);
  return {
    supplierId: Number(ownedProps.match.params.supplierId),
    tab,
    profile: state.AdminSupplierProfile,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: supplierId =>
      dispatch(sriaAdminSupplierProfileAction(supplierId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplierView)
);

import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sriaAdminSupplierCompetitorsAction } from "../../actions/SRIAAdminSupplierAction";
import CompetitorListView from "../competitor/CompetitorListView";

type TProps = {
  loadData: Function,
  supplierId: number,
  page: number,
};

type TState = {
  loading: boolean,
};

class SRIAAdminSupplierCompetitor extends Component<TProps, TState> {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.props.loadData(this.props.supplierId, this.props.page);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.supplierId !== this.props.supplierId ||
      nextProps.page !== this.props.page
    ) {
      nextProps.loadData(nextProps.supplierId, nextProps.page);
    }
  }

  render() {
    return (
      <div>
        <CompetitorListView showSupplier={false} {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const { page = 1 } = queryString.parse(ownedProps.location.search);
  return {
    supplierId: Number(ownedProps.match.params.supplierId),
    page: Number(page),
    ...state.SupplierCompetitors,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (supplierId, page) =>
      dispatch(sriaAdminSupplierCompetitorsAction({ supplierId, page })),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplierCompetitor)
);

// @flow
import { Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import React, { Component } from "react";
import { Provider } from "react-redux";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { isLocalEnv } from "../../addons/LambdaUtils";
import { handleSignInPayload } from "../co/COSignInUtils";

import AmplifyConfig from "../int/AmplifyConfig";

import WithAmplifyAuthenticator from "../int/WithAmplifyAuthenticator";
import AmplifyTheme from "../ui/AmplifyTheme";
import Logout from "../ui/components/Logout";
import SRIAConfigureStore from "./SRIAConfigureStore";

import SRIARoute from "./SRIARoute";

type TProps = {};

type TState = {
  store: ?Object,
  persistor: ?Object,
};

class App extends Component<any> {
  render() {
    const { store, persistor } = this.props;
    const { login } = store.getState();

    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <Switch>
              <Route exact path={`/logout`} component={Logout} />
            </Switch>
          </Router>
          {login.ok && <SRIARoute />}
        </PersistGate>
      </Provider>
    );
  }
}

const AuthenticatedApp = WithAmplifyAuthenticator(
  App,
  false,
  [],
  null,
  AmplifyTheme
);

export default class AmplifyApp extends Component<TProps, TState> {
  state = {
    store: null,
    persistor: null,
    signedIn: false,
    signInFinished: false,
  };

  newState: TState;

  // Default handler for listening events
  onHubCapsule = async (capsule: any) => {
    switch (capsule.payload.event) {
      case "signIn":
        await handleSignInPayload(
          capsule.payload.data,
          this.state.store.dispatch
        );
        this.setState({ signInFinished: true });

        break;
      default:
        break;
    }
  };

  componentDidMount() {
    this.newState = SRIAConfigureStore({
      onReady: this.onReady,
    });
  }

  onReady = async () => {
    await AmplifyConfig("AddyUI");

    Hub.listen("auth", this);

    if (isLocalEnv()) {
      // if (!Cookies.get("session")) {
      //   await Auth.signOut();
      // }
    }

    // Now, it is ready to render
    this.setState(this.newState);
  };

  render() {
    const { store, persistor } = this.state;

    if (!store || !persistor) {
      return null;
    }

    return <AuthenticatedApp {...this.state} />;
  }
}

// @flow
export const UPDATE_USER = "UPDATE_USER";
export const CLEAR_USER = "CLEAR_USER";

export function updateUser(payload: Object) {
  return {
    type: UPDATE_USER,
    payload,
  };
}

export function clearUser() {
  return {
    type: CLEAR_USER,
  };
}

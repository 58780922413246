// @flow
import { API } from "aws-amplify";

export function isLocalEnv() {
  return (
    window.currentEnv === "local" ||
    (window.currentEnv == null && window.location.hostname === "localhost")
  );
}

export function callAPIGatewayLambda(
  apiGatewayName: string,
  lambdaName: string,
  params: Object = {}
) {
  if (isLocalEnv()) {
    return callLocalFunc(lambdaName, params);
  }

  const init = {
    body: params,
  };
  return API.post(apiGatewayName, `/default/${lambdaName}`, init);
}

export async function callLocalFunc(func: string, params: Object = {}) {
  let fetchOptions: Object = { credentials: "include", method: "POST" };

  if (params) {
    fetchOptions.headers = {
      // Add custom header will make the prelight request (= send an option request to server)
      // Accept: "application/json",
      // "Content-Type": "application/json",
      // "Content-Type": "multipart/form-data",
    };
    const formData = new FormData();
    formData.append("__formData", JSON.stringify(params));
    fetchOptions.body = formData;
  }

  const path = window.location.port === "3002" ? "func-ui" : "func-int";

  const res = await fetch(`/${path}/${func}`, fetchOptions);

  if (!res.ok) {
    const data = await res.json();

    const e = new Error(data.message);
    // $FlowFixMe
    e.Code = data.Code;

    throw e;
  } else {
    return await res.json();
  }
}

export async function buildLambdaAPIUrl(apiName: string) {
  let apiHost = window.apiHost;
  if (!apiHost) {
    throw new Error("API Host is not provide.");
  }

  if (apiHost.endsWith("/")) {
    apiHost = apiHost.substring(0, apiHost.length - 1);
  }

  let apiNameTmp = apiName;
  if (apiNameTmp.startsWith("/")) {
    apiNameTmp = apiNameTmp.substring(1);
  }
  if (isLocalEnv()) {
    const path = window.location.port === "3002" ? "func-ui" : "func-int";
    return `${apiHost}/${path}/${apiNameTmp}`;
  }
  const endpoint = await API.endpoint(apiNameTmp);
  return `${endpoint}/default/${apiNameTmp}`;
}

import { SRIA_ADMIN_STORE_SUPPLIER_CRAWLED_DATA } from "../actions/SRIAAdminSupplierAction";
const initState = {
  ok: true,
  loading: true,
  message: null,
  Last10k: {
    items: [],
    pagination: {},
  },
  ConsumerFinance: {
    items: [],
    pagination: {},
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_ADMIN_STORE_SUPPLIER_CRAWLED_DATA:
      return {
        ...state,
        ...action.payload.data,
        ok: action.payload.ok,
        loading: action.payload.loading,
      };

    default:
      return state;
  }
};

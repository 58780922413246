import { Row, Col } from "antd";
import React from "react";
import moment from "moment";
import styled from "styled-components";
import { NonNewsEventInfo } from "./NonNewsEventInfo";

const EventLast10kView = ({ record }) => {
  return (
    <div>
      <div style={{ marginLeft: 60, marginBottom: 10 }}>
        <NonNewsEventInfo record={record} />
      </div>

      <ul style={{ marginLeft: 16, listStyleType: "circle" }}>
        {record.docs.map((doc, j) => {
          const content = JSON.parse(doc.content);
          return (
            <li key={j} style={{ paddingBottom: 16 }}>
              <div>
                <span>
                  <b>{doc.title}</b> - {moment(doc.published_date).format("ll")}
                </span>
              </div>
              <div style={{ marginTop: 15, marginBottom: 5 }}>
                <a href={doc.url} target="_blank" rel="noopener noreferrer">
                  Sentiment Data:
                </a>
              </div>

              <div>
                <Row>
                  <Col span={6}>
                    <Sentiment
                      value={Math.round(Number(content.Product) * 100)}
                      text="Product"
                    />
                  </Col>

                  <Col span={6}>
                    <Sentiment
                      value={Math.round(Number(content.Financial) * 100)}
                      text="Financial"
                    />
                  </Col>

                  <Col span={6}>
                    <Sentiment
                      value={Math.round(Number(content.Revenue) * 100)}
                      text="Revenue"
                    />
                  </Col>
                </Row>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const Sentiment = ({ value, text }) => {
  return (
    <SentimentDiv>
      <PosDiv
        style={{
          width: `${value}%`,
        }}
      >
        <TextDiv>
          {text}: {value} / 100
        </TextDiv>
      </PosDiv>
      <NegDiv
        style={{
          width: `${100 - value}%`,
        }}
      />
    </SentimentDiv>
  );
};

const SentimentDiv = styled.div`
  height: 20px;
  width: 66%;
  display: inline-flex;
`;
const PosDiv = styled.div`
  background-color: #5cb85c;
  float: left;
  text-align: center;
`;

const TextDiv = styled.span`
  position: absolute;
  display: block;
  width: 66%;
`;

const NegDiv = styled.div`
  background-color: #ff5454;
  float: left;
`;

export default EventLast10kView;

import { Checkbox, Input, message } from "antd";
import { uniq } from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { useFormInput } from "../../utils/FormUtils";
import { actionCodeMap } from "../action/ActionListView";
const allActionCodes = Object.keys(actionCodeMap);
const SectionActionsContainer = styled.div`
  .ant-checkbox-group-item {
    display: block;
  }
`;

function actionCodeToOption(actionCode) {
  if (actionCodeMap[actionCode]) {
    return {
      label: actionCodeMap[actionCode],
      value: actionCode,
    };
  }

  return {
    label: actionCode,
    value: actionCode,
  };
}

const MyCheckBox = ({ label, value }) =>
  <Checkbox value={value}>
    {actionCodeMap[value]
      ? label
      : <i>
          {label}
        </i>}
  </Checkbox>;

export const SectionActions = (props: {
  recommendActions: string[],
  selectedActions: string[],
  onChange: (actions: string[]) => void,
}) => {
  const {
    recommendActions,
    selectedActions: initSelectedActions = [],
    onChange,
  } = props;
  const initOtherActions = [
    ...allActionCodes,
    ...initSelectedActions.filter(actionCode => !actionCodeMap[actionCode]),
  ];

  const [otherActions, setOtherActions] = useState(initOtherActions);

  const [selectedActions, setSelectedActions] = useState(initSelectedActions);

  const updateSelectedActions = actions => {
    setSelectedActions(actions);

    if (onChange) {
      onChange(actions);
    }
  };
  const add = useFormInput("");

  const handleChangeExtraAction = e => {
    const value = e.target.value.trim();
    if (otherActions.includes(value)) {
      message.warn(`Action '${value}' already exists`);
      return;
    }

    if (value === "") {
      return;
    }

    setOtherActions([...otherActions, value]);
    updateSelectedActions([...selectedActions, value]);

    add.onChange({ target: { value: "" } });
  };

  return (
    <SectionActionsContainer>
      <Checkbox.Group
        value={otherActions.filter(actionCode =>
          selectedActions.includes(actionCode)
        )}
        onChange={values => {
          updateSelectedActions(values);
        }}
        style={{ marginBottom: 5 }}
      >
        {recommendActions &&
          recommendActions.length > 0 &&
          <div>
            <h2>
              Recommended actions (<a
                onClick={() => {
                  const nextSelectedActions = uniq([
                    ...selectedActions,
                    ...recommendActions,
                  ]);

                  updateSelectedActions(nextSelectedActions);
                }}
              >
                select all
              </a>)
            </h2>
          </div>}

        {recommendActions &&
          recommendActions.map(actionCode => {
            const { value, label } = actionCodeToOption(actionCode);
            return (
              <div key={value}>
                <MyCheckBox value={value} label={label} />
              </div>
            );
          })}
        <h2 style={{ marginBottom: 0 }}>Other actions</h2>
        {otherActions
          .filter(actionCode => !recommendActions.includes(actionCode))
          .map(actionCode => {
            const { value, label } = actionCodeToOption(actionCode);
            return (
              <div key={value}>
                <MyCheckBox value={value} label={label} />
              </div>
            );
          })}
      </Checkbox.Group>
      <Input
        type="text"
        placeholder="Extra action...."
        {...add}
        onPressEnter={handleChangeExtraAction}
        onBlur={handleChangeExtraAction}
      />
    </SectionActionsContainer>
  );
};

import { useState } from "react";

export function useFormInput(initialValue) {
  const [value, setValue] = useState(initialValue);

  function onChange(e) {
    return setValue(e.target.value);
  }

  return {
    value,
    onChange,
  };
}

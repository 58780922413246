import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { sriaCompetitorDashboardLoad } from "../../actions/SRIACompetitorDashboardAction";
import CompetitorListView from "./CompetitorListView";
import { normalizeItems } from "../event/EventUtils";

class CompetitorList extends Component {
  componentWillMount() {
    this.props.loadData(
      this.props.page,
      this.props.supplierIds,
      this.props.mySuppliers,
      this.props.competitorNames
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      !isEqual(nextProps.supplierIds, this.props.supplierIds) ||
      !isEqual(nextProps.mySuppliers, this.props.mySuppliers) ||
      !isEqual(nextProps.competitorNames, this.props.competitorNames)
    ) {
      nextProps.loadData(
        nextProps.page,
        nextProps.supplierIds,
        nextProps.mySuppliers,
        nextProps.competitorNames
      );
    }
  }

  render() {
    return <CompetitorListView showSupplier={true} {...this.props} />;
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    page = 1,
    supplier_ids,
    competitor_names,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);
  return {
    ...state.CompetitorDashboard,
    page,
    supplierIds: supplier_ids,
    competitorNames: normalizeItems(competitor_names),
    mySuppliers: mySuppliers === "1",
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (page, supplierIds, mySuppliers, competitorNames) => {
      return dispatch(
        sriaCompetitorDashboardLoad(
          page,
          supplierIds,
          mySuppliers,
          competitorNames
        )
      );
    },
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CompetitorList)
);

import { SRIA_ADMIN_STORE_SUPPLIER_ACTIONS } from "../actions/SRIAAdminSupplierAction";
const initState = {
  ok: true,
  loading: true,
  message: null,
  data: {
    items: [],
    pagination: {},
  },
};

export default (state = initState, action) => {
  switch (action.type) {
    case SRIA_ADMIN_STORE_SUPPLIER_ACTIONS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

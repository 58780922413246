// @flow
import React from "react";
import { Layout } from "antd";
import { addyColorGray } from "../Addy/utils";
const { Footer, Content } = Layout;

type TProps = {
  children: React$Node,
};
const UnAuthenticatedLayout = (props: TProps) =>
  <Layout style={{ minHeight: "100vh" }}>
    <Layout>
      <Content style={{ backgroundColor: addyColorGray, minHeight: 600 }}>
        {props.children}
      </Content>
      <Footer style={{ textAlign: "center", backgroundColor: "white" }}>
        © 2017 Copyright TenPoint7. All rights reserved
      </Footer>
    </Layout>
  </Layout>;

export default UnAuthenticatedLayout;

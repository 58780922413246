// @flow
import { combineReducers } from "redux";
import login from "../../ui/reducers/login";
import user from "../../ui/reducers/user";
import ActionDashboardReducer from "./ActionDashboardReducer";
import CompetitorDashboardReducer from "./CompetitorDashboardReducer";
import EventDashboardReducer from "./EventDashboardReducer";
import FeedbackReducer from "./FeedbackReducer";
import MySuppliersReducer from "./MySuppliersReducer";
import SRIAAdminSupplierActionsReducer from "./SRIAAdminSupplierActionsReducer";
import SRIAAdminSupplierCompetitorsReducer from "./SRIAAdminSupplierCompetitorsReducer";
import SRIAAdminSupplierCrawledDataReducer from "./SRIAAdminSupplierCrawledDataReducer";
import SRIAAdminSupplierEventsReducer from "./SRIAAdminSupplierEventsReducer";
import SRIAAdminSupplierNewsReducer from "./SRIAAdminSupplierNewsReducer";
import SRIAAdminSupplierProfileReducer from "./SRIAAdminSupplierProfileReducer";
import SRIAAdminSupplierReducer from "./SRIAAdminSupplierReducer";
import SRIAEventReportReducer from "./SRIAEventReportReducer";
import SRIAUserProfileReducer from "./SRIAUserProfileReducer";
import CompetitorFilterReducer from "./CompetitorFilterReducer";

const appReducers = combineReducers({
  login,
  user,
  EventDashboard: EventDashboardReducer,
  ActionDashboard: ActionDashboardReducer,
  MySuppliers: MySuppliersReducer,
  UserProfile: SRIAUserProfileReducer,
  AdminSuppliers: SRIAAdminSupplierReducer,
  AdminSupplierProfile: SRIAAdminSupplierProfileReducer,
  SupplierNews: SRIAAdminSupplierNewsReducer,
  SupplierEvents: SRIAAdminSupplierEventsReducer,
  SupplierActions: SRIAAdminSupplierActionsReducer,
  SupplierCrawledData: SRIAAdminSupplierCrawledDataReducer,
  SupplierCompetitors: SRIAAdminSupplierCompetitorsReducer,
  CompetitorDashboard: CompetitorDashboardReducer,
  SRIAEventReport: SRIAEventReportReducer,
  Feedback: FeedbackReducer,
  CompetitorFilter: CompetitorFilterReducer,
});

export default appReducers;

import { Select, Row, Col } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SwitchRouteParams, {
  changeUrl,
} from "../../../co/modules/layout/SwitchRouteParams";
import { sriaLoadMySuppliers } from "../../actions/SRIAEventDashboardAction";
import { normalizeIds, normalizeItems } from "../event/EventUtils";

const { Option } = Select;

type TProps = {
  showSupplier: boolean,
  showEvent: boolean,
};

class ActionFilter extends Component<TProps> {
  state = {
    value: null,
    selectedSupplierIds: null,
  };

  componentDidMount() {
    this.props.loadData();
  }

  handleSupplierChange = changes => {
    changeUrl(this.props, { supplier_ids: changes, page: 1 });
  };

  handleEventChange = changes => {
    changeUrl(this.props, { event_codes: changes, page: 1 });
  };

  handleTimePeriodChange = period => {
    changeUrl(this.props, { time_period: period, page: 1 });
  };

  render() {
    let {
      supplierIds,
      eventCodes,
      data: { suppliers = [], events },
      loading,
      showSupplier,
      showEvent,
    } = this.props;

    let items = 1;
    if (showSupplier) {
      items += 1;
    }

    if (showEvent) {
      items += 2;
    }

    const colSpan = 24 / items;

    const supplierList = suppliers.filter(
      supplier => supplier.suppressed !== true
    );

    return (
      <div>
        <Row gutter={8}>
          <Col span={colSpan}>
            {" "}{!loading &&
              showSupplier &&
              <Select
                mode="multiple"
                style={{ width: "100%", marginBottom: 4 }}
                placeholder="Filter by supplier..."
                value={supplierIds}
                onChange={this.handleSupplierChange}
                optionLabelProp="label"
                optionFilterProp="label"
                allowClear
                ref={select => (this.supplierSelect = select)}
              >
                {supplierList.map(supplier =>
                  <Select.Option
                    key={supplier.id}
                    value={supplier.id}
                    label={supplier.name}
                  >
                    {supplier.name}
                  </Select.Option>
                )}
              </Select>}
          </Col>

          <Col span={colSpan}>
            {!loading &&
              showEvent &&
              <Select
                mode="multiple"
                style={{ width: "100%", marginBottom: 5 }}
                placeholder="Filter by events..."
                value={eventCodes}
                onChange={this.handleEventChange}
                optionLabelProp="label"
                optionFilterProp="label"
                allowClear
                ref={select => (this.eventSelect = select)}
              >
                {events.map(event =>
                  <Select.Option
                    key={event.code}
                    value={event.code}
                    label={event.name}
                  >
                    {event.name}
                  </Select.Option>
                )}
              </Select>}
          </Col>

          {showEvent &&
            <Col span={colSpan}>
              <Select
                defaultValue={this.props.timePeriod}
                placeholder="Select time period"
                style={{ width: "100%" }}
                onChange={this.handleTimePeriodChange}
              >
                <Option value="all">All</Option>
                <Option value="today">Today</Option>
                <Option value="week">Last Week</Option>
                <Option value="month">Last Month</Option>
                <Option value="year">Last Year</Option>
              </Select>
            </Col>}
          <Col span={colSpan}>
            <SwitchRouteParams
              field="my_suppliers"
              title="My Suppliers"
              defaultChecked={true}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    supplier_ids: supplierIds,
    event_codes: eventCodes,
    time_period: timePeriod,
  } = queryString.parse(ownedProps.location.search);

  return {
    ...state.MySuppliers,
    supplierIds: normalizeIds(supplierIds),
    eventCodes: normalizeItems(eventCodes),
    timePeriod: timePeriod || "week",
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: () => {
      return dispatch(sriaLoadMySuppliers());
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ActionFilter)
);

import { rpcCall } from "../../../addons/RPC";
import { buildRPCThunk } from "../../co/actions/COActionUtils";
export const SRIA_STORE_USER_PROFILE = "SRIA_STORE_USER_PROFILE";

function _sriaStoreUserProfile(payload) {
  return {
    type: SRIA_STORE_USER_PROFILE,
    payload,
  };
}

export function sriaUserProfileLoadAction() {
  return buildRPCThunk({
    func: "EventService/sria_user_profile",
    params: {},
    action: _sriaStoreUserProfile,
  });
}

export function sriaUserProfileUpdateAction(field, value) {
  return async (dispatch, getState) => {
    const state = getState();
    const data = state.UserProfile.data;
    dispatch(
      _sriaStoreUserProfile({
        data: {
          ...data,
          [field]: value,
        },
      })
    );

    await rpcCall("EventService/sria_user_profile_update", { field, value });
  };
}

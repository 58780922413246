import Amplify, { Auth } from "aws-amplify";
import { pick } from "lodash";
import { callAPI } from "../../addons/APIUtils2";

export default async (apiName: string) => {
  let amplifyConfig;
  if (window.location.hostname === "localhost") {
    // Run in local machine => Get config from local api
    const config = await callAPI("sr_api_dev_get_config", {
      currentEnv: window.currentEnv || "local",
      apiName,
    });

    // console.log("config", config);
    amplifyConfig = {
      Auth: pick(config, "region", "userPoolId", "userPoolWebClientId"),
      API: {
        endpoints: [
          {
            ...config.api,
            custom_header: async () => {
              const token = (await Auth.currentSession()).idToken.jwtToken;
              return {
                Authorization: token,
              };
            },
          },
        ],
      },
    };
  } else {
    // Run in cloudfront
    const apiId = window.APIID;
    amplifyConfig = {
      Auth: {
        region: "us-west2",
        userPoolId: window.CognitoUserPoolID,
        userPoolWebClientId: window.CognitoClientID,
      },
      // TODO Fix this
      API: {
        endpoints: [
          {
            name: apiName,
            endpoint: `https://${apiId}.execute-api.us-west-2.amazonaws.com`,
            custom_header: async () => {
              return {
                Authorization: (await Auth.currentSession()).idToken.jwtToken,
              };
            },
          },
        ],
      },
    };
  }

  Amplify.configure(amplifyConfig);
};

// @flow
import { Button, Col, Row, Tag } from "antd";
import { isEqual } from "lodash";
import queryString from "query-string";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import { buildUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { sriaAdminSuppliersLoadAction } from "../../actions/SRIAAdminSupplierAction";
import EventFilter from "../event/EventFilter";
import { DataTableConfigable } from "./DataTable";
import DownloadFileButton from "./DownloadFileButton";
import IngestionAndDetectEventButton from "./IngestionAndDetectEventButton";
import SRIAAdminSupplierUpload from "./SRIAAdminSupplierUpload";
import SRIASupplierExport from "./SRIASupplierExport";
import SRIAAdminScoreFilter from "./SRIAAdminScoreFilter";
import SupplierDeleteButton from "./SupplierDeleteButton";

type TProps = {
  loadData: Function,
  page: number,
  supplierIds: ?string,
  isSystemAdmin: boolean,
};

type TState = {};

class SRIAAdminSupplier extends Component<TProps, TState> {
  state = { filterDropdownVisible: true };
  componentDidMount() {
    this.props.loadData(
      this.props.page,
      this.props.supplierIds,
      this.props.mySuppliers
    );
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.page !== this.props.page ||
      !isEqual(nextProps.supplierIds, this.props.supplierIds) ||
      !isEqual(nextProps.mySuppliers, this.props.mySuppliers)
    ) {
      nextProps.loadData(
        nextProps.page,
        nextProps.supplierIds,
        nextProps.mySuppliers
      );
    }
  }

  handleTableChange = pagination => {
    const { current } = pagination;
    this.props.history.push(buildUrl(this.props, { page: current }));
  };

  render() {
    const { isSystemAdmin } = this.props;

    return (
      <DataTableConfigable
        name="SupplierList"
        {...this.props}
        columns={this.getAllColumns()}
        defaultColumns={["ID", "Name", "Website", "Entities", "Operations"]}
        rowKey="SRIASupplierID"
        render={({ configButton, table }) => (
          <div>
            <Row
              type="flex"
              justify="space-between"
              style={{ marginBottom: 5 }}
            >
              <Col>
                {isSystemAdmin && (
                  <Link to="/suppliers/new">
                    <Button icon="plus" type="primary">
                      New Supplier
                    </Button>
                  </Link>
                )}
              </Col>
              <Col>
                <ButtonList>
                  {isSystemAdmin && <SRIAAdminSupplierUpload />}
                  {isSystemAdmin && <SRIASupplierExport />}
                  <DownloadFileButton func="sria_admin_event_export">
                    Events
                  </DownloadFileButton>
                  {configButton}
                </ButtonList>
              </Col>
            </Row>

            <EventFilter showSupplier={true} showSuppressedSupplier={true} />

            <div style={{ marginTop: 10 }}>{table}</div>
          </div>
        )}
      />
    );
  }

  onChangeScoreFilter = () => {};

  getScoreRender = record => {
    const { score_interal: scoreInterval = 7 } = queryString.parse(
      this.props.location.search
    );

    const scoreField = `RiskScore${scoreInterval}Days`;

    return record[scoreField] ? record[scoreField] : <i>---</i>;
  };

  getScoreChangeRender = record => {
    const { score_interal: scoreInterval = 7 } = queryString.parse(
      this.props.location.search
    );

    const scoreChangeField = `RiskScoreChange${scoreInterval}Days`;
    const scoreChange = record[scoreChangeField];
    if (scoreChange) {
      let color = "#1890ff";

      if (scoreChange > 19) {
        color = "#DD3F5B";
      } else if (scoreChange >= -19 && scoreChange <= 19) {
        color = "#f19f09";
      }

      return <span style={{ color: color }}>{record[scoreChangeField]}</span>;
    }

    return <i>---</i>;
  };

  getAllColumns = () => {
    return [
      {
        title: "ID",
        dataIndex: "SRIASupplierID",
        key: "SRIASupplierID",
      },
      {
        title: "Name",
        render: record => (
          <Link to={`/suppliers/view/${record.SRIASupplierID}`}>
            {record.Name}
          </Link>
        ),
      },
      {
        title: "Risk Score",
        align: "center",
        filterDropdown: ({
          setSelectedKeys,
          selectedKeys,
          confirm,
          clearFilters,
        }) => (
          <SRIAAdminScoreFilter
            options={[7, 30, 60, 90]}
            defaultOption={7}
            confirm={confirm}
          />
        ),

        render: this.getScoreRender,
      },

      {
        title: "Risk Score Change(%)",
        align: "center",
        render: this.getScoreChangeRender,
      },

      {
        title: "Website",
        render: record => {
          if (record.Website) {
            return record.Website.map((url, i) => (
              <a key={i} href={url} target="_blank" rel="noopener noreferrer">
                {url}
                <br />
              </a>
            ));
          }

          return <i>N/A</i>;
        },
      },
      {
        title: "Entities",
        render: record => {
          if (record.Keywords) {
            return record.Keywords.map(keyword => (
              <Tag key={keyword}>{keyword}</Tag>
            ));
          }

          return <i>N/A</i>;
        },
      },
      {
        title: "Crunchbase",
        render: record => {
          return <span>{record.Crunchbase}</span>;
        },
      },
      {
        title: "Last10k",
        render: record => {
          return (
            <a
              key={record.Last10k}
              href={record.Last10k}
              target="_blank"
              rel="noopener noreferrer"
            >
              {record.Last10k}
            </a>
          );
        },
      },
      {
        title: "Consumer Finance",
        render: record => {
          return <span>{record.ConsumerFinance}</span>;
        },
      },
      {
        title: "Ticker",
        render: record => {
          return <span>{record.Ticker}</span>;
        },
      },
      {
        title: "Import Status",
        render: record => {
          return <Tag>{record.ImportStatus}</Tag>;
        },
      },

      {
        title: "Relationship Status",
        render: record => {
          if (record.IsFuture) {
            return <Tag color="blue">Future</Tag>;
          }
          return null;
        },
      },

      {
        title: "Operations",
        render: record => {
          return (
            <span>
              <Link
                to={`/suppliers/edit/${record.SRIASupplierID}`}
                style={{ marginRight: 3 }}
              >
                Edit
              </Link>
              {" | "}
              <Link
                to={`/events?supplier_ids=${record.SRIASupplierID}`}
                style={{ marginRight: 3, marginLeft: 3 }}
              >
                Events
              </Link>
              {" | "}

              <IngestionAndDetectEventButton
                supplierId={record.SRIASupplierID}
              />

              {record.IsFuture && (
                <SupplierDeleteButton supplierId={record.SRIASupplierID} />
              )}
            </span>
          );
        },
      },
    ];
  };
}

const ButtonList = styled.div`
  .ant-btn {
    margin-left: 10px;
  }
`;

const mapStateToProps = (state: Object, ownedProps: Object) => {
  const {
    page = 1,
    supplier_ids: querySupplierIds,
    my_suppliers: mySuppliers = "1",
  } = queryString.parse(ownedProps.location.search);

  let supplierIds;
  if (typeof querySupplierIds === "string") {
    supplierIds = [querySupplierIds];
  } else {
    supplierIds = querySupplierIds;
  }

  return {
    ...state.AdminSuppliers,
    page,
    supplierIds,
    mySuppliers: mySuppliers === "1",
    isSystemAdmin: JSON.parse(state.user.isSystemAdmin),
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: (page, supplierIds, mySuppliers) => {
      return dispatch(
        sriaAdminSuppliersLoadAction(page, supplierIds, mySuppliers)
      );
    },
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SRIAAdminSupplier)
);

// @flow
import { Icon, message, Popconfirm } from "antd";

import React, { Component } from "react";
import { rpcCall } from "../../../../../addons/RPC";

type TProps = { doc_id: string, onDelete: Function };

type TState = {};

export default class DocDelete extends Component<TProps, TState> {
  render() {
    return (
      <Popconfirm
        title="Are you sure want to delete this document? This can't be undone!"
        onConfirm={this.delete}
        okText="Yes"
        cancelText="No"
      >
        <Icon type="delete" />
      </Popconfirm>
    );
  }

  delete = async () => {
    await rpcCall("EventService/sria_admin_supplier_news_delete", {
      doc_id: this.props.doc_id,
    });

    // Need to wait as elasticsearch does not delete immediately
    setTimeout(() => {
      message.info("Deleted!");

      this.props.onDelete();
    }, 1000);
  };
}

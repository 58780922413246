// @flow
import React, { Component } from "react";
import styled from "styled-components";

type TInputField = {
  value: string,
  updateValue: Function,
};

export default class InputField extends Component<
  TInputField,
  {
    value: string,
  }
> {
  state: {
    value: string,
  } = { value: "" };

  constructor(props: TInputField) {
    super(props);

    this.state = this.buildState(props);
  }

  buildState = (props: TInputField) => {
    return {
      value: props.value,
    };
  };

  render() {
    return <InputText {...this.props} onChange={this.updateValue} />;
  }

  updateValue = (e: DOMEvent) => {
    this.setState({ value: e.target.value });
    this.props.updateValue(e.target.value);
  };
}

const InputText = styled.input`
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  color: #fff;
  border: none;
  border-radius: 4px;
  box-shadow: none;
  height: 46px;
  padding: 10px 16px;
  line-height: 1.3333333;

  &:focus {
    box-shadow: none;
    outline: 0;
  }
`;

import { Button } from "antd";
import styled from "styled-components";

export const COAuthButton = styled(Button)`
  &:hover {
    background-color: #ffac31 !important;
  }

  min-width: 153px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143 !important;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  color: #fff !important;
  background-color: #ff9c00 !important;
  border-color: #ccc;
  text-transform: uppercase;
  padding: 14px 0 !important;
  letter-spacing: 1.1px;
  border: none !important;
  border-radius: 0 !important;
  height: auto !important;
  float: right;
`;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import DataTable from "../SRIAAdminSupplier/DataTable";
import { Tag } from "antd";
import { Link } from "react-router-dom";

class CompetitorListView extends Component {
  handleTableChange = pagination => {
    const extraParams = {
      ...this.state,
      page: pagination.current,
    };

    changeUrl(this.props, extraParams);
  };

  render = () => {
    return (
      <DataTable
        {...this.props}
        rowKey={record => record.SupplierCompetitorID}
        columns={this.getColumns()}
        onChange={(pagination, filters, sorter) => {
          this.handleTableChange(pagination);
        }}
      />
    );
  };

  getColumns = () => {
    const { showSupplier = true } = this.props;
    let ret = [
      {
        title: "ID",
        key: "SupplierCompetitorID",
        dataIndex: "SupplierCompetitorID",
      },
    ];

    if (showSupplier) {
      ret.push({
        title: "Supplier Name",
        key: "SupplierName",
        dataIndex: "SupplierName",
      });
    }

    ret = [
      ...ret,
      {
        title: "Name",
        render: record => {
          if (record.RefSRIASupplierID) {
            return (
              <Link
                to={`/suppliers/view/${record.RefSRIASupplierID}?tab=profile`}
              >
                {record.Name}
              </Link>
            );
          }

          return record.Name;
        },
        key: "Name",
      },
      {
        title: "Tag",
        render: record => {
          if (record.RefSRIASupplierID) {
            return (
              <Tag color="blue" key={record.SupplierCompetitorID}>
                Active Supplier
              </Tag>
            );
          }
          return "";
        },
        key: "RefSRIASupplierID",
      },
    ];

    return ret;
  };
}

export default withRouter(CompetitorListView);

import { message, Spin, Switch, Tag } from "antd";
import React, { Component } from "react";

import { connect } from "react-redux";

import { FormItem } from "../../../co/modules/layout/COCommon";

import {
  sriaUserProfileLoadAction,
  sriaUserProfileUpdateAction,
} from "../../actions/SRIAUserProfileAction";

import SRIAUserProfileInput from "./SRIAUserProfileInput";
import SRIAUserProfileTPMSelect from "./SRIAUserProfileTPMSelect";

type TProps = {};

type TState = {};

class SRIAUserProfile extends Component<TProps, TState> {
  componentDidMount() {
    this.props.loadData();
  }

  render() {
    const {
      loading,
      data: { Notify, FullName, PrimaryTPM, IsSystemAdmin, ManagedSuppliers },
    } = this.props;
    return (
      <Spin spinning={loading}>
        <h3>
          User Profile{" "}
          {IsSystemAdmin && <Tag color="green">System Administrator</Tag>}
        </h3>
        <FormItem label="Full Name">
          <SRIAUserProfileInput
            field="FullName"
            defaultValue={FullName}
            placeholder="Full name..."
          />
        </FormItem>
        <FormItem label="Primary TPM">
          <SRIAUserProfileTPMSelect
            field="PrimaryTPM"
            defaultValue={PrimaryTPM}
            placeholder="Primary TPM..."
          />
        </FormItem>

        <FormItem label="Alert">
          <label>
            <Switch
              checked={Notify}
              onChange={this.props.save("Notify")}
            />{" "}
            Notification via email
          </label>
        </FormItem>
        {ManagedSuppliers != null &&
          <FormItem label="Managed Suppliers">
            <ul>
              {ManagedSuppliers.map(supplier =>
                <li id={supplier.id}>
                  {supplier.name}
                </li>
              )}
            </ul>
          </FormItem>}
      </Spin>
    );
  }
}

const mapStateToProps = (state: Object, ownedProps: Object) => {
  return {
    ...state.UserProfile,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    loadData: () => dispatch(sriaUserProfileLoadAction()),
    save: field => async value => {
      await dispatch(sriaUserProfileUpdateAction(field, value));
      message.info("Profile has been updated!");
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SRIAUserProfile);

// @flow
import { Button } from "antd";

import React, { Component } from "react";

type TProps = {
  func: string,
  children: any,
  style: ?Object,
};

type TState = {};

export default class DownloadFileButton extends Component<TProps, TState> {
  render() {
    const { func, children, style } = this.props;
    return (
      <Button icon="download" href={`/rpc/EventService/${func}`} style={style}>
        {children}
      </Button>
    );
  }
}

// @flow
import { Switch } from "antd";
import queryString from "query-string";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

type TProps = {
  field: string,
  title: any,
  params: ?any,
};

type TState = {};

class SwitchRouteParamsRenderProps_ extends Component<TProps, TState> {
  onSwitchToggle = checked => {
    const { field, params } = this.props;
    changeUrl(this.props, { [field]: checked ? "1" : "0", ...params });
  };

  render() {
    const {
      field,
      location: { search },
      render,
      defaultChecked,
      ...rest
    } = this.props;
    const params = queryString.parse(search);
    const checked =
      params[field] === "1" ||
      (params[field] === undefined ? defaultChecked : params[field] !== "0");

    return render({
      checked,
      onSwitchToggle: this.onSwitchToggle,
      ...rest,
    });
  }
}

export const SwitchRouteParamsRenderProps = withRouter(
  SwitchRouteParamsRenderProps_
);

export default class SwitchRouteParams extends Component<TProps, TState> {
  render() {
    const { field, title, defaultChecked, ...params } = this.props;

    return (
      <SwitchRouteParamsRenderProps
        field={field}
        params={params}
        defaultChecked={defaultChecked}
        render={({ checked, onSwitchToggle }) =>
          <React.Fragment>
            <Switch checked={checked} onChange={onSwitchToggle} /> {title}
          </React.Fragment>}
      />
    );
  }
}

export function changeUrl(props, extraParams, removeParams) {
  const finalUrl = buildUrl(props, extraParams, removeParams);
  props.history.push(finalUrl);
}

export function buildUrl(props, extraParams, removeParams = []) {
  const { location: { search, pathname } } = props;
  // console.log(props);
  const params = queryString.parse(search);
  for (const field in extraParams) {
    const value = extraParams[field];
    if (value == null || value === "") {
      delete params[field];
    } else {
      if (typeof value === "boolean") {
        if (value) {
          params[field] = "1";
        } else {
          delete params[field];
        }
        // params[field] = value ? "1" : "0";
      } else {
        params[field] = value;
      }
    }
  }

  for (const field of removeParams) {
    if (params[field]) {
      delete params[field];
    }
  }

  const finalUrl = `${pathname}?${queryString.stringify(params)}`;

  return finalUrl;
}

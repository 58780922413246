// @flow
import { Breadcrumb } from "antd";

import React, { Component } from "react";

import { Link } from "react-router-dom";

type TProps = {};

type TState = {};

export default class MyBreadcrumb extends Component<TProps, TState> {
  render() {
    const { routes } = this.props;
    return (
      <Breadcrumb>
        {routes.map(
          ({ path, breadcrumbName }, i) =>
            i === routes.length - 1
              ? <Breadcrumb.Item key={path}>
                  {breadcrumbName}
                </Breadcrumb.Item>
              : <Breadcrumb.Item key={path}>
                  <Link to={path}>
                    {breadcrumbName}
                  </Link>
                </Breadcrumb.Item>
        )}
      </Breadcrumb>
    );
  }
}

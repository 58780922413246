/* eslint-disable */
import * as yup from "yup";

yup.setLocale({
  mixed: {
    required: "Field is required.",
    url: "Field must be a valid URL",
    max: "Field must be at most ${max} characters",
    min: "Field must have at least ${min} items",
    oneOf: "Field is required.",
  },
  string: {
    url: "Field must be a valid URL.",
    email: "Invalid email address.",
    max: "Field must be at most ${max} characters.",
    min: "Field must have at least ${min} items.",
    oneOf: "Field is required.",
  },
  array: {
    max: "Field must be at most ${max} characters",
    min: "Field must have at least ${min} items",
  },
});
export default yup;

import { Col, Icon, Pagination, Row, Spin, Switch, Tag } from "antd";
import moment from "moment";
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import {
  buildUrl,
  SwitchRouteParamsRenderProps,
} from "../../../co/modules/layout/SwitchRouteParams";
import { changeUrl } from "../../../co/modules/layout/SwitchRouteParams";
import { actionCodeMap } from "../action/ActionListView";
import DataTable, {
  handlePaginationChange,
} from "../SRIAAdminSupplier/DataTable";
import DownloadFileButton from "../SRIAAdminSupplier/DownloadFileButton";
import Event8kView from "./Event8kView";
import EventExplaination from "./EventExplaination";
import EventSnippet from "./EventSnippet";
import { EventThumbUpDown2 } from "./EventThumbUpDown2";
import EventVote from "./EventVote";
import EventWeatherView from "./EventWeatherView";
import EventLast10kView from "./EventLast10kView";
import { NonNewsEventInfo } from "./NonNewsEventInfo";
import EventViolationView from "./EventViolationView";

function _buildSentence(explaination) {
  let parts = [];
  if (explaination && explaination.length > 0) {
    parts = explaination
      .flatMap(item => item.parts)
      .map(part => {
        return part.text;
      });
  }

  return parts.join(" ");
}

class EventListView extends Component {
  handleTableChange = (pagination, sorter) => {
    const extraParams = {
      ...this.state,
      sortField: sorter.columnKey,
      sortOrder: sorter.order,
      page: pagination.current,
    };

    changeUrl(this.props, extraParams);
  };

  render() {
    return (
      <div>
        <SwitchRouteParamsRenderProps
          field="explainationOnly"
          render={({ checked, onSwitchToggle }) => (
            <div>
              {checked ? this.renderExplainationOnly() : this.renderDataTable()}
              <Switch checked={checked} onChange={onSwitchToggle} /> Explanation
              Only
            </div>
          )}
        />
        <EventThumbUpDown2 />
      </div>
    );
  }

  renderExplainationOnly = () => {
    const {
      data: { items, votes },
    } = this.props;
    let explainations = [];
    if (items && items.length > 0) {
      explainations = items.flatMap(item =>
        item.docs.map(doc => ({
          title: doc.title,
          url: doc.url,
          explaination: doc.explaination,
          event: item.event_code,
          id: item.id,
          matchSyntax: doc.match_syntax,
          matchSentence: _buildSentence(doc.explaination),
        }))
      );
    }

    return (
      <Spin spinning={this.props.loading}>
        <Row
          type="flex"
          justify="space-between"
          style={{ marginTop: 8, marginBottom: 8 }}
        >
          <Col>
            <Pagination
              {...this.props.data.pagination}
              onChange={page =>
                handlePaginationChange(this.props)({ current: page })
              }
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
            />
          </Col>
          <Col>
            <DownloadFileButton func="sria_admin_event_label_export">
              Event Labels
            </DownloadFileButton>
          </Col>
        </Row>
        {explainations.map(
          (
            {
              event,
              explaination,
              title,
              url,
              content,
              id,
              matchSyntax,
              matchSentence,
            },
            i
          ) => (
            <ExplainationBlock key={i}>
              <div>
                Event Code: <b>{event}</b>{" "}
                <EventVote
                  id={id}
                  url={url}
                  rule={matchSyntax}
                  sentence={matchSentence}
                  vote={votes[id]}
                  code={event}
                />
              </div>

              <a href={url} target="_blank" rel="noopener noreferrer">
                {title || <i>---</i>}
              </a>
              <EventExplaination data={explaination} />
            </ExplainationBlock>
          )
        )}
        <Pagination
          {...this.props.data.pagination}
          onChange={page =>
            handlePaginationChange(this.props)({ current: page })
          }
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
        />
      </Spin>
    );
  };

  renderExpandedRow = record => {
    const {
      data: { votes },
    } = this.props;

    if (record.index === "event_consumerfinance") {
      return <ConsumerFinanceInfo record={record} />;
    }
    if (record.index === "event_weather") {
      return <EventWeatherView record={record} />;
    }

    if (record.index === "event_8k") {
      return <Event8kView record={record} />;
    }
    if (record.index === "event_10k") {
      return <EventLast10kView record={record} />;
    }

    if (record.index === "event_violation") {
      return <EventViolationView record={record} />;
    }

    if (record.event_code === "StockPriceDrop") {
      return <StockPriceDropEvent record={record} />;
    }

    let actionRender = (
      <ul>
        <div>
          <span>
            <b>Event Type:</b> {record.event_name}
          </span>
        </div>

        {this.renderActions(
          record.actions && record.actions.length > 0
            ? { actions: record.actions, title: "Actions" }
            : {
                actions: record.recommend_actions,
                title: "Recommend Actions",
              }
        )}
      </ul>
    );
    if (record.index === "event_crunchbase") {
      actionRender = (
        <ul>
          <NonNewsEventInfo record={record} />
        </ul>
      );
    }

    return (
      <div>
        {actionRender}

        <ul style={{ marginLeft: 16, listStyleType: "circle" }}>
          {record.docs.map((doc, j) => {
            const title =
              (doc.title_highlight ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: doc.title_highlight,
                  }}
                />
              ) : null) || doc.title;

            return (
              <li key={j} style={{ paddingBottom: 16 }}>
                <div>
                  {title ? (
                    <span>
                      <b>{title}</b> - {moment(doc.published_date).format("ll")}
                    </span>
                  ) : (
                    doc.published_date
                  )}{" "}
                  {doc.explaination != null && (
                    <EventVote
                      id={record.id}
                      url={doc.url}
                      rule={doc.match_syntax}
                      sentence={_buildSentence(doc.explaination)}
                      vote={votes[record.id]}
                      code={record.event_code}
                    />
                  )}
                </div>

                <div>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">
                    {doc.url}
                  </a>
                </div>
                <EventExplaination data={doc.explaination} />
                {doc.content && (
                  <div
                    style={{
                      marginLeft: 32,
                      paddingLeft: 8,
                      borderLeft: "5px solid #eee",
                    }}
                  >
                    <EventSnippet
                      content={doc.content}
                      eventProps={{
                        event_id: record.id,
                        event_code: record.event_code,
                        doc_url_hash: doc.url_hash,
                        actions: record.actions,
                        recommend_actions: record.recommend_actions,
                        index: record.index,
                      }}
                      report={doc.report}
                    />
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  renderActions = ({ actions: actionCodes, title }) => {
    if (!actionCodes || actionCodes.length === 0) {
      return null;
    }
    return (
      <div>
        <b>{title}:</b>
        <ul style={{ marginLeft: 40, color: "tomato" }}>
          {actionCodes.map(code => (
            <li key={code}>{actionCodeMap[code] || code}</li>
          ))}
        </ul>
      </div>
    );
  };

  renderDataTable = () => {
    return (
      <DataTable
        {...this.props}
        rowKey={record => {
          return `${record.effective_date}_${record.supplier_id}_${record.event_code}`;
        }}
        rowClassName={record => {
          let riskLevel = "";
          if (!record.score || record.score <= 30) {
            riskLevel = "Low";
          } else if (record.score > 30 && record.score <= 71) {
            riskLevel = "Medium";
          } else if (record.score > 71) {
            riskLevel = "High";
          }
          return `EventRiskLevel_${riskLevel}`;
        }}
        expandedRowRender={this.renderExpandedRow}
        columns={this.getColumns()}
        onChange={(pagination, filters, sorter) => {
          this.handleTableChange(pagination, sorter);
        }}
      />
    );
  };

  getColumns = () => {
    const { showSupplier = true } = this.props;
    let ret = [
      {
        title: "Effective Date",
        render: record => {
          return (
            <span style={{ whiteSpace: "nowrap" }}>
              {moment(record.effective_date).format("ll")}
            </span>
          );
        },
        key: "effective_date",
        sorter: true,
      },
    ];

    if (showSupplier) {
      ret.push({
        title: "Supplier",
        key: "supplier_name",
        sorter: true,
        render: record => (
          <span>
            <Link
              to={buildUrl(this.props, { supplier_ids: record.supplier_id })}
            >
              {record.supplier_name}
            </Link>{" "}
            <Link to={`/suppliers/view/${record.supplier_id}`}>
              <Icon type="link" />
            </Link>
          </span>
        ),
      });
    }

    ret = [
      ...ret,
      {
        title: "Event",
        key: "event_name",
        sorter: true,
        render: record => (
          <Link
            to={buildUrl(this.props, { event_codes: record.event_code })}
            style={{ whiteSpace: "nowrap" }}
          >
            {record.event_name}
          </Link>
        ),
      },
      {
        title: "Event Type",
        key: "_index",
        sorter: true,
        render: record => {
          if (record.event_code === "EarningsRelease") {
            return (
              <Tag color="#e97272" key="CompanyFinancial">
                Company Financial
              </Tag>
            );
          }

          if (record.index === "event_crunchbase") {
            return (
              <Tag color="blue" key="Crunchbase">
                Crunchbase
              </Tag>
            );
          }

          if (record.index === "event_news") {
            return (
              <Tag color="TEAL" key="News">
                News
              </Tag>
            );
          }

          if (record.index === "event_consumerfinance") {
            return (
              <Tag color="#2E86C1" key="ConsumerFinance">
                Consumer Finance
              </Tag>
            );
          }

          if (
            record.index === "event_finance" ||
            record.index === "event_10k"
          ) {
            return (
              <Tag color="#5AC7E5" key="Finance">
                Finance
              </Tag>
            );
          }

          if (record.index === "event_weather") {
            return (
              <Tag color="#0F8BAD" key="Weather">
                Natural Disaster
              </Tag>
            );
          }

          if (record.index === "event_8k") {
            return (
              <Tag color="#DD3F5B" key="Event8k">
                Government Regulator
              </Tag>
            );
          }

          if (record.index === "event_webupdate") {
            return (
              <Tag color="#5d92be" key="EventWebupdate">
                Website Activity
              </Tag>
            );
          }

          if (record.index === "event_violation") {
            return (
              <Tag color="#ff4d4f" key="Violations">
                Violations
              </Tag>
            );
          }
        },
      },
      {
        title: "Importance",
        key: "score",
        sorter: true,
        render: record => {
          if (record.score <= 30) {
            return "Low";
          }
          if (record.score > 30 && record.score <= 70) {
            return "Medium";
          }
          return "High";
        },
      },
      {
        title: "Sources",
        render: record =>
          record.domains.map(domain => (
            <SourceLink key={domain}>{domain}</SourceLink>
          )),
      },
      // {
      //   title: "Operations",
      //   render: record =>
      //     <div>
      //       <RemoveEventButton eventId={record.id} />
      //     </div>,
      // },
    ];

    return ret;
  };
}

const SourceLink = styled.span`
  margin-right: 10px;
  border-bottom: 1px dashed #555;

  &:hover {
    font-weight: bold;
    /* border-bottom: 2px dashed orange; */
    color: #096dd9;
    font-size: 13px !important;
    border-bottom: none;
  }
`;

const ExplainationBlock = styled.div`
  margin-bottom: 8px;
  padding: 8px;

  &:hover {
    background-color: #eee;
  }
`;

const ConsumerFinanceInfo = ({ record }) => {
  const link = `#/suppliers/view/${record.supplier_id}?tab=ConsumerFinance`;
  return (
    <div>
      <NonNewsEventInfo record={record} />

      <Row>
        <Col>
          <b>Number of complaints in this month</b>: {record.doc_count}
        </Col>
      </Row>

      <a href={link}>View more details...</a>
    </div>
  );
};

const StockPriceDropEvent = ({ record }) => {
  const dateBefore = moment(record.stock_data.date_before, "YYYY-MM-DD")
    .add(1, "day")
    .unix();

  const dateCurrent = moment(record.stock_data.date_current, "YYYY-MM-DD")
    .add(1, "day")
    .unix();

  const link = `https://finance.yahoo.com/quote/${record.stock_data.ticker}/history?period1=${dateBefore}&period2=${dateCurrent}&interval=1d&filter=history&frequency=1d`;

  return (
    <div style={{ width: "50%" }}>
      <NonNewsEventInfo record={record} />

      <Row>
        <Col span={6}>Previous Trade Date:</Col>
        <Col span={6}>{record.stock_data.date_before}</Col>

        <Col span={6}>Previous Stock Price:</Col>
        <Col span={6}>{record.stock_data.price_before.toFixed(2)}</Col>
      </Row>

      <Row>
        <Col span={6}>Trade Date:</Col>
        <Col span={6}>{record.stock_data.date_current}</Col>
        <Col span={6}>Stock Price:</Col>
        <Col span={6}>{record.stock_data.price_current.toFixed(2)}</Col>
      </Row>
      <Row>
        <Col span={12} />
        <Col span={6}>
          <b>Price Rate Change:</b>
        </Col>
        <Col span={6}>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontSize: 16, color: "red" }}
          >
            <b>{record.stock_data.rate} %</b>
          </a>
        </Col>
      </Row>
    </div>
  );
};
export default withRouter(EventListView);

import { Input } from "antd";
import { isEqual } from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { rpcCall } from "../../../../addons/RPC";
import { showOutputMessage } from "../../../co/modules/request/view/RequestViewUtils";

class _MyInput extends Component {
  state = { value: this.props.defaultValue };

  componentWillReceiveProps(nextProps) {
    if (!isEqual(this.props.defaultValue, nextProps.defaultValue)) {
      this.setState({ value: nextProps.defaultValue });
    }
  }

  onChange = event => {
    const value = event.target.value;
    this.setState({ value });
  };

  render() {
    return (
      <Input
        // style={{ width: 120 }}
        value={this.state.value}
        onChange={this.onChange}
        onBlur={this.onBlur}
        onPressEnter={this.onPressEnter}
        placeholder={this.props.placeholder}
      />
    );
  }

  onPressEnter = e => {
    this.updateValue(e.target.value);
  };

  onBlur = ({ target: { value } }) => {
    this.updateValue(value);
  };

  updateValue = async value => {
    if (value !== this.props.defaultValue) {
      const { field } = this.props;

      const output = await this.props.update(field, value);

      showOutputMessage(output);

      this.setState({ value });
    }
  };
}

const mapDispatchToProps = (dispatch: Function, ownedProps) => {
  return {
    update: (field, value) => {
      return rpcCall("EventService/sria_user_profile_update", {
        field,
        value,
      });
    },
  };
};

export default connect(null, mapDispatchToProps)(_MyInput);
